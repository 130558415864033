import React, { useState } from 'react';
import { Grid, Icon, Label, Modal, Popup } from 'semantic-ui-react';
import Ribbon from '../Ribbon';
import { formatDateWithTZ } from '../../util/timezone';
import { RequestInstance } from '../../types/RequestInstance';
import { handleRequestTest, renderRequestLabelColor, renderRequestStatus } from '../../util/requests';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AuthState } from '../../types';
import RescheduleRequestModal from './RescheduleRequestModal';
import RequestInfoModal from '../Requests/RequestInfoModal';
import SidebarIcon from '../SidebarIcon';
import ResidentsLiveCall from '../ResidentsLiveCall';
import NotificationsFormContainer from '../NotificationFormContainer';

interface Props extends RouteComponentProps {
    request: RequestInstance;
    isFullScreenModalView?: boolean,
    expandView: boolean,
    orderIdColorDict: any,
    expandsingleRequestId: string | null,
    setExpandSingleRequestId: (id: string | null) => void;
    powerAndFacilityUsers?: boolean;
    refresh?: () => void;
    facilityWebRTCEnabled: boolean;
}
const RequestCard = ({ request, isFullScreenModalView, expandView, orderIdColorDict, powerAndFacilityUsers, refresh, expandsingleRequestId, setExpandSingleRequestId, facilityWebRTCEnabled }: Props) => {

    const [selectedRequestForReschedule, setSelectedRequestForReschedule] = useState<RequestInstance>();
    const [rescheduleRequestModal, setScheduleRequestModal] = useState<boolean>(false);
    const [requestDetailedModal, setRequestDetailedModal] = useState<boolean>(false);
    const [openLiveCallModal, setOpenLiveCallModal] = useState<boolean>(false);
    const [webRTCModalLoading, setWebRTCModalLoading] = useState<boolean>(false);
    const [showAnnouncementModal, setShowAnnouncementModal] = useState<boolean>(false);

    const profile = useSelector(({ authReducer }: { authReducer: AuthState; }) => {
        return authReducer.profile;
    });
    const facilityTZ = (profile && profile.FacilityTimeZone) || '';

    const setRequestIdforExpand = (id) => {
        if (expandsingleRequestId === id) {
            setExpandSingleRequestId(null);
        } else {
            setExpandSingleRequestId(id);
        }
    };

    const toggleExpandModal = () => {
        setOpenLiveCallModal(!openLiveCallModal);
    }

    const annoucementIconClickHandler = (id: string | undefined) => {
        setShowAnnouncementModal(true);  
    }

    const renderStaffName = (req) => {
        if (req.Status === 'Accepted') {
            return req.AcceptedByName;
        } else if (req.Status === 'Closed') {
            return req.ClosedByName;
        } else {
            return '';
        }
    };

    const editIconClickHandler = (e: React.MouseEvent) => {
        e.stopPropagation(); // Prevents the event from reaching other identical rendered instances 
        setRequestDetailedModal(true);
    };
    
    const refreshCard = () => {
        if (refresh) {
            refresh();
        }
        setRequestDetailedModal(false);
    }

    return (
        <div
            style={{
                backgroundColor: request.Department_Color
                    ? request.Department_Color.includes('#')
                        ? request.Department_Color
                        : `#${request.Department_Color}`
                    : '#1f3c73',
                color: 'white',
                borderRadius: '5px',
                marginBottom: '8px', // Reduced margin between cards
                display: 'flex',
                flexDirection: 'column',
                width: isFullScreenModalView ? '100%' : '400px', // Fixed width
                overflow: 'hidden', // Handle overflowing text gracefully
            }}
            onDoubleClick={(e) => editIconClickHandler(e)}
        >
            <Grid style={{ padding: '10px' }}>
                {/* Top Section */}
                <Grid.Row
                    columns={2}
                    style={{
                        paddingBottom: '3px', // Reduced row padding
                        marginTop: '0', // Remove default top margin
                        marginBottom: '0', // Remove default bottom margin
                    }}
                    onClick={() => {
                        setRequestIdforExpand(request._id);
                    }}
                >
                    <Grid.Column
                        width={
                            ['open', 'closed'].includes(renderRequestStatus(request).toLowerCase()) ? 10 : 9
                        }
                        floated="left"
                    >
                        <div
                            style={{
                                fontSize: '15px', // Slightly smaller font size
                                fontWeight: 'bold',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {request.Unit_Name},{' '}
                            {`${request.Registrant_FirstName} ${request.Registrant_LastName}`}
                        </div>
                        <div style={{ fontSize: '15px' }}>{request.Department_Name}</div>
                    </Grid.Column>
                    <Grid.Column
                        width={
                            ['open', 'closed'].includes(renderRequestStatus(request).toLowerCase()) ? 6 : 7
                        }
                        textAlign="right"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            gap: '7px'
                        }}
                        floated="right"
                    >
                        {powerAndFacilityUsers && <Popup
                            content="Announcement"
                            trigger={
                                <div
                                    style={{ cursor: 'pointer', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                    onClick={() => annoucementIconClickHandler(request._id)}
                                >
                                    <SidebarIcon
                                        name="announcementsSolid"
                                        white={true}
                                        smallSize={true}
                                        customStyle={{
                                            cursor: 'pointer',
                                            fontSize: '20px'
                                        }}
                                    />
                                </div>
                            }
                            pinned
                            position="top center"
                            size="tiny"
                        />}
                        {facilityWebRTCEnabled && powerAndFacilityUsers ?
                            <Popup
                                content="Video call"
                                trigger={
                                    <div
                                        style={{ cursor: 'pointer', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                        onClick={toggleExpandModal}
                                    >
                                        <SidebarIcon
                                            name="webRTCSolid"
                                            white={true}
                                            customStyle={{
                                                cursor: 'pointer',
                                                fontSize: '20px'
                                            }}
                                        />
                                    </div>
                                }
                                pinned
                                position="top center"
                                size="tiny"
                            /> : <></>}
                        {request.OrderName ? (
                            <div
                                style={{ cursor: 'pointer', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                onClick={() => {
                                    setSelectedRequestForReschedule(request);
                                    setScheduleRequestModal(true);
                                }}
                            >
                                <Popup
                                    content={'Reschedule'}
                                    position="top center"
                                    trigger={
                                        <Icon
                                            name="calendar alternate outline"
                                            size="large"
                                            disabled={false}
                                        />
                                    }
                                />
                            </div>
                        ) : (
                            <div style={{ cursor: 'pointer', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Icon
                                    name="calendar alternate outline"
                                    size="large"
                                    disabled={true}
                                />
                                </div>
                        )}
                        <div style={{position: 'relative'}}>
                            <Ribbon
                                ribbonColor={renderRequestLabelColor(request)}
                                heading={renderRequestStatus(request)}
                            />
                            {request.requestCount && request.requestCount > 1 && (
                                <div>
                                    <Label circular style={{backgroundColor: '#2185D0', color: 'white', fontSize: '12px', padding: '5px', position: 'absolute', top: '-14px', right: '-9px'}}>
                                        {request.requestCount}
                                    </Label>
                                </div>
                            )}
                        </div>
                    </Grid.Column>
                </Grid.Row>
                {/* Middle Section */}
                <Grid.Row
                    style={{
                        paddingTop: '3px', // Reduced row padding
                        marginTop: '0', // Remove default top margin
                        marginBottom: '0', // Remove default bottom margin
                        marginRight: '15px',
                    }}
                    columns={2}
                >
                    <Grid.Column>
                        <div style={{ fontSize: '15px' }}>
                            {formatDateWithTZ(request.RequestedTime, 'h:mm A, M/D/YYYY', facilityTZ)}
                        </div>
                    </Grid.Column>
                    <Grid.Column style={{ paddingLeft: '0', paddingRight: '0' }}>
                        <div style={{ fontSize: '12px', textAlign: 'right', marginTop: '1px' }}>
                            {request._id}
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            {/* Expanded View */}
            {(expandView || request._id === expandsingleRequestId) && (
                <div
                    style={{
                        backgroundColor: '#E2E2E2',
                        color: '#183466',
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '10px',
                    }}
                >
                    <div className="fontRoboto" style={{ fontSize: '15px', marginBottom: '5px', width: '240px' }}>
                        <p style={{ marginBottom: '0px' }}>{request.Details ? handleRequestTest(request, 'Details') : request.Description}</p>
                        {(request.Name && request.OriginalServiceId) ? <p style={{ marginBottom: '0px' }}>Title: {request.Name}</p> : <></>}
                        {request.Service && request.Service.name ? <p style={{ marginBottom: '0px' }}>Service: {request.Service.name}</p> : <></>}
                        <i
                            className="fa-solid fa-pen"
                            onClick={(e) => editIconClickHandler(e)}
                            style={{ color: '#183466', cursor: 'pointer', padding: '5px', marginLeft: '-5px', marginTop: '10px' }}
                        ></i>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            alignItems: 'flex-end',
                            width: '156px',
                        }}
                    >
                        {request.OrderId && (
                            <Label
                                style={{
                                    backgroundColor: orderIdColorDict[request.OrderId],
                                    color: 'white',
                                    fontSize: '11px',
                                    borderRadius: '4px',
                                    margin: '5px',
                                }}
                            >
                                Order ID: {request.OrderId.slice(request.OrderId.length - 4)}
                            </Label>
                        )}
                        {renderStaffName(request) && (
                            <p style={{ textAlign: 'right', color: '#183466', fontSize: '15px' }}>
                                {renderStaffName(request)}
                            </p>
                        )}
                    </div>
                </div>
            )}
            <RescheduleRequestModal
                id={(selectedRequestForReschedule && selectedRequestForReschedule._id) || ''}
                datePickerModal={rescheduleRequestModal}
                setDatePickerModal={setScheduleRequestModal}
                request={selectedRequestForReschedule || ({} as RequestInstance)}
                powerAndFacilityUsers={powerAndFacilityUsers}
                refresh={refresh}
            />
            {
                requestDetailedModal &&
                <RequestInfoModal
                    requestDetailedModal={requestDetailedModal}
                    setRequestDetailedModal={setRequestDetailedModal}
                    request={request}
                    refresh={refreshCard || (() => {})}
                    powerAndFacilityUsers={powerAndFacilityUsers}
                />
            }
            {openLiveCallModal ? <ResidentsLiveCall
                fullScreen={openLiveCallModal}
                onModalClose={toggleExpandModal}
                importedByRequests={true}
                residentId={request.ResidentId || request.Registrant}
                modalLoading={webRTCModalLoading}
                setModalLoading={setWebRTCModalLoading}
            /> :
            <></>
            }
            <Modal
                open={showAnnouncementModal}
                closeIcon
                closeOnEscape
                closeOnDimmerClick
                onClose={() => setShowAnnouncementModal(false)}
                size="small"
                style={{ paddingBottom: '20px' }}
            >
                <NotificationsFormContainer
                    closeModal={() => {
                        setShowAnnouncementModal(false);
                    }}
                    residentId={request.ResidentId || request.Registrant}
                />
            </Modal>
        </div>
    );
};

export default withRouter(RequestCard);