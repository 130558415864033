import React from 'react';

type Props = {
    disabled?: boolean;
    onClickHandler: () => void;
};

const ReopenBtn = ({ disabled, onClickHandler }: Props) => {
    return (
        <div>
            <button type="button" className="edit-request-btn" disabled={disabled} onClick={onClickHandler}>
                <div>
                    <i className="fa-solid fa-arrow-up-right-from-square updateIcon" style={{ color: '#F4C542' }}></i>
                </div>
                <div className='btnReqTitle'>Reopen</div>
            </button>
        </div>
    );
};

export default ReopenBtn;
