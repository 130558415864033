import React, { FC, useState, useEffect } from 'react';
import { AuthState, OrderIdColorDict, ReportSummaryFilters, Request, User } from '../../types';
import Board, { moveCard } from '@asseinfo/react-kanban';
import '@asseinfo/react-kanban/dist/styles.css';
import requestsKanbanInitData from './requestsKanbanInitData';
import { Dimmer, Dropdown, Grid, Icon, Label, Loader, Popup } from 'semantic-ui-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import './style.less';
import RequestCalendarView from '../RequestsList/RequestCalenderView';
import LoadMore from '../LoadMore';
import { sendToast } from '../../util';
import RequestCard from './RequestCard';
import { groupRequests, requestCalendarView, requestSortOptions } from '../../util/requests';
import { useSelector } from 'react-redux';
import { fetchOneFeaturedFacility } from '../../services/Facilities';

interface LoadMoreState {
    isLoading: boolean;
    hasMore: boolean;
    setPageNo: React.Dispatch<React.SetStateAction<number>>;
}

interface LoadMoreProps {
    open: LoadMoreState;
    accepted: LoadMoreState;
    closed?: LoadMoreState;
}

interface RequestsKanbanProps extends RouteComponentProps {
    openRequests: Request[];
    acceptedRequests: Request[];
    closedRequests?: Request[];
    handleCardMoveToClose?: (card, source) => void;
    handleCardMoveToOpen: (card, source) => void;
    handleCardMoveToAccepted: (card, source) => void;
    loading: boolean;
    orderIdColorDict: OrderIdColorDict;
    powerAndFacilityUsers?: boolean;
    assignableRequestFilters: ReportSummaryFilters;
    loadMoreProps: LoadMoreProps;
    openRequestsLength: number;
    acceptedRequestsLength: number;
    closedRequestsLength?: number;
    selectedOpenSortingValue: string;
    selectedAcceptedSortingValue: string;
    selectedClosedSortingValue?: string;
    setSelectedOpenSortingValue: React.Dispatch<React.SetStateAction<string>>;
    setSelectedAcceptedSortingValue: React.Dispatch<React.SetStateAction<string>>;
    setSelectedClosedSortingValue?: React.Dispatch<React.SetStateAction<string>>;
    refresh: () => void;
    setOpenPageNo: React.Dispatch<React.SetStateAction<number>>;
    setAcceptedPageNo: React.Dispatch<React.SetStateAction<number>>;
    setClosedPageNo?: React.Dispatch<React.SetStateAction<number>>;
    userId?: string;
    isFullScreenModalView?: boolean;
    fullScreenStatus?: string;
    setAssignableRequestFilters: React.Dispatch<React.SetStateAction<ReportSummaryFilters>>;
    expandView: boolean;
    expandsingleRequestId: string | null;
    setExpandSingleRequestId: React.Dispatch<React.SetStateAction<string | null>>;
    currentView: string;
}

const RequestsKanban: FC<RequestsKanbanProps> = ({
    openRequests,
    acceptedRequests,
    closedRequests,
    history,
    handleCardMoveToAccepted,
    handleCardMoveToClose,
    handleCardMoveToOpen,
    loading,
    orderIdColorDict,
    powerAndFacilityUsers,
    assignableRequestFilters,
    setAssignableRequestFilters,
    loadMoreProps,
    openRequestsLength,
    acceptedRequestsLength,
    closedRequestsLength,
    selectedOpenSortingValue,
    selectedAcceptedSortingValue,
    selectedClosedSortingValue,
    setSelectedOpenSortingValue,
    setSelectedAcceptedSortingValue,
    setSelectedClosedSortingValue,
    refresh,
    setOpenPageNo,
    setAcceptedPageNo,
    setClosedPageNo,
    userId,
    isFullScreenModalView,
    fullScreenStatus,
    expandView,
    expandsingleRequestId,
    setExpandSingleRequestId,
    currentView,
}) => {
    const [board, setBoard] = useState(requestsKanbanInitData);
    const [isFacilityWebRTCEnabled, setIsFacilityWebRTCEnabled] = useState<boolean>(false);

    const profile = useSelector(({ authReducer }: { authReducer: AuthState; }) => {
        return authReducer.profile;
    });

    const groupedOpenRequests = groupRequests(openRequests);

    useEffect(() => {
        // Update the board when props change
        const updatedBoard = {
            columns: [
                {
                    id: 'open', // Don't change this as this key is used in multiple other components
                    title: 'Open Requests' + (openRequestsLength ? ` (${openRequestsLength})` : "(0)"),
                    cards: groupedOpenRequests.map((request) => ({
                        id: request._id,
                        title: request.Name || 'No Name',
                        description: `${request.UnitName}, ${request.Registrant_FirstName} ${request.Registrant_LastName}`,
                        metadata: request,
                    })),
                    numberOfRequests: openRequestsLength,
                    hasMore: loadMoreProps.open.hasMore,
                    isLoading: loadMoreProps.open.isLoading,
                    selectedRequestSortOption: selectedOpenSortingValue,
                    setSelectedRequestSortOption: setSelectedOpenSortingValue,
                    setPageNo: setOpenPageNo,
                },
                {
                    id: 'accepted', // Don't change this as this key is used in multiple other components
                    title: 'Accepted Requests' + (acceptedRequestsLength ? ` (${acceptedRequestsLength})` : "(0)"),
                    cards: acceptedRequests.map((request) => ({
                        id: request._id,
                        title: request.Name || 'No Name',
                        description: `${request.UnitName}, ${request.Registrant_FirstName} ${request.Registrant_LastName}`,
                        metadata: request,
                    })),
                    numberOfRequests: acceptedRequestsLength,
                    hasMore: loadMoreProps.accepted.hasMore,
                    isLoading: loadMoreProps.accepted.isLoading,
                    selectedRequestSortOption: selectedAcceptedSortingValue,
                    setSelectedRequestSortOption: setSelectedAcceptedSortingValue,
                    setPageNo: setAcceptedPageNo,
                },
                closedRequests && selectedClosedSortingValue && setClosedPageNo && {
                    id: 'closed', // Don't change this as this key is used in multiple other components
                    title: 'Closed Requests' + (closedRequestsLength ? ` (${closedRequestsLength})` : "(0)"),
                    cards: closedRequests && closedRequests.map((request) => ({
                        id: request._id,
                        title: request.Name || 'No Name',
                        description: `${request.UnitName}, ${request.Registrant_FirstName} ${request.Registrant_LastName}`,
                        metadata: request,
                    })),
                    numberOfRequests: closedRequestsLength,
                    hasMore: loadMoreProps && loadMoreProps.closed && loadMoreProps.closed.hasMore,
                    isLoading: loadMoreProps && loadMoreProps.closed && loadMoreProps.closed.isLoading,
                    selectedRequestSortOption: selectedClosedSortingValue,
                    setSelectedRequestSortOption: setSelectedClosedSortingValue,
                    setPageNo: setClosedPageNo,
                },
            ],
        };
        updatedBoard.columns = updatedBoard.columns.filter(Boolean);
        // @ts-ignore
        setBoard(updatedBoard);
    }, [openRequests, acceptedRequests, (closedRequests && closedRequests)]);

    useEffect(() => {
        const fetchFeaturedFacility = async () => {
            if (profile && profile.Facility) {
                try {
                    const { FacilityId = "" } = await fetchOneFeaturedFacility(profile.Facility);
                    if (FacilityId) {
                        setIsFacilityWebRTCEnabled(FacilityId);
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        };
        fetchFeaturedFacility();
    }, [])

    const handleCardMove = (card, source, destination) => {
        moveCard(board, source, destination);
        switch (destination.toColumnId) {
            case 'closed':
                handleCardMoveToClose && handleCardMoveToClose(card, source);
                break;
            case 'open':
                handleCardMoveToOpen(card, source);
                break;
            case 'accepted':
                handleCardMoveToAccepted(card, source);
                break;
            default:
                sendToast('warn', 'Invalid Destination column');
        }
    };

    const renderKanbanCard = (card) => {
        return (
            <RequestCard
                request={card.metadata}
                isFullScreenModalView={isFullScreenModalView}
                expandView={expandView}
                orderIdColorDict={orderIdColorDict}
                expandsingleRequestId={expandsingleRequestId}
                setExpandSingleRequestId={setExpandSingleRequestId}
                powerAndFacilityUsers={powerAndFacilityUsers}
                refresh={refresh}
                facilityWebRTCEnabled={isFacilityWebRTCEnabled}
            />
        );
    };

    const renderKanbanColumnHeader = (column) => {
        return (
            <div className="flex">
                <div style={{ fontWeight: 'bold', fontSize: '20px' }}>
                    {/* // if the name has request in it, we remove it */}
                    {column.title.replace('Requests', '').trim().toUpperCase()}
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Dropdown
                        options={requestSortOptions}
                        selection
                        value={column.selectedRequestSortOption}
                        onChange={(e, { value }) => {
                            if (column.setSelectedRequestSortOption) {
                                column.setSelectedRequestSortOption(value);
                            }
                        }}
                    />
                    {!isFullScreenModalView ? (
                        <div>
                            <Icon
                                name="expand"
                                size="large"
                                onClick={() => {
                                    if (powerAndFacilityUsers) {
                                        const basePath = '/admin/requests/';
                                        const queryParam =
                                            column.id === 'accepted'
                                                ? '?expandAccepted=true'
                                                : column.id === 'closed'
                                                    ? '?expandClosed=true'
                                                    : '?expandOpen=true';
                                        history.push(basePath + queryParam);
                                    } else {
                                        const basePath = "/";
                                        // do the opposite for staff compared to facility admin because of the way props are passed.
                                        const queryParam = column.id === 'accepted' ? "?expandAccepted=true" : "?expandOpen=true";
                                        history.push(basePath + queryParam);
                                    }
                                }}
                            />
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        );
    };

    return (
        <>
            <Dimmer
                active={
                    loading ||
                    loadMoreProps.open.isLoading ||
                    loadMoreProps.accepted.isLoading ||
                    (loadMoreProps && loadMoreProps.closed && loadMoreProps.closed.isLoading)
                }
                inverted
            >
                <Loader
                    active={
                        loading ||
                        loadMoreProps.open.isLoading ||
                        loadMoreProps.accepted.isLoading ||
                        (loadMoreProps && loadMoreProps.closed && loadMoreProps.closed.isLoading)
                    }
                />
            </Dimmer>
            {isFullScreenModalView ? (
                <>
                    {board && board.columns && board.columns.length > 0 ? (
                        <div>
                            {board.columns.map((column) => {
                                if (column.id === fullScreenStatus) {
                                    return (
                                        <div key={column.id}>
                                            {renderKanbanColumnHeader(column)}
                                            {column.cards && column.cards.length > 0 ? (
                                                column.cards.map((card) => <div>{renderKanbanCard(card)}</div>)
                                            ) : (
                                                <></>
                                            )}
                                            <div>
                                                <LoadMore // If any change made here do it for kanban Load More also
                                                    isLoading={column.isLoading}
                                                    hasMore={column.hasMore || false}
                                                    next={() => column.setPageNo((prev) => prev + 1)}
                                                />
                                            </div>
                                        </div>
                                    );
                                }
                                return null;
                            })}
                        </div>
                    ) : (
                        <></>
                    )}
                </>
            ) : (
                    <div className="RequestsKanbanBoard">                    
                    {(currentView === requestCalendarView) ? (
                        <RequestCalendarView
                            calendarView={true}
                            assignableRequestFilters={assignableRequestFilters}
                            powerAndFacilityUsers={powerAndFacilityUsers}
                            setAssignableRequestFilters={setAssignableRequestFilters}
                        />
                    ) : (
                        <div style={{ position: 'relative' }}>
                            <Board
                                onCardDragEnd={handleCardMove}
                                renderColumnHeader={(column) => renderKanbanColumnHeader(column)}
                                style={{ width: '400px' }}
                                renderCard={renderKanbanCard}
                            >
                                {board}
                            </Board>
                            <Grid style={{ flexWrap: 'nowrap', width: '1335px'}}>
                                {board.columns.map((column, i) => (
                                    <Grid.Column
                                        key={column.id}
                                        style={{
                                            textAlign: 'center',
                                            width: '445px'
                                        }}
                                    >
                                        <LoadMore // If any change made here do it for full screen Load More also
                                            isLoading={column.isLoading}
                                            hasMore={column.hasMore || false}
                                            next={() => column.setPageNo((prev) => prev + 1)}
                                            skipMargin={true}
                                        />
                                    </Grid.Column>
                                ))}
                            </Grid>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default withRouter(RequestsKanban);
