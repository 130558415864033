import React from 'react';
import {
    Form,
    InputOnChangeData,
    Dropdown,
    Message,
    Checkbox,
    Button,
    Icon,
    Modal,
    Header,
    Popup,
    Dimmer,
    Loader,
    Menu,
    Grid
} from 'semantic-ui-react';
import './style.less';
import DatePicker from 'react-datepicker';
import AnnouncementAlertIcon from '../../../components/Icon/index';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { AppState } from '../../../reducers';
import { UserProfile } from '../../../types';
import { fetchFacilityTimezoneWithFacilityId } from '../../../util';
import { toast } from 'react-toastify';
import { sortByKey } from '../../../util/sortData';
import AnnouncementIcons from '../../../components/NotificationFormContainer/AnnouncementCategories';
import { Slider } from "react-semantic-ui-range";
import "semantic-ui-css/semantic.min.css";
interface Props {
    isSaving: boolean;
    onSubmit: (
        message: string,
        roomIds: string[],
        dismissalTimeOffsetMinutes: number,
        sendDeviceNotification: boolean,
        triggerDateTimeString?: string,
        reccurence?: string,
        sendMail?: boolean,
        sendSms?: boolean,
        sendStaffAlert?: boolean
    ) => Promise<any>;
    roomOptions;
    roomGroups;
    deleteRoomGroup: (id: string) => Promise<any>;
    createRoomGroup(name: string, roomIds: string[]);
    disallowRoomControls?: boolean;
    selectedAnnouncees?: string[];
    hideAnnounceeSelection?: boolean;
    selectedDate?: number;
    sourcepage?: "home" // add other source page as union types later if required 
    selectedRoomA4hId?: string
    profile: UserProfile | null;
    residentId?: string;
}

interface State {
    message: string;
    selectedRooms: string[];
    disableIndividualRooms: boolean;
    disableSubmit: boolean;
    dismissalTimeOffsetMinutes: number;
    errMsg: string;
    warnMsg: string;
    sendDeviceNotification: boolean;
    scheduleAnnouncement: boolean;
    repeatType: string;
    triggerDateTimeString: string;
    endDateTimeString: string;
    saveAsGroup: boolean;
    roomGroupName: string;
    savingRoomGroup: boolean;
    saveGroupErr: string;
    hideSaveAsGroup: boolean;
    openManageGroups: boolean;
    deleteGroup: string;
    deletingRoomGroup: boolean;
    delGroupErr: string;
    sendSms: boolean;
    sendMail: boolean;
    sendFacebookAlert: boolean,
    sendTwitterAlert: boolean,
    sendInstagramAlert: boolean,
    sendLinkedinAlert: boolean,
    sendStaffAlert: boolean,
    facilityTimeZone: string | undefined;
    loading : boolean;
    selectedAnnouncementCategories: string[];
    sendAlexaAnnouncement: boolean;
}

class NotificationsForm extends React.Component<Props, State> {
    getDefaultTriggerDateTimeString = () => {
        /*
            moment.tz('10 AM', 'hh A', 'America/New_York') // Creates a 10 AM moment instance in new york time zone
            moment('10 AM', 'hh A').tz('America/New_York') // Creates a 10 AM moment instance in local time zone and then converts it to new york time zone. if local timezone is IST this will return 11:30 PM of the previous day
        */
        const currentTime = moment.tz(this.state.facilityTimeZone);
        const isBeforeNineAm = currentTime.hour() <= 9;
        let defaultDate;
    
        if (this.props.selectedDate && new Date(this.props.selectedDate) > new Date()) {
            defaultDate = moment(this.props.selectedDate);
        } else if (isBeforeNineAm) {
            defaultDate = currentTime.clone().hour(9).minute(0).second(0);
        } else {
            defaultDate = currentTime.clone().add(5, 'minutes');
        }
        return defaultDate.format('YYYY-MM-DDTHH:mm:ss');
    };
    state: State = {
        message: '',
        selectedRooms: this.props.selectedRoomA4hId ? [this.props.selectedRoomA4hId] : this.props.disallowRoomControls ? [] : ['all'],
        disableIndividualRooms: true,
        disableSubmit: true,
        dismissalTimeOffsetMinutes: 5,
        errMsg: '',
        warnMsg: '',
        sendDeviceNotification: true,
        scheduleAnnouncement: this.props.selectedDate && new Date(this.props.selectedDate) > new Date() ? true : false,
        repeatType: 'OFF',
        triggerDateTimeString: moment().format('YYYY-MM-DDTHH:mm:ss'),
        endDateTimeString: moment().format('YYYY-MM-DDTHH:mm:ss'),
        saveAsGroup: false,
        roomGroupName: '',
        savingRoomGroup: false,
        saveGroupErr: '',
        hideSaveAsGroup: false,
        openManageGroups: false,
        deleteGroup: '',
        deletingRoomGroup: false,
        delGroupErr: '',
        sendSms: false,
        sendMail: false,
        sendFacebookAlert: false,
        sendTwitterAlert: false,
        sendInstagramAlert: false,
        sendLinkedinAlert: false,
        sendStaffAlert: false,
        facilityTimeZone: '',
        loading: false,
        selectedAnnouncementCategories: ['Alexa'],
        sendAlexaAnnouncement: true,
    };

    handleChange(e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) {
        if (data.value.length >= 60) {
            this.setState({
                warnMsg: 'Only the first 60 characters shall be shown on pinned and rotating alerts',
            });
        } else {
            this.setState({
                warnMsg: '',
            });
        }
        this.setState({
            message: data.value,
        })
        this.handleSubmitBtnEnable(data, 'Message');
    }

    annoucementCategories: {category : string,stateKey?: string}[] = [
        { category: "Alexa", stateKey: 'sendAlexaAnnouncement' },
        { category: "Text", stateKey: 'sendSms' },
        { category: "Email", stateKey: 'sendMail' },
        { category: "Staff", stateKey: 'sendStaffAlert' }
    ];

    handleDropdownChange(roomIds) {
        // console.log({ roomIds });
        const roomsHandlingGroups = roomIds.map((roomId) => {
            if (roomId.indexOf(',') !== -1) {
                return roomId.split(',');
            }
            return roomId;
        });
        const rooms = roomsHandlingGroups.flat();

        if (!rooms.includes('all') && rooms.length > 1 && this.state.hideSaveAsGroup) {
            this.setState({
                hideSaveAsGroup: false,
            });
        }

        if (rooms.includes('all')) {
            this.setState({
                selectedRooms: ['all'],
                disableIndividualRooms: true,
                sendDeviceNotification: true,
            }, () => this.handleSubmitBtnEnable(rooms, 'Rooms'));
        } else {
            this.setState({
                selectedRooms: rooms,
                sendDeviceNotification: false,
            }, () => {this.handleSubmitBtnEnable(rooms, 'Rooms')});
        }
        
    }

    handleSubmitBtnEnable(data:any = null, key:string = '') {
        
        // validate message
        const message = key === 'Message' ? data.value && data.value : this.state.message
        const validMessage = !!(message.length);
        
        // validate selected rooms
        const selectedRooms = key === 'Rooms' ? data : this.state.selectedRooms;
        const validRooms = !!(this.props.roomOptions.length > 1 && selectedRooms.length);
        
        // // validate error
        // const notError = !this.state.errMsg 
        
        // validate alerts - this takes care of room validation hence it is skipped in the final check (allowSubmit)
        const { sendStaffAlert, sendSms, sendMail, sendDeviceNotification } = this.state;
        const smsOrMailOrNotificationEnabled = sendSms || sendMail || sendDeviceNotification;
        const allowAlerts = !!((sendStaffAlert && !smsOrMailOrNotificationEnabled) || validRooms)
        
        // staff alert edge case - when staff alert is selected we allow announcements regardless room selection

        // validate scheduled announcements
        const onlyFutureAnnouncement = this.state.scheduleAnnouncement && this.state.triggerDateTimeString.length === 19 && this.state.repeatType == 'OFF';
        const recurringAnnouncement = this.state.scheduleAnnouncement && this.state.triggerDateTimeString.length === 19 && this.state.repeatType != 'OFF' && this.state.endDateTimeString.length === 19;
        const allowScheduled = this.state.scheduleAnnouncement ? !!(onlyFutureAnnouncement || recurringAnnouncement) : true;

        const allowSubmit = validMessage && allowAlerts && allowScheduled;
        this.setState({
            disableSubmit: !allowSubmit
        })

    }

    handleSubmit() {
        const dismissalTimeOffsetMinutes = this.state.dismissalTimeOffsetMinutes;
        const formattedDate = (date) => moment(date).format('YYYYMMDD');
        let recurrence = '';
        let triggerDateTimeString = '';
        if (this.state.scheduleAnnouncement) {
            const dtstart = formattedDate(this.state.triggerDateTimeString);
            const until =
                this.state.repeatType == 'OFF'
                    ? formattedDate(this.state.triggerDateTimeString)
                    : formattedDate(this.state.endDateTimeString);
            const freq = this.state.repeatType === 'OFF' ? 'DAILY' : this.state.repeatType;
            recurrence = `DTSTART:${dtstart}\nRRULE:FREQ=${freq};UNTIL=${until}`;
            triggerDateTimeString = this.state.triggerDateTimeString;
        }
        this.props.onSubmit(
            this.state.message,
            this.state.selectedRooms,
            dismissalTimeOffsetMinutes,
            this.state.sendDeviceNotification,
            triggerDateTimeString,
            recurrence,
            this.state.sendMail,
            this.state.sendSms,
            this.state.sendStaffAlert,
        );
        if (!this.state.facilityTimeZone) {
            toast.error('Facility timezone not found. Please have it updated', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        };
        if (this.props.selectedAnnouncees) {
            const selectedRooms = this.getSelectedRoomsWhenRoomSelectionDisabled();
            this.setState({
                message: '',
                selectedRooms: selectedRooms,
                disableIndividualRooms: true,
                dismissalTimeOffsetMinutes: 5,
                sendDeviceNotification: true,
                warnMsg: '',
                saveAsGroup: false,
                scheduleAnnouncement: false,
                triggerDateTimeString: moment.tz(new Date(), this.state.facilityTimeZone).add(5, 'minutes').format('YYYY-MM-DDTHH:mm:ss'),
                endDateTimeString: moment.tz(((24 * 60 * 60 * 1000) + Date.now()), this.state.facilityTimeZone).format('YYYY-MM-DDTHH:mm:ss'),
                repeatType: 'OFF',
                sendMail: false,
                sendSms: false,
                sendStaffAlert: false,
                selectedAnnouncementCategories: ['Alexa']
            }, () => {
                this.handleSubmitBtnEnable();
            });
        } else {
            this.setState({
                message: '',
                selectedRooms: ['all'],
                disableIndividualRooms: true,
                dismissalTimeOffsetMinutes: 5,
                sendDeviceNotification: true,
                warnMsg: '',
                saveAsGroup: false,
                scheduleAnnouncement: false,
                triggerDateTimeString: moment.tz(new Date, this.state.facilityTimeZone).add(5, 'minutes').format('YYYY-MM-DDTHH:mm:ss'),
                endDateTimeString: moment.tz(((24 * 60 * 60 * 1000 ) + Date.now()), this.state.facilityTimeZone).format('YYYY-MM-DDTHH:mm:ss'),
                repeatType: 'OFF',
                sendMail: false,
                sendSms: false,
                sendStaffAlert: false,
            }, () => {
                this.handleSubmitBtnEnable();
            });
        }
    }

    async saveRoomGroupName(name: string) {
        this.setState({
            savingRoomGroup: true,
        });
        try {
            //make call to save rooms as group
            await this.props.createRoomGroup(name, this.state.selectedRooms);

            this.setState({
                savingRoomGroup: false,
                saveAsGroup: false,
                hideSaveAsGroup: true, //hack
            });
        } catch (e) {
            this.setState({
                saveGroupErr: e.message ? e.message : 'Save failed! Please retry.',
                savingRoomGroup: false,
            });
        }
    }

    async handleDelGroup() {
        console.log('deleting group', this.state.deleteGroup);
        try {
            this.setState({
                deletingRoomGroup: true,
            });

            await this.props.deleteRoomGroup(this.state.deleteGroup);

            this.setState({
                deletingRoomGroup: false,
                openManageGroups: false,
            });
        } catch (e) {
            console.error(e);
            this.setState({
                deletingRoomGroup: false,
                delGroupErr: 'Delete failed',
            });
        }
    }

    getA4HRoomIdsAndSyncState(announceeNames) {
        if (announceeNames) {
            const announceeRooms = this.props.roomOptions.filter((room) => {
                return announceeNames.includes(room.text);
            });
            const A4HRoomIds = announceeRooms.map((room) => {
                return room.value;
            });
            this.setState({ selectedRooms: A4HRoomIds });
            return A4HRoomIds;
        }
        return [];
    }

    getSelectedRoomsWhenRoomSelectionDisabled = () => {
        if (this.props.selectedAnnouncees) {
            const announceeA4HIds = this.getA4HRoomIdsAndSyncState(this.props.selectedAnnouncees);
            return announceeA4HIds;
        }
    };

    async componentDidMount() {
        this.setState({ loading: true });
        if (this.props.selectedAnnouncees) {
            const selectedRooms = this.getSelectedRoomsWhenRoomSelectionDisabled();
            this.setState({ selectedRooms: selectedRooms });
        }
        if (this.props.profile && this.props.profile.Facility) {
            const facilityId = this.props.profile && this.props.profile.Facility;
            try {
                const FacilityTimeZone = await fetchFacilityTimezoneWithFacilityId(facilityId);
                if (!FacilityTimeZone) throw new Error('Facility timezone not found');
                console.log('FacilityTimeZone', FacilityTimeZone);
                this.setState({ facilityTimeZone: FacilityTimeZone }, () => {
                    this.setState(
                        {
                            triggerDateTimeString: this.getDefaultTriggerDateTimeString(),
                            endDateTimeString:
                                this.props.selectedDate && new Date(this.props.selectedDate) > new Date()
                                    ? moment(
                                        24 * 60 * 60 * 1000 + (new Date(this.props.selectedDate).getTime() || Date.now()),
                                    ).format('YYYY-MM-DDTHH:mm:ss')
                                    : moment
                                        .tz(Date.now(), FacilityTimeZone)
                                        .format('YYYY-MM-DDTHH:mm:ss'), // set default endTime to be next day
                        },
                        () => {
                            this.handleSubmitBtnEnable();
                        },
                    );
                });
                this.setState({ loading: false });
            } catch (error) {
                console.error(error);
                toast.error('Facility timezone not found. Please have it updated', {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            }
            
        } else throw new Error('Profile not found');
        this.handleSubmitBtnEnable();
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedAnnouncees) {
            if (
                this.props.selectedAnnouncees !== prevProps.selectedAnnouncees ||
                this.props.roomOptions.length !== prevProps.roomOptions.length
            ) {
                const announceeA4HIds = this.getA4HRoomIdsAndSyncState(this.props.selectedAnnouncees);
                this.setState({ selectedRooms: announceeA4HIds });
            }
        }
        if (this.props.selectedRoomA4hId && this.props.residentId) {
            if (this.props.selectedRoomA4hId !== prevProps.selectedRoomA4hId) {
                this.setState({ selectedRooms: [this.props.selectedRoomA4hId] });
            }
        }
    }

    handleIconEnter(e) {
        e.target.style.opacity = '0.7';
    }

    handleIconLeave(e) {
        e.target.style.opacity = '1';
    }

    handleIconClick(e, stateName) {
        e.preventDefault();
        if (stateName === 'sendAlexaAnnouncement'){ 
            return; // Alexa is always selected
        }
        const selectedAnnouncementCategory =  this.annoucementCategories.find(category => category.stateKey === stateName);
        const alreadySelectedIndex = selectedAnnouncementCategory ? this.state.selectedAnnouncementCategories.indexOf( selectedAnnouncementCategory.category) : -1;
        if (alreadySelectedIndex !== -1) {
            // Category is already selected, so remove it
            const updatedSelectedCategories = [...this.state.selectedAnnouncementCategories];
            updatedSelectedCategories.splice(alreadySelectedIndex, 1);
    
            this.setState(
                {
                    ...this.state,
                    selectedAnnouncementCategories: updatedSelectedCategories,
                    [stateName]: !this.state[stateName],
                },
                () => {
                    this.handleSubmitBtnEnable();
                }
            );
        } else {
            // Category is not selected, so add it
            this.setState(
                {
                    ...this.state,
                    selectedAnnouncementCategories: selectedAnnouncementCategory ? [...this.state.selectedAnnouncementCategories, selectedAnnouncementCategory.category] : [...this.state.selectedAnnouncementCategories],
                    [stateName]: !this.state[stateName],
                },
                () => {
                    this.handleSubmitBtnEnable();
                }
            );
        }
    }

    renderNewAnnouncementField() {
        return <Form.Input
            placeholder="New Announcement"
            value={this.state.message}
            onChange={this.handleChange.bind(this)}
        />
    }

    renderCheckboxField() {
        return <Form.Field>
            <Checkbox
                toggle
                checked={this.state.scheduleAnnouncement}
                label={<label className="light-font" style={{ color: "#5C5D5D", fontSize: "15px" }}>Schedule</label>}
                onChange={(e, d) => {
                    this.setState({
                        triggerDateTimeString: this.getDefaultTriggerDateTimeString(),
                        endDateTimeString: moment.tz(((24 * 60 * 60 * 1000) + Date.now()), this.state.facilityTimeZone).format('YYYY-MM-DDTHH:mm:ss'),
                    });
                    this.handleSubmitBtnEnable();
                    this.setState({
                        scheduleAnnouncement: Boolean(d.checked),
                        sendDeviceNotification: false,
                    });
                }}
            />
        </Form.Field>
    }

    renderRoomSelectionField() {
        return (!this.props.hideAnnounceeSelection && (
            <Form.Field width={15}>
                <Dropdown
                    fluid={true}
                    closeOnEscape
                    defaultValue={
                        this.props.disallowRoomControls ? '' : this.props.roomOptions[0].value
                    }
                    value={this.state.selectedRooms}
                    multiple
                    clearable
                    search
                    scrolling
                    selection
                    options={sortByKey(this.props.roomOptions)}
                    disabled={this.props.disallowRoomControls}
                    onChange={(e, d) => this.handleDropdownChange(d.value)}
                />
            </Form.Field>
        ))
    }

    renderGroupsField() {
        return (!this.props.hideAnnounceeSelection && (
            <Form.Field width={3} style={{ display: 'flex', justifyContent: this.props.sourcepage === "home" ? "center" :  'flex-end' }}>
                {!this.state.hideSaveAsGroup && !this.props.disallowRoomControls && (
                    <Modal
                        style={{ border: '2px solid #183466' }}
                        size="mini"
                        open={this.state.saveAsGroup}
                        trigger={
                            <Popup
                                content="Save as Group"
                                trigger={
                                    <Button
                                        icon="save"
                                        basic
                                        color="green"
                                        disabled={this.state.selectedRooms.length <= 1}
                                        onClick={(e, d) => {
                                            e.preventDefault();
                                            this.setState({
                                                saveAsGroup: !this.state.saveAsGroup,
                                            });
                                        }}
                                    />
                                }
                            />
                        }
                    >
                        <Header>Enter group name</Header>
                        <Modal.Content>
                            <input
                                onChange={(e) =>
                                    this.setState({
                                        roomGroupName: e.target.value,
                                    })
                                }
                            />

                            {this.state.saveGroupErr && (
                                <Message negative>{this.state.saveGroupErr}</Message>
                            )}
                        </Modal.Content>

                        <Modal.Actions>
                            <Button
                                basic
                                color="red"
                                onClick={() =>
                                    this.setState({
                                        roomGroupName: '',
                                        saveAsGroup: false,
                                        saveGroupErr: '',
                                    })
                                }
                            >
                                <Icon name="remove" /> Cancel
                            </Button>
                            <Button
                                basic
                                color="green"
                                loading={this.state.savingRoomGroup}
                                onClick={() =>
                                    this.state.roomGroupName &&
                                    this.saveRoomGroupName(this.state.roomGroupName)
                                }
                            >
                                <Icon name="checkmark" /> Save
                            </Button>
                        </Modal.Actions>
                    </Modal>
                )}

                {!this.props.disallowRoomControls && (
                    <Modal
                        style={{ border: '2px solid #183466' }}
                        size="mini"
                        open={this.state.openManageGroups}
                        trigger={
                            <Popup
                                content="Manage groups"
                                trigger={
                                    <Button
                                        icon="edit"
                                        basic
                                        color="blue"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({
                                                openManageGroups: true,
                                            });
                                        }}
                                    />
                                }
                            />
                        }
                    >
                        <Header>Manage room groups</Header>
                        <Modal.Content>
                            <Dropdown
                                placeholder="Select group to delete"
                                closeOnEscape
                                selection
                                fluid
                                search
                                scrolling
                                onChange={(e, d) => {
                                    const groupObj = this.props.roomGroups.find(
                                        (g) => g.value === d.value,
                                    );
                                    groupObj && this.setState({ deleteGroup: groupObj.key });
                                }}
                                options={sortByKey(this.props.roomGroups)}
                            />

                            {this.state.delGroupErr && (
                                <Message negative>{this.state.delGroupErr}</Message>
                            )}
                        </Modal.Content>

                        <Modal.Actions>
                            <Button
                                style={{ backgroundColor: '#2987CD', color: 'white' }}
                                onClick={() =>
                                    this.setState({
                                        openManageGroups: false,
                                        deleteGroup: '',
                                        delGroupErr: '',
                                    })
                                }
                            >
                                <Icon name="cancel" /> Cancel
                            </Button>
                            <Button
                                color="red"
                                loading={this.state.deletingRoomGroup}
                                disabled={!this.state.deleteGroup}
                                onClick={this.handleDelGroup.bind(this)}
                            >
                                <Icon name="trash" /> Delete
                            </Button>
                        </Modal.Actions>
                    </Modal>
                )}
            </Form.Field>
        ))
    }

    renderSelectedAnnoucementField() {
        return (this.state.scheduleAnnouncement && (
            <Form.Field>
                <Dropdown
                    placeholder="Repeats"
                    defaultValue={'OFF'}
                    selection
                    options={[
                        {
                            key: 'Off',
                            text: 'Repeat off',
                            value: 'OFF',
                        },
                        {
                            key: 'Daily',
                            text: 'Repeat daily',
                            value: 'DAILY',
                        },
                        {
                            key: 'Weekly',
                            text: 'Repeat weekly',
                            value: 'WEEKLY',
                        },
                        {
                            key: 'Monthly',
                            text: 'Repeat monthly',
                            value: 'MONTHLY',
                        },
                    ]}
                    onChange={(e, d) => {
                        this.setState(
                            {
                                repeatType: String(d.value),
                                sendDeviceNotification: false,
                            },
                            () => {
                                this.handleSubmitBtnEnable();
                            },
                        );
                    }}
                />
            </Form.Field>
        ))
    }

    renderAddToNotificationsField() {
        return (
            <Form.Field>
                <Checkbox
                    label="Add to notifications"
                    checked={this.state.sendDeviceNotification}
                    onChange={(e, d) => {
                        this.setState(
                            {
                                sendDeviceNotification: Boolean(d.checked),
                            },
                            () => this.handleSubmitBtnEnable(),
                        );
                    }}
                />
            </Form.Field>
        );
    }

    renderRangeField() {
        return (<Form.Field style={{ display: 'flex', alignItems: 'center' }}>
            <input
                min={5}
                max={60}
                onChange={(e) =>
                    this.setState({
                        dismissalTimeOffsetMinutes: Number(e.target.value),
                    })
                }
                step={1}
                type="range"
                value={this.state.dismissalTimeOffsetMinutes}
            />
            &nbsp;&nbsp;
            <p>{this.state.dismissalTimeOffsetMinutes} minutes on Screen</p>
        </Form.Field>)
    }

    renderStartDateField() {
        const facilityDateTimeString = moment.tz(this.state.facilityTimeZone).format('YYYY-MM-DDTHH:mm:ss');
        return (this.state.scheduleAnnouncement ? (
            <Form.Field>
                <div className="customDatePickerWidth">
                    <DatePicker
                        style={{ border: '1px solid #183466' }}
                        customInput={
                            <div
                                style={{
                                    position: 'relative',
                                    boxShadow: '0px 1px 2px 0 rgba(34, 36, 38, 0.15)',
                                }}
                            >
                                <Icon
                                    name="calendar alternate outline"
                                    style={{
                                        position: 'absolute',
                                        top: '45%',
                                        left: '10px',
                                        transform: 'translateY(-50%)',
                                    }}
                                />
                                <input
                                    style={{ paddingLeft: '30px' }}
                                    placeholder="Schedule start *"
                                    value={moment(this.state.triggerDateTimeString).format('M.D.Y h:mm a') || ""}
                                />
                            </div>
                        }
                        placeholderText="Start Date"
                        dateFormat="M.d.Y h:mm aa"
                        selectsStart
                        selected={new Date(this.state.triggerDateTimeString)}
                        showTimeSelect
                        timeIntervals={15}
                        onChange={(date: Date) => {
                            this.setState({ triggerDateTimeString: moment(date).format('YYYY-MM-DDTHH:mm:ss') }, () => {
                                this.handleSubmitBtnEnable();
                            });
                            if(new Date(this.state.endDateTimeString) < date){
                                this.setState({ endDateTimeString: moment(date).format('YYYY-MM-DDTHH:mm:ss') }, () => {
                                    this.handleSubmitBtnEnable();
                                });
                            }
                        }}
                        startDate={Date.parse(this.state.triggerDateTimeString)}
                        minDate={new Date(facilityDateTimeString)}
                        minTime={new Date(this.state.triggerDateTimeString).toDateString() ===
                            new Date(facilityDateTimeString).toDateString()
                            ? new Date(facilityDateTimeString)
                            : false}
                        maxTime={new Date(this.state.triggerDateTimeString).toDateString() ===
                            new Date(facilityDateTimeString).toDateString()
                            ? new Date(facilityDateTimeString).setHours(23, 59, 0, 0)
                            : false}
                        popperPlacement="left"
                        popperModifiers={{ preventOverflow: { enabled: true } }}
                    />
                </div>
            </Form.Field>
        ) : (
            <></>
        ))
    }

    renderSubmitButton() {
        return (<Form.Button type="submit" primary disabled={this.state.disableSubmit}>
            Send Announcement
        </Form.Button>)
    }

    renderAnnoucementAlertIcons() {
        return (<Form.Field className="announcement-alerts-group">
            <AnnouncementAlertIcon
                imgSrc={
                    this.state.sendSms
                        ? `${process.env.PUBLIC_URL}/announcements/sms-enabled.svg`
                        : `${process.env.PUBLIC_URL}/announcements/sms.svg`
                }
                type={'SMS'}
                click={(e) => this.handleIconClick(e, 'sendSms')}
                mouseEnter={(e) => this.handleIconEnter(e)}
                mouseLeave={(e) => this.handleIconLeave(e)}
            // disabled={true}
            />
            <AnnouncementAlertIcon
                imgSrc={
                    this.state.sendMail
                        ? `${process.env.PUBLIC_URL}/announcements/email-enabled.svg`
                        : `${process.env.PUBLIC_URL}/announcements/email.svg`
                }
                type={'email'}
                click={(e) => this.handleIconClick(e, 'sendMail')}
                mouseEnter={(e) => this.handleIconEnter(e)}
                mouseLeave={(e) => this.handleIconLeave(e)}
            // disabled={true}
            />
            <AnnouncementAlertIcon
                imgSrc={
                    this.state.sendFacebookAlert
                        ? `${process.env.PUBLIC_URL}/announcements/facebook-enabled.svg`
                        : `${process.env.PUBLIC_URL}/announcements/facebook.svg`
                }
                type={'facebook'}
                click={(e) => this.handleIconClick(e, 'sendFacebookAlert')}
                mouseEnter={(e) => this.handleIconEnter(e)}
                mouseLeave={(e) => this.handleIconLeave(e)}
                disabled={true}
            />
            <AnnouncementAlertIcon
                imgSrc={
                    this.state.sendTwitterAlert
                        ? `${process.env.PUBLIC_URL}/announcements/twitter-enabled.svg`
                        : `${process.env.PUBLIC_URL}/announcements/twitter.svg`
                }
                type={'twitter'}
                click={(e) => this.handleIconClick(e, 'sendTwitterAlert')}
                mouseEnter={(e) => this.handleIconEnter(e)}
                mouseLeave={(e) => this.handleIconLeave(e)}
                disabled={true}
            />
            <AnnouncementAlertIcon
                imgSrc={
                    this.state.sendInstagramAlert
                        ? `${process.env.PUBLIC_URL}/announcements/instagram-enabled.svg`
                        : `${process.env.PUBLIC_URL}/announcements/instagram.svg`
                }
                type={'instagram'}
                click={(e) => this.handleIconClick(e, 'sendInstagramAlert')}
                mouseEnter={(e) => this.handleIconEnter(e)}
                mouseLeave={(e) => this.handleIconLeave(e)}
                disabled={true}
            />
            <AnnouncementAlertIcon
                imgSrc={
                    this.state.sendLinkedinAlert
                        ? `${process.env.PUBLIC_URL}/announcements/linkedin-enabled.svg`
                        : `${process.env.PUBLIC_URL}/announcements/linkedin.svg`
                }
                type={'linkedIn'}
                click={(e) => this.handleIconClick(e, 'sendLinkedinAlert')}
                mouseEnter={(e) => this.handleIconEnter(e)}
                mouseLeave={(e) => this.handleIconLeave(e)}
                disabled={true}
            />
            <AnnouncementAlertIcon
                imgSrc={
                    this.state.sendStaffAlert
                        ? `${process.env.PUBLIC_URL}/announcements/staff-enabled.svg`
                        : `${process.env.PUBLIC_URL}/announcements/staff.svg`
                }
                type={'staff'}
                click={(e) => this.handleIconClick(e, 'sendStaffAlert')}
                mouseEnter={(e) => this.handleIconEnter(e)}
                mouseLeave={(e) => this.handleIconLeave(e)}
            />
        </Form.Field>)
    }

    renderEndDateField() {
        return (this.state.scheduleAnnouncement && this.state.repeatType != 'OFF' && (
            <Form.Field>
                <div className="customDatePickerWidth">
                    <DatePicker
                        style={{ border: '1px solid #183466' }}
                        customInput={
                            <div
                                style={{
                                    position: 'relative',
                                    boxShadow: '0px 1px 2px 0 rgba(34, 36, 38, 0.15)',
                                }}
                            >
                                <Icon
                                    name="calendar alternate outline"
                                    style={{
                                        position: 'absolute',
                                        top: '45%',
                                        left: '10px',
                                        transform: 'translateY(-50%)',
                                    }}
                                />
                                <input
                                    style={{ paddingLeft: '30px', }}
                                    placeholder="End Datetime"
                                    value={moment(this.state.endDateTimeString).format('M.D.Y h:mm a') || ""}
                                />
                            </div>
                        }
                        placeholderText="End Date"
                        dateFormat="M.d.Y h:mm aa"
                        selectsEnd
                        showTimeSelect
                        timeIntervals={15}
                        selected={Date.parse(this.state.endDateTimeString)}
                        onChange={(date: Date) => {
                            this.setState({ endDateTimeString: moment(date).format('YYYY-MM-DDTHH:mm:ss')}, () => {this.handleSubmitBtnEnable();});
                        }}
                        //## should we replace new Date with facility timezone and moment //// I tried this for some time. could not make it work with minTime. 
                        startDate={Date.parse(this.state.triggerDateTimeString)}
                        endDate={Date.parse(this.state.endDateTimeString)}
                        minDate={this.state.triggerDateTimeString ? new Date(
                            24 * 60 * 60 * 1000 +
                            (new Date(this.state.triggerDateTimeString).getTime() || Date.now()),
                        ) : new Date()}
                        popperPlacement="left"
                        popperModifiers={{ preventOverflow: { enabled: true } }}
                    />
                </div>
            </Form.Field>
        ))
    }
    render() {
        return this.state.loading ? (
            <div style ={{height:'35vh', width: '10vw'}}>
                <Dimmer active={this.state.loading} inverted>
                    <Loader active={this.state.loading}/>
                </Dimmer>
            </div>
        ) : (
            <Form loading={this.props.isSaving} onSubmit={this.handleSubmit.bind(this)} className="form-custom-margin">
                {this.props.sourcepage === 'home' ? (
                        <>
                            <div className='align-center' style={{ padding: "0px 5px" }} >
                                <div style={{ padding: "0px 25px" , maxWidth: "50rem" }} >
                                    <Form.Field className='announcement-input' width={15}>
                                        <input type="text"
                                            placeholder="Type your announcement here"
                                            className="centered-placeholder"
                                            style={{
                                                border: "2px solid #1BB934",
                                                borderRadius: "7px"
                                    
                                            }}
                                            value={this.state.message}
                                            onChange={(e) => {
                                                this.handleChange(e, { value: e.target.value });
                                            }}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <div style={{ display: "flex", width: "100%", position:"relative" }}>
                                            {this.renderRoomSelectionField()}
                                            <Popup
                                                trigger={<Icon
                                                    style={{ position: "absolute", top: "5px", right: "-20px", fontSize: "30px", cursor: "pointer" }}
                                                    disabled={this.state.selectedRooms.length <= 1}
                                                    onClick={(e, d) => {
                                                        e.preventDefault();
                                                        console.log("rached here")
                                                        this.setState({
                                                            saveAsGroup: !this.state.saveAsGroup,
                                                        });
                                                    }}
                                                    size='big'>
                                                    <i className="fa-thin fa-floppy-disk save-group-icon"></i>
                                                </Icon>}
                                                content="Save Group"
                                            />
                                        </div>
                                        <div className="align-center">
                                            <div className='menu-category' style={{ display: "flex", justifyContent: "center",}}>
                                                <Menu borderless >
                                                    <Menu.Menu style={{ display: 'grid', gridTemplateColumns: `repeat(4, 1fr)`, alignSelf: 'center', border: 'none'}}>
                                                        {this.annoucementCategories && this.annoucementCategories.length > 0 && this.annoucementCategories.map((annoucementCategory) => (
                                                                <Menu.Item style={{ border: 'none', boxShadow: 'none' }}
                                                                    className={`${this.state.selectedAnnouncementCategories.includes(annoucementCategory.category) ? 'active' : ''}`}
                                                                    key={annoucementCategory.category}
                                                                    onClick={(e) => { this.handleIconClick(e, annoucementCategory.stateKey); }}
                                                                >
                                                                    <Popup
                                                                        content={annoucementCategory.category}
                                                                        trigger={
                                                                            <div className='align-center'>
                                                                                <AnnouncementIcons name={annoucementCategory.category} size={'large'} />
                                                                                <p style={{ padding: "5px 10px" }}>{annoucementCategory.category}</p>
                                                                            </div>
                                                                        }
                                                                        size="tiny"
                                                                        on="hover"
                                                                        position="top center"
                                                                    />
                                                                </Menu.Item>
                                                        ))}
                                                    </Menu.Menu>
                                                </Menu>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <div style={{ width: "100px" }}>
                                                    <Slider value={this.state.dismissalTimeOffsetMinutes} color="blue" settings={{
                                                        start: 0,
                                                        min: 5,
                                                        max: 60,
                                                        step: 1,
                                                        onChange: value => {
                                                            this.setState({ dismissalTimeOffsetMinutes: value });
                                                        }
                                                    }} />
                                                </div>
                                                &nbsp;
                                                <p className='light-font'>{this.state.dismissalTimeOffsetMinutes} minutes on screen</p>
                                            </div> 
                                        </div>
                                    </Form.Field> 
                                </div>
                                <div style={{ width: "100%" }}>
                                    <hr style={{ border: "0.05rem solid #979797", margin: "10px" }} />
                                </div>
                                <div className="align-center" >
                                    {this.renderCheckboxField()}
                                    {this.renderSelectedAnnoucementField()}
                                    {this.state.scheduleAnnouncement && this.state.repeatType == 'OFF' ? this.renderStartDateField()
                                        : (
                                            <Grid columns={2} stackable centered >
                                                <Grid.Column className="align-center" >
                                                    {this.renderStartDateField()}
                                                </Grid.Column>
                                                <Grid.Column>
                                                    {this.renderEndDateField()}
                                                </Grid.Column>
                                            </Grid>
                                        )
                                    }
                                    <Form.Button style={{ marginTop: "10px", background: "#549FD7", color: "#ffff" , padding: "10px 50px", fontWeight: "200" }} type="submit" disabled={this.state.disableSubmit}>Send</Form.Button>
                                </div>
                            </div>

                            {!this.state.hideSaveAsGroup && !this.props.disallowRoomControls && (
                                <Modal
                                    style={{ border: '2px solid #183466' }}
                                    size="mini"
                                    open={this.state.saveAsGroup}
                                >
                                    <Header>Enter group name</Header>
                                    <Modal.Content>
                                        <input
                                            onChange={(e) =>
                                                this.setState({
                                                    roomGroupName: e.target.value,
                                                })
                                            }
                                        />

                                        {this.state.saveGroupErr && (
                                            <Message negative>{this.state.saveGroupErr}</Message>
                                        )}
                                    </Modal.Content>

                                    <Modal.Actions>
                                        <Button
                                            basic
                                            color="red"
                                            onClick={() =>
                                                this.setState({
                                                    roomGroupName: '',
                                                    saveAsGroup: false,
                                                    saveGroupErr: '',
                                                })
                                            }
                                        >
                                            <Icon name="remove" /> Cancel
                                        </Button>
                                        <Button
                                            basic
                                            color="green"
                                            loading={this.state.savingRoomGroup}
                                            onClick={() =>
                                                this.state.roomGroupName &&
                                                this.saveRoomGroupName(this.state.roomGroupName)
                                            }
                                        >
                                            <Icon name="checkmark" /> Save
                                        </Button>
                                    </Modal.Actions>
                                </Modal>
                            )}
                        </>
                    ) : (
                        <>
                            <Form.Group widths={2}>
                                {this.renderNewAnnouncementField()}
                                {this.renderCheckboxField()}
                            </Form.Group>
                            <Form.Group widths={2}>
                                <Form.Group widths={2}>
                                    {this.renderRoomSelectionField()}
                                    {this.renderGroupsField()}
                                </Form.Group>
                                {this.renderSelectedAnnoucementField()}
                            </Form.Group>
                            <Form.Group widths={2}>
                                <Form.Group widths={2}>
                                    {this.renderAddToNotificationsField()}
                                    {this.renderRangeField()}
                                </Form.Group>
                                {this.renderStartDateField()}
                            </Form.Group>
                            <Form.Group widths={2}>
                                <Form.Group widths={'equal'}>
                                    {/* submit button */}
                                    {this.renderSubmitButton()}
                                    {/* Alert comp */}
                                    {this.renderAnnoucementAlertIcons()}
                                </Form.Group>
                                {/* End Date comp */}
                                {this.renderEndDateField()}
                            </Form.Group>
                        </>
                    )}
                {this.state.errMsg && <Message negative>{this.state.errMsg}</Message>}
                {this.state.warnMsg && <Message color="grey">{this.state.warnMsg}</Message>}
            </Form>
        );
    }
}

function mapStateToProps({ authReducer }: AppState) {
    return {
        profile: authReducer.profile,
    };
}

export default connect(mapStateToProps)(NotificationsForm);