import React from 'react'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom'
import { Icon, Dimmer, Loader } from 'semantic-ui-react'
import { fetchOneRequest, acceptRequest, closeRequest, setRequestSentiment, unAcceptRequest, closeAllRequests } from '../../../../services/Requests'
import { UserProfile } from '../../../../types'
import './style.less'
import { AppState } from '../../../../reducers'
import { connect } from 'react-redux'
import { distanceInWordsToNowWithTimezone, formatDateWithTZ } from '../../../../util/timezone'
import { RequestInstance } from '../../../../types/RequestInstance';
import moment from 'moment';
import CreateRequestModal from '../../../../components/Requests/CreateRequestModal';
import momentTimezone from 'moment-timezone';
import StandaloneDetailedForm from '../../../../components/Requests/StandaloneDetailedForm';
import CloseBtn from '../../../../components/Requests/DetailedViewButtons/CloseBtn';
import AcceptBtn from '../../../../components/Requests/DetailedViewButtons/AcceptBtn';
import UnacceptBtn from '../../../../components/Requests/DetailedViewButtons/UnacceptBtn';

interface RouteInfo {
    id: string
}
interface Props extends RouteComponentProps<RouteInfo> {
    profile?: UserProfile | null
    requestId?: string
    refresh?: () => void
    similarRequestIds?: string[]
}

interface State {
    isFetching: boolean
    hasError: boolean
    error: string | null
    request: RequestInstance | null
}

class SingleRequest extends React.Component<Props, State> {
    id: string
    constructor(props: Props) {
        super(props)
        this.id = props.match.params.id || props.requestId || ''
        this.state = {
            isFetching: false,
            hasError: false,
            error: null,
            request: null,
        }
    }

    async componentDidMount() {
        await this.fetchRequestDetails();
    }

    async fetchRequestDetails() {
        this.setState({
            isFetching: true,
            hasError: false,
            error: null,
        })
        try {
            const res = await fetchOneRequest(this.id)
            this.setState({
                isFetching: false,
                request: res,
                hasError: res ? false : true,
                error: res ? null : `Could not find request ${this.id}`,
            })
        } catch (e) {
            this.setState({
                isFetching: false,
                hasError: true,
                error: e.message,
            })
        }
    }

    goHome() {
        this.props.refresh && this.props.refresh();
        this.props.history.push('/')
    }

    formatAcceptedTime(time) {
        if (!time) return undefined;

        const facilityTimezone = this.props.profile && this.props.profile.FacilityTimeZone || "";

        const formatString = moment(new Date(time)).year() > moment().year()
            ? 'MMM DD, YYYY, hh:mm A'
            : 'MMM DD, hh:mm A';

        return formatDateWithTZ(time, formatString, facilityTimezone);
    }

    formatSvcRequestTime = (time: number | Date | undefined) => {
        // collects time in timestamp and returns a string in the format "at 12:00 PM on Mar 19, 2021"
        if (!time) return "";
        const facilityTimezone = this.props.profile && this.props.profile.FacilityTimeZone || "";
        if (!facilityTimezone) {
            return "";
        }
        const formatString = 'h:mm A on MMM D, YYYY';
        const formattedTime = 'at ' + momentTimezone.tz(time, facilityTimezone).format(formatString);
        return formattedTime;
    }

    async handleAccept() {
        this.setState({
            isFetching: true,
            hasError: false,
            error: null,
        })
        try {
            const newRequest = await acceptRequest(this.id)
            this.setState({
                request: newRequest,
                isFetching: false,
                hasError: false,
            })
            this.goHome()
        } catch (e) {
            console.log(e)
            this.setState({
                request: null,
                hasError: true,
                error: e.message,
            })
        }
    }

    async handleClose() {
        this.setState({
            isFetching: true,
            hasError: false,
            error: null,
        });
        if (!(this.props && this.props.similarRequestIds) ) {
            try {
                const newRequest = await closeRequest(this.id);
                this.setState({
                    request: newRequest,
                    isFetching: false,
                    hasError: false,
                });
                this.props.refresh && this.props.refresh();
                this.props.history.push('/');
            } catch (e) {
                this.setState({
                    isFetching: false,
                    hasError: true,
                    error: e.message,
                });
            }
        } 
        if (this.props &&
            this.props.similarRequestIds &&
            Array.isArray(this.props.similarRequestIds) &&
            this.props.similarRequestIds.length > 0
        ) {
            try {
                const ids = this.props.similarRequestIds;
                await closeAllRequests(ids);
                this.setState({
                    isFetching: false,
                    hasError: false,
                });
                this.props.refresh && this.props.refresh();
                this.props.history.push('/');
            } catch (e) {
                this.setState({
                    isFetching: false,
                    hasError: true,
                    error: e.message,
                });
            }
        }
    }

    async handleUnAccept() {
        this.setState({
            isFetching: true,
            hasError: false,
            error: null,
        })
        try {
            const newRequest = await unAcceptRequest(this.id)
            this.setState({
                request: newRequest,
                isFetching: false,
            })
            this.goHome()
        } catch (e) {
            console.log(e)
            this.setState({
                request: null,
                isFetching: false,
                hasError: true,
                error: e.message,
            })
        }
    }

    async setSentiment(sentiment: number) {
        if (!this.state.request) return
        this.setState({
            isFetching: true,
            hasError: false,
            error: null,
            request: {
                ...this.state.request,
                Sentiment: sentiment,
            },
        })
        try {
            const newRequest = await setRequestSentiment(this.id, sentiment)
            this.setState({
                request: newRequest,
                isFetching: false,
                hasError: false,
            })
        } catch (e) {
            this.setState({
                isFetching: false,
                hasError: true,
                error: e.message,
            })
        }
    }

    renderFaces(request: RequestInstance) {
        const isAccepted = request.Status === 'Accepted'
        const isClosed = request.Status === 'Closed'
        const faces = [
            <Icon
                key={1}
                className="face"
                onClick={() => this.setSentiment(1)}
                name="frown outline"
                color="red"
                size={request.Sentiment ? (request.Sentiment === 1 ? 'huge' : 'big') : 'big'}
            />,
            <Icon
                key={2}
                className="face"
                onClick={() => this.setSentiment(2)}
                name="frown outline"
                color="orange"
                size={request.Sentiment ? (request.Sentiment === 2 ? 'huge' : 'big') : 'big'}
            />,
            <Icon
                key={3}
                className="face"
                onClick={() => this.setSentiment(3)}
                name="meh outline"
                color="yellow"
                size={request.Sentiment ? (request.Sentiment === 3 ? 'huge' : 'big') : 'big'}
            />,
            <Icon
                key={4}
                className="face"
                onClick={() => this.setSentiment(4)}
                name="smile outline"
                color="olive"
                size={request.Sentiment ? (request.Sentiment === 4 ? 'huge' : 'big') : 'big'}
            />,
            <Icon
                key={5}
                className="face"
                onClick={() => this.setSentiment(5)}
                name="smile outline"
                color="green"
                size={request.Sentiment ? (request.Sentiment === 5 ? 'huge' : 'big') : 'big'}
            />,
        ]
        const faceButtons = <div className="faces">{faces}</div>
        if (isAccepted && !request.Sentiment && !isClosed) return faceButtons
        if (isAccepted && request.Sentiment) return faceButtons
        return null
    }

    renderRequest(request: RequestInstance) {
        const isOpen = request.Status === 'Open'
        const isClosed = request.Status === 'Closed'
        const isAccepted = request.Status === 'Accepted'

        const closeBtn = (disable: boolean) => {
            return (
                <CloseBtn
                    disabled={isClosed || this.state.isFetching || disable}
                    onClickHandler={() => this.handleClose()}
                    title={(this.props && this.props.similarRequestIds) ? 'Close All' : 'Close'}
                />
            );
        }
        const acceptBtn = (
            <AcceptBtn 
                disabled={this.state.isFetching}
                onClickHandler={this.handleAccept.bind(this)}
            />
        )

        const unacceptBtn = (
            <UnacceptBtn
                disabled={this.state.isFetching}
                onClickHandler={this.handleUnAccept.bind(this)}
                title={'Unaccept'}
            />
        )
        
        const generateReqTimeDiffString = (request) => {
            const isScheduled = request.ScheduledTime && request.ScheduledTime.startDate
            const facilityTZ = this.props.profile && this.props.profile.FacilityTimeZone || "";

            if(isScheduled) {
                return `Scheduled ${distanceInWordsToNowWithTimezone(request.ScheduledTime.startDate, facilityTZ)}`
            } else {
                return `Created ${distanceInWordsToNowWithTimezone(request.RequestedTime, facilityTZ)}`
            }
        }
        const renderServiceRequestDetails = (request: RequestInstance) => {
            return (
                <>
                    <div className="header" style={{ flexDirection: 'column' }}>
                        <CreateRequestModal
                            facilityId={this.props.profile && this.props.profile.Facility || ""}
                            request={request}
                            refresh={this.goHome.bind(this)}
                            cancelClickHandler={this.goHome.bind(this)}
                            parentLoading={this.state.isFetching}
                            handleOnClose={this.handleClose.bind(this)}
                            handleOnAccept={this.handleAccept.bind(this)}
                            isStaffUser={true}
                        />
                    </div>
                </>
            );
        };
        const renderStandaloneRequestDetails = (request: RequestInstance) => {
            return (
                <StandaloneDetailedForm 
                    request={request} 
                    renderFaces={this.renderFaces.bind(this)}
                    closeBtn={closeBtn}
                    unacceptBtn={unacceptBtn}
                    locationState={this.props.similarRequestIds}
                    acceptBtn={acceptBtn}
                    isStaffUser={true}
                    parentLoading={this.state.isFetching}
                />
            );
        };

        return (
            <div className="request-view" style={{ backgroundColor: "#E0E1E2", border: "1px solid #99A0A9" }}>
                {
                    (request.OriginalServiceId || request.OriginalServiceInstanceId) ? renderServiceRequestDetails(request) : renderStandaloneRequestDetails(request)
                }
                {(this.state.hasError && <div className="error">{this.state.error}</div>) || null}
            </div>
        )
    }

    renderLoadingOrError() {
        const { isFetching, hasError, error } = this.state
        if (isFetching) {
            return (
                <Dimmer active={isFetching} inverted>
                    <Loader active={isFetching} />
                </Dimmer>
            )
        }

        if (hasError && error) {
            return (
                <div className="error">
                    {error} <br />
                    <Link to="/">Go Back</Link>
                </div>
            )
        }
    }

    render() {
        const { request } = this.state
        return (
            <div className="SingleRequest">{request ? this.renderRequest(request) : this.renderLoadingOrError()}</div>
        )
    }
}

function mapStateToProps({ authReducer }: AppState) {
    return {
        profile: authReducer.profile
    }
}

export default withRouter(connect(mapStateToProps)(SingleRequest));