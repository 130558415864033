import React, { Dispatch } from 'react'
import { Divider, Grid, Header } from 'semantic-ui-react'
import GoalsInputBoxes from '../ResidentDietery/GoalsInputBoxes'
import RestrictionsCheckBoxes from '../ResidentDietery/RestrictionsCheckBoxes'
import { FetchDataAction, HandleChange, ResetData } from '../ResidentDietery/useDietary';
import { DietaryState } from '../../types/Dietary';
interface Props {
    dieteryState: DietaryState;
    dispatch: Dispatch<FetchDataAction | HandleChange | ResetData>;
    readonly: boolean; // pass only if the form needs to be read only
}

const DieteryForm: React.FC<Props> = ({ dieteryState, dispatch, readonly }) => {
    return (
        <Grid relaxed columns={3} style={{ marginTop: "10px" }}>
                <Grid.Column width={6}>
                    <Header as={"h3"}>Dietary Goals</Header>
                    <GoalsInputBoxes dietaryStateItems={dieteryState} disptach={dispatch} readonly={readonly} />
                </Grid.Column>
                <Grid.Column width={1}>
                    <Divider vertical/>
                </Grid.Column>
                <Grid.Column width={8}>
                    <Header as={"h3"}>Restrictions</Header>
                    <RestrictionsCheckBoxes dietaryStateItems={dieteryState} disptach={dispatch} readonly={readonly} />
                </Grid.Column>
        </Grid>
    );
}

export default DieteryForm