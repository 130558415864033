import React, { useEffect, useState } from 'react';
import {
    Message,
    Dropdown,
    Button,
    Popup,
    Grid,
    Modal,
    Dimmer,
    Loader,
    TextArea,
    MessageHeader,
    Form,
    FormField,
} from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { AuthState, Department, Registrant, User } from '../../types';
import { fetchAllActiveServicesTypes, getServiceCategories } from '../../services/service';
import { fetchDepartments } from '../../services/Departments';
import { mealTypes, sendToast } from '../../util';
import { withRouter, RouteComponentProps } from 'react-router';
import AssignButton from '../AssignButton';
import { convertStandaloneRequestToServiceRequest } from '../../services/RequestInstances';
import { RequestInstance } from '../../types/RequestInstance';
import { reopenStandaloneRequest } from '../../services/Requests';
import { fetchAllServiceInstance } from '../../services/ServiceInstances';
import moment from 'moment-timezone';
import { capitalizeFirstLetter } from '../../pages/AdminPanel/Menu/utils';
import { ServicesType } from '../../types/ServicesTypes';
import Ribbon from '../Ribbon';
import { handleRequestTest, renderRequestLabelColor, renderRequestStatus } from '../../util/requests';
import UserItem from '../UserItem';
import { fetchOneRegistrant } from '../../services/Registrants';
import NotificationsFormContainer from '../NotificationFormContainer';
import { fetchOneFeaturedFacility } from '../../services/Facilities';
import ConvertBtn from './DetailedViewButtons/ConvertBtn';
import ReopenBtn from './DetailedViewButtons/ReopenBtn';
import CancelFormBtn from './DetailedViewButtons/CancelFormBtn';
interface StandaloneDetailedFormProps extends RouteComponentProps {
    request: RequestInstance;
    users?: User[];
    userLoading?: boolean;
    onAssignSelection?: (selection: any) => void;
    isAssignBtnDisabled?: boolean;
    locationState: any;
    closeBtn: (loading: boolean) => React.ReactNode;
    renderFaces: (request: any) => React.ReactNode;
    unacceptBtn?: JSX.Element;
    acceptBtn?: JSX.Element;
    isStaffUser?: boolean;
    assignedStaffName?: string;
    parentLoading?: boolean;
}

const StandaloneDetailedForm: React.FC<StandaloneDetailedFormProps> = ({
    request,
    users,
    userLoading,
    onAssignSelection,
    isAssignBtnDisabled,
    locationState,
    closeBtn,
    renderFaces,
    history,
    isStaffUser,
    acceptBtn,
    unacceptBtn,
    assignedStaffName,
    parentLoading
}) => {
    const [departments, setDepartments] = useState<Department[]>([]);
    const [allServices, setAllServices] = useState<ServicesType[]>([]);
    const [filteredServices, setFilteredServices] = useState<ServicesType[]>([]);
    const [categories, setCategories] = useState<string[]>([]);
    const [selectedDepartment, setSelectedDepartment] = useState<string>('');
    const [selectedService, setSelectedService] = useState<string>('');
    const [selectedMealItems, setSelectedMealItems] = useState<string[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [filteredCategories, setFilteredCategories] = useState<string[]>([]);
    const [convertLoading, setConvertLoading] = useState<boolean>(false);
    const [reopenLoading, setReopenLoading] = useState<boolean>(false);
    const profile = useSelector(({ authReducer }: { authReducer: AuthState }) => authReducer.profile);
    const [selectedMealType, setSelectedMealType] = useState<string>('');
    const [isMenuItems, setIsMenuItems] = useState<boolean>(false);
    const [selectedResident, setSelectedResident] = useState<Registrant>();
    const [showAnnouncementModal, setShowAnnouncementModal] = useState<boolean>(false);
    const [announcementId, setAnnouncementId] = useState<string | undefined>(undefined);
    const [isFacilityWebRTCEnabled, setIsFacilityWebRTCEnabled] = useState<boolean>(false);

    const avatarUrl = request.Registrant_Image || `${process.env.PUBLIC_URL}/avatar_placeholder.svg`

    useEffect(() => {
        populateServiceDepartmentAndCategoryData();
    }, [profile]);

    useEffect(() => {
        const fetchFeaturedFacility = async () => {
            if (profile && profile.Facility) {
                try {
                    const { FacilityId = "" } = await fetchOneFeaturedFacility(profile.Facility);
                    if (FacilityId) {
                        setIsFacilityWebRTCEnabled(FacilityId);
                    }
                } catch (error) {
                    sendToast('error', error instanceof Error ? error.message : 'Error fetching facility details');
                }
            }
        };
        fetchFeaturedFacility();
    }, [])

    useEffect(() => {
        if (request && request.Registrant) {
            fetchRegistrantDetails(request.Registrant);
        }
    }, [request]);

    useEffect(() => {
        if (selectedCategory && selectedDepartment) {
            handleFetchServices();
        }
    }, [selectedCategory, selectedDepartment]);

    const fetchRegistrantDetails = async (registrantId: string) => {
        try {
            setLoading(true);
            const response = await fetchOneRegistrant(registrantId);
            setSelectedResident(response);
        } catch (error) {
            sendToast('error', error instanceof Error ? error.message : 'Error fetching resident details');
        } finally {
            setLoading(false);
        }
    };

    async function assignStaff(user) {
        try {
            onAssignSelection && onAssignSelection(user);
        } catch (error) {
            sendToast('error', error instanceof Error ? error.message : 'Something went wrong while assigning staff !. Please try again later.');
        }
    }
    async function handleFetchServices() {
        try {
            setLoading(true);
            const isCategoryMenu = selectedCategory === 'Menu';
            const department = departments.find((dept) => String(dept._id) === String(selectedDepartment));
            const isDepartmentDining = department && department.Name === 'Dining';
            if (isCategoryMenu && isDepartmentDining && profile) {
                //fetch service instance items for the day and time
                const menuFilter = {
                    Facility: profile.Facility,
                    source: 'Menu' as 'Menu',
                    startDate: moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss'), // Start of the day formatted
                    endDate: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
                };
                const menuItems = await fetchAllServiceInstance(menuFilter);
                setAllServices(menuItems);
                setFilteredServices(menuItems);
                setIsMenuItems(true);
            } else if (isMenuItems && profile) {
                // this is to avoid multiple calls to fetch services
                const serviceFilter = { Facility: profile.Facility, active: true };
                const services = await fetchAllActiveServicesTypes(serviceFilter);
                const filteredServices = services.filter(
                    (service) => service.Department === selectedDepartment || service.category === selectedCategory,
                );
                // filter categories based on the services of the request
                setFilteredServices(filteredServices);
                setIsMenuItems(false);
            }
        } catch (error) {
            console.error('Error fetching services', error);
            sendToast('error', 'Something went wrong while fetching services !. Please try again later.');
        } finally {
            setLoading(false);
        }
    }

    const populateServiceDepartmentAndCategoryData = async () => {
        try {
            if (profile && profile.Facility) {
                setLoading(true);
                const serviceFilter = { Facility: profile.Facility, active: true };
                const cateogryFilter = { includeAllCategories: true };
                const [services, allDepartments, categories] = await Promise.all([
                    fetchAllActiveServicesTypes(serviceFilter),
                    fetchDepartments(),
                    getServiceCategories({ Filter: cateogryFilter, sortAlphabetically: true }),
                ]);
                setAllServices(services);
                setDepartments(allDepartments);
                setCategories(categories);
                setSelectedDepartment(request.Department);
                //filter services based on the department of the request
                const filteredServices = services.filter((service) => service.Department === request.Department);
                // filter categories based on the services of the request
                setFilteredServices(filteredServices);
                // filter categories based on the services of the request
                const serviceCategories = filteredServices.map((service) => service.category);
                setFilteredCategories(categories.filter((category) => serviceCategories.includes(category)));
            }
        } catch (error) {
            console.error('Error fetching departments and services', error);
            sendToast('error', 'Something went wrong while fetching details !. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const handleDropDownChange = (type: string, value: any) => {
        try {
            if (type === 'Department') {
                setSelectedDepartment(value);
                setSelectedService('');
                setSelectedCategory('');
                //filter services based on the department
                const filteredServices = allServices.filter((service) => service.Department === value);
                setFilteredServices(filteredServices);
                // filter categories based on the services of the request
                const serviceCategories = filteredServices.map((service) => service.category);
                setFilteredCategories(categories.filter((category) => serviceCategories.includes(category)));
                setIsMenuItems(false);
            } else if (type === 'Service') {
                if (isMenuItems) {
                    setSelectedMealItems(value);
                } else {
                    setSelectedService(value);
                }
                // get the selected service and set the category
                const selectedService = allServices.find((service) => service._id === value);
                if (selectedService && selectedService.category) {
                    setSelectedCategory(selectedService.category);
                }
            } else if (type === 'Category') {
                // if category is selected then filter the services from the filtered service based on the category
                setSelectedCategory(value);
                if (value) {
                    const filteredCategoriesService = allServices.filter(
                        (service) => service.Department === selectedDepartment && service.category === value,
                    );
                    setFilteredServices(filteredCategoriesService);
                } else {
                    const filteredCategoriesService = allServices.filter(
                        (service) => service.Department === selectedDepartment,
                    );
                    setFilteredServices(filteredCategoriesService);
                    setIsMenuItems(false);
                }
            } else if (type === 'MealCategory') {
                setSelectedMealType(value);
                if (value) {
                    const filteredMealTypeService = allServices.filter((service) => service.menuCategory === value);
                    setFilteredServices(filteredMealTypeService);
                } else {
                    setFilteredServices(allServices);
                }
            }
        } catch (error) {
            console.error('Error handling dropdown change', error);
            sendToast(
                'error',
                'Something went wrong while handling dropdown change !. Please refresh the page and try again.',
            );
        }
    };

    const handleConvert = async () => {
        try {
            setConvertLoading(true);
            if (request && (selectedService || selectedMealItems.length > 0)) {
                const convertRequestData: {
                    standaloneRequestId: string;
                    serviceId?: string;
                    mealItems?: string[];
                } = {
                    standaloneRequestId: String(request._id),
                };

                if (selectedService) {
                    convertRequestData.serviceId = String(selectedService);
                }

                if (selectedMealItems.length > 0) {
                    convertRequestData.mealItems = selectedMealItems;
                }

                await convertStandaloneRequestToServiceRequest(convertRequestData);
                sendToast('success', 'Request converted successfully you will be redirected to requests page.');
                history.push('/admin/requests');
            } else {
                sendToast('warn', 'Please select a service to convert the request');
            }
        } catch (error) {
            console.error('Error converting request', error);
            sendToast('error', 'Something went wrong while converting request !. Please try again later.');
        } finally {
            setConvertLoading(false);
        }
    };

    const annoucementIconClickHandler = (id: string | undefined) => {
        setShowAnnouncementModal(true);
        setAnnouncementId(id);
    };

    const formatSvcRequestTime = (time: number | Date | undefined) => {
        // collects time in timestamp and returns a string in the format "at 12:00 PM on Mar 19, 2021"
        if (!time) return "";
        const facilityTimezone = profile && profile.FacilityTimeZone || "";
        if (!facilityTimezone) {
            return "";
        }
        const formatString = 'h:mm A on MMM D, YYYY';
        const formattedTime = 'at ' + moment.tz(time, facilityTimezone).format(formatString);
        return formattedTime;
    }

    const reopenRequest = async () => {
        try {
            setReopenLoading(true);
            setLoading(true);
            await reopenStandaloneRequest(request._id);
        } catch (error) {
            sendToast(
                'error',
                error instanceof Error
                    ? error.message
                    : 'Something went wrong while reopening the request !. Please try again later.',
            );
        } finally {
            setReopenLoading(false);
            setLoading(false);
            history.push('/admin/requests');
        }
    }

    const residentNotes = (selectedResident && selectedResident.Notes) || '';
    const toBeTruncated = residentNotes.length > 65;
    const truncatedResidentNotes = toBeTruncated ? residentNotes.slice(0, 65) + '...' : residentNotes;

    const cancelBtn = (
        <CancelFormBtn 
            onClickHandler={() => isStaffUser ? history.push('/') : history.push('/admin/requests')}
        />
    )

    // Case Staff user open request - we have 2 buttons
    // Case Staff user accepted request - we have 3 buttons
    // Case Admin user all cases - we have 4 buttons
    const buttonCount = isStaffUser ? request && request.Status === 'Open' ? 2 : 3 : 4;

    return (
        <>
            <Dimmer active={loading || userLoading || parentLoading} inverted>
                <Loader active={loading || userLoading || parentLoading} />
            </Dimmer>
            <Grid>
                <Grid.Row columns={2} style={{ padding: '0', margin: '2vh 0 0 0' }}>
                    <Grid.Column width={5} style={{ textAlign: 'left' }}>
                        <Ribbon
                            ribbonColor={renderRequestLabelColor(request)}
                            heading={renderRequestStatus(request)}
                            source="requestsDetailView"
                        />
                    </Grid.Column>
                    <Grid.Column width={11} style={{ textAlign: 'right', color: '#333', fontSize: '18px' }}>
                        {toBeTruncated ? (
                            <Popup content={residentNotes} trigger={<span>{truncatedResidentNotes}</span>} />
                        ) : (
                            residentNotes
                        )}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ padding: '0', margin: '2vh' }}>
                    <Grid.Column>
                        {selectedResident && (
                            <UserItem
                                id={(selectedResident && String(selectedResident._id)) || ''}
                                a4hRoomId={
                                    (selectedResident && selectedResident.Unit && selectedResident.Unit.a4hRoomId) || ''
                                }
                                name={
                                    (selectedResident &&
                                        `${selectedResident.FirstName} ${selectedResident.LastName}`) ||
                                    ''
                                }
                                details={
                                    (selectedResident &&
                                        (selectedResident.SpecialHandling || selectedResident.Notes)) ||
                                    ''
                                }
                                avatarUrl={
                                    (selectedResident &&
                                        (selectedResident.Image ||
                                            selectedResident.alisImage ||
                                            selectedResident.ExternalResidentImage)) ||
                                    avatarUrl ||
                                    ''
                                }
                                onClick={() => {}} // Inavtive click handler
                                source="requests"
                                roomName={
                                    (selectedResident && selectedResident.Unit && selectedResident.Unit.Name) || ''
                                }
                                residentPremise={selectedResident && selectedResident.onPremise}
                                residentSocialOptOut={(selectedResident && selectedResident.Private) || false}
                                isExternalResident={selectedResident.ExternalIntegrationSrc ? true : false}
                                isAlisResident={selectedResident.alisResidentId ? true : false}
                                externalIntegrationSource={selectedResident.ExternalIntegrationSrc || ''}
                                annoucementIconClickHandler={annoucementIconClickHandler}
                                isFacilityWebRTCEnabled={isFacilityWebRTCEnabled}
                                isStaffUser={isStaffUser || false}
                            />
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Form style={{ textAlign: 'left' }}>
                <FormField>
                    <label>Department</label>
                    <Dropdown
                        style={{ width: '100%' }}
                        options={departments.map((dept) => ({
                            key: dept._id,
                            text: dept.Name,
                            value: dept._id,
                        }))}
                        placeholder="Select Department"
                        selection
                        value={selectedDepartment}
                        search={true}
                        loading={loading}
                        onChange={(e, { value }) => handleDropDownChange('Department', value)}
                        disabled={isStaffUser}
                    />
                </FormField>
                {!isStaffUser && <FormField>
                    <label>Category</label>
                    <Dropdown
                        style={{ width: '100%' }}
                        options={
                            filteredCategories.length > 0
                                ? filteredCategories.map((category) => ({
                                      key: category,
                                      text: category,
                                      value: category,
                                  }))
                                : [
                                      {
                                          key: 'no-category',
                                          text: 'No Category Available',
                                          value: 'no-category',
                                          disabled: true,
                                      },
                                  ]
                        }
                        placeholder={filteredCategories.length > 0 ? 'Select Category' : 'No Category Available'}
                        selection
                        value={selectedCategory}
                        search={true}
                        clearable
                        loading={loading}
                        onChange={(e, { value }) => handleDropDownChange('Category', value)}
                        onBlur={(e) => {
                            // Prevent auto-select of the first item on blur
                            if (!filteredCategories.find((category) => category === selectedCategory)) {
                                handleDropDownChange('Category', null);
                            }
                        }}
                        disabled={filteredCategories.length === 0}
                    />
                </FormField>}
                {isMenuItems && !isStaffUser && (
                    <FormField>
                        <label>Meal Type</label>
                        <Dropdown
                            style={{ width: '100%' }}
                            options={mealTypes
                                .filter((mealType) => mealType !== 'Any')
                                .map((mealType, index) => ({
                                    key: index,
                                    text: capitalizeFirstLetter(mealType),
                                    value: mealType.toLocaleLowerCase(),
                                }))}
                            placeholder={'Select Meal Type'}
                            selection
                            clearable
                            value={selectedMealType}
                            search={true}
                            loading={loading}
                            onChange={(e, { value }) => handleDropDownChange('MealCategory', value)}
                        />
                    </FormField>
                )}
                {!isStaffUser && <FormField>
                    <label>{isMenuItems ? 'Menu Items' : 'Service'}</label>
                    <Dropdown
                        style={{ width: '100%' }}
                        options={
                            filteredServices.length > 0
                                ? filteredServices.map((service) => ({
                                      key: service._id,
                                      text: service.name,
                                      value: service._id,
                                  }))
                                : [
                                      {
                                          key: 'no-service',
                                          text: 'No Service Available',
                                          value: 'no-service',
                                          disabled: true,
                                      },
                                  ]
                        }
                        label={isMenuItems ? 'Menu Items' : 'Service'}
                        placeholder={filteredServices.length > 0 ? 'Select Service' : 'No Service Available'}
                        selection
                        value={isMenuItems ? selectedMealItems : selectedService}
                        search={true}
                        loading={loading}
                        multiple={isMenuItems}
                        onChange={(e, { value }) => handleDropDownChange('Service', value)}
                        disabled={filteredServices.length === 0}
                    />
                </FormField>}
                <FormField>
                    <label>Description</label>
                    <TextArea
                        style={{
                            width: '100%',
                            height: '100px',
                            border: '0.5px solid #B2B3B3',
                            borderRadius: '3px',
                            padding: '10px',
                            background: '#FFFFFF',
                        }}
                        placeholder={request ? "Description" : "Select Service"}
                        value={handleRequestTest(request, 'Details')}
                        disabled
                    />
                </FormField>
            </Form>
            <h3 style={{ margin: '2vh 0' }}>
                <strong>
                    {(request &&
                        request.DateAdded &&
                        profile &&
                        profile.FacilityTimeZone &&
                        moment.tz(request.DateAdded, profile.FacilityTimeZone).format('hh:mm A, MM/DD/YYYY')) ||
                        ''}
                </strong>
            </h3>
            <div style={{ display: 'flex', justifyContent: buttonCount === 4 ? 'space-between' : 'center', gap: buttonCount === 4 ? '0' : buttonCount === 3 ? '40px' : '80px' }}>
                {users && !isStaffUser && (
                    <div>
                        {request.Department !== selectedDepartment ? (
                            <Popup
                                content="You can assign after conversion."
                                trigger={
                                    <div>
                                        <AssignButton
                                            users={users}
                                            loading={userLoading}
                                            onSelectionChange={assignStaff}
                                            disabled={true}
                                            locationState={locationState}
                                            source="requestStandalone"
                                            customButtonText={assignedStaffName}
                                        />
                                    </div>
                                }
                            />
                        ) : (
                            <div>
                                <AssignButton
                                    users={users}
                                    loading={userLoading}
                                    onSelectionChange={assignStaff}
                                    disabled={isAssignBtnDisabled || convertLoading} // disable assign button if request is not open, accepted, or if the request is being converted
                                    locationState={locationState}
                                    source="requestStandalone"
                                    customButtonText={assignedStaffName}
                                />
                            </div>
                        )}
                    </div>
                )}
                {users && !isStaffUser && (
                    <ConvertBtn
                        disabled={
                            !(selectedService || (selectedMealItems && selectedMealItems)) || !selectedDepartment
                        }
                        onClickHandler={handleConvert}
                    />
                )}
                {users && !isStaffUser &&
                    (request.Status === 'Closed' ? (
                        <ReopenBtn disabled={loading || reopenLoading} onClickHandler={reopenRequest} />
                    ) : (
                        closeBtn(convertLoading)
                    ))}
                {isStaffUser && request && request.Status === 'Open' && acceptBtn}
                {isStaffUser && request && request.Status === 'Accepted' && (
                    <>
                        {unacceptBtn}
                        {closeBtn(convertLoading)}
                    </>
                )}
                {cancelBtn}
            </div>
            <Message
                size="small"
                style={{
                    textAlign: 'left',
                    lineHeight: 2.5,
                    background: '#E0E1E2',
                    outline: '1.5px solid #183466',
                    borderRadius: '10px',
                }}
            >
                <MessageHeader style={{ color: '#484848' }}>History</MessageHeader>
                <strong>Request Id: {request && request._id}</strong>
                <br />
                <strong>
                    Created {formatSvcRequestTime(request && request.DateAdded)} by{' '}
                    {request &&
                        ((request.Registrant_FirstName || '') + ' ' + (request.Registrant_LastName || '')).trim()}
                </strong>
                <br />
                {request && request.AcceptedTime && (
                    <strong>
                        Accepted by: {(request && request.AcceptedByName) || ''}{' '}
                        {formatSvcRequestTime(request && request.AcceptedTime)}
                        <br />
                    </strong>
                )}
                {request && request.ClosedTime && (
                    <strong>
                        Closed by: {(request && request.ClosedByName) || ''}{' '}
                        {formatSvcRequestTime(request && request.ClosedTime)}
                        <br />
                    </strong>
                )}
            </Message>
            {renderFaces(request)}
            <Modal
                open={showAnnouncementModal}
                closeIcon
                closeOnEscape
                closeOnDimmerClick
                onClose={() => setShowAnnouncementModal(false)}
                size="small"
            >
                <NotificationsFormContainer
                    selectedA4hRoomId={announcementId}
                    closeModal={() => {
                        setShowAnnouncementModal(false);
                        setAnnouncementId(undefined);
                    }}
                />
            </Modal>
        </>
    );
};

export default withRouter(StandaloneDetailedForm);
