import React from 'react';

type Props = {
    onClickHandler: () => void;
};

const CancelFormBtn = ({ onClickHandler }: Props) => {
    return (
        <div>
            <button
                type="button"
                className="edit-request-btn"
                onClick={(e) => {
                    e.preventDefault();
                    onClickHandler();
                }}
            >
                <div>
                    <i className="fa-solid fa-ban updateIcon" style={{ color: '#54C7D7' }}></i>
                </div>
                <div className='btnReqTitle'>Cancel</div>
            </button>
        </div>
    );
};

export default CancelFormBtn;
