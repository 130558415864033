import React, { useState, useEffect } from 'react'
import { useAdminPanelRequests as useAssignableRequests } from '../../hooks/adminPanel'
import { Button, Dropdown, Modal } from 'semantic-ui-react';
import { OrderIdColorDict, ReportSummaryFilters, Request, User } from '../../types'
import { BasicReportSummaryFilters } from '../../types'
import './style.less'
import { withRouter } from 'react-router'
import ReportSummaryAll from './ReportSummaryAll'
import { getOrderIdColor, requestListView } from '../../util/requests';
import RequestsKanban from '../RequestsKanban';
import { assignRequest, closeAllRequests, closeRequest } from '../../services/Requests';
import { fetchActiveUsers } from '../../services/Users';
import { fetchOrdersFromOrderId, reopenServiceRequest, unacceptServiceRequest } from '../../services/RequestInstances';
import { sendToast } from '../../util';
import { FetchOrderFromOrderIdParams, RequestInstance } from '../../types/RequestInstance';
import CloseAssociatedOrdersModal from '../Requests/CloseAssociatedOrdersModal';
import HeaderIcons from '../Requests/HeaderIcons';

const AllRequests = (props) => {
    const [openRequestModal, setOpenRequestModal] = useState<boolean>(false);
    const [showAcceptedReqModal, setShowAcceptedReqModal] = useState<boolean>(false);
    const [showClosedReqModal, setShowClosedReqModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [movedCardDepartmentId, setMovedCardDepartmentId] = useState<string>('');
    const [assignableRequestFilters, setAssignableRequestFilters] = useState<ReportSummaryFilters>({
        requestType: 'active',
        fromDate: (() => {
            const fromDate = new Date();
            fromDate.setDate(fromDate.getDate() - 7); // Subtract 7 days from the current date
            return fromDate;
        })(),
        toDate: (() => {
            const toDate = new Date();
            toDate.setDate(toDate.getDate() + 7); // Add 7 days to the current date
            return toDate;
        })(),
        disable: false,
        staff: 'all',
        resident: 'all',
        department: 'all',
        group: "all",
        reload: 0,
        FetchCancelRequests: false
    })

    const [basicRequestFilters, setBasicRequestFilters] = useState<BasicReportSummaryFilters>({
        fromDate: new Date(),
        toDate: new Date(),
        resident: 'all',
        food: 'all',
        type: 'all',
        disable: false,
        reload: 0
    })
    // const [upcomingRequestsDuePageNo, setUpcomingRequestsDuePageNo] = useState<number>(1);
    const [openPageNo, setOpenPageNo] = useState<number>(1);
    const [acceptedPageNo, setAcceptedPageNo] = useState<number>(1);
    const [closedPageNo, setClosedPageNo] = useState<number>(1);
    const [selectedOpenSortingValue, setSelectedOpenSortingValue] = useState<string>('asc');
    const [selectedAcceptedSortingValue, setSelectedAcceptedSortingValue] = useState<string>('asc');
    const [selectedClosedSortingValue, setSelectedClosedSortingValue] = useState<string>('asc');  
    const [orderIdColorDict, setOrderIdColorDict] = useState<OrderIdColorDict>({});
    const [alreadyGeneratedColors, setAlreadyGeneratedColors] = useState<string[]>([]);
    const [showAcceptedStaffModal, setShowAcceptedStaffModal] = useState<boolean>(false);
    const [assignStaffMembers, setAssignStaffMembers] = useState<User[]>([]);
    const [associatedOrders, setAssociatedOrders] = useState<RequestInstance[]>([]);
    const [currentMoveDetails, setCurrentMoveDetails] = useState({
        card: {
            metadata: {
                _id: ""
            }
        }, 
        source: {
            fromColumnId: ""
        }
    });
    const [staffUsers, setStaffUsers] = useState<User[]>([]);
    const [selectedStaffId, setSelectedStaffId] = useState<string>('');
    const [openCloseAssociatedOrdersModal, setOpenCloseAssociatedOrdersModal] = useState<boolean>(false);
    const [showClosedToOpenModal, setShowClosedToOpenModal] = useState<boolean>(false);
    const [expandView, setExpandView] = useState<boolean>(false);
    const [expandsingleRequestId, setExpandSingleRequestId] = useState<string | null>(null);
    const [currentView, setCurrentView] = useState<string>(requestListView);

    const {
        requests: openRequests,
        isFetching: openIsFetching,
        isPaginating: openIsPaginating,
        hasMorePages: openHasMorePages,
        totalDataLength: openTotalDataLength,
    } = useAssignableRequests(assignableRequestFilters, selectedOpenSortingValue === "asc" ? [{ RequestedTime: "asc" }] : [{ RequestedTime: "desc" }], true, false, openPageNo, 20, props.history, false, "openRequests")

    const {
        requests: acceptedRequests,
        isFetching: acceptedIsFetching,
        isPaginating: acceptedIsPaginating,
        hasMorePages: acceptedHasMorePages,
        totalDataLength: acceptedTotalDataLength,
    } = useAssignableRequests(assignableRequestFilters, selectedAcceptedSortingValue === "asc" ? [{ RequestedTime: "asc" }] : [{ RequestedTime: "desc" }], true, false, acceptedPageNo, 20, props.history, false, "open");

    const {
        requests: closedRequests,
        isFetching: closedIsFetching,
        isPaginating: closedIsPaginating,
        hasMorePages: closedHasMorePages,
        totalDataLength: closedTotalDataLength,
    } = useAssignableRequests(assignableRequestFilters, selectedClosedSortingValue === "asc" ? [{ RequestedTime: "asc" }] : [{ RequestedTime: "desc" }], true, false, closedPageNo, 20, props.history, false, "closed");

    useEffect(() => {
        const getQueryParams = () => {
            const queryParams = new URLSearchParams(props.location.search);
            const expandOpen = queryParams.get('expandOpen');
            const expandAccepted = queryParams.get('expandAccepted');
            const expandClosed = queryParams.get('expandClosed');

            setOpenRequestModal(expandOpen === 'true');
            setShowAcceptedReqModal(expandAccepted === 'true');
            setShowClosedReqModal(expandClosed === 'true');
        };

        getQueryParams();
    }, [props.location]);

    useEffect(() => {
        (async () => {
            const staffUsers = await fetchActiveUsers();
            setStaffUsers(staffUsers);
        })();
    }, []);

    useEffect(() => {
        filterStaffMemberstoDepartment();
    }, [movedCardDepartmentId])

    const filterStaffMemberstoDepartment = () => {
        const filteredStaff = staffUsers.filter(user => {
            const departmentIds = user.Departments && user.Departments.map(e => e._id)
            return departmentIds.includes(movedCardDepartmentId)
        })
        setAssignStaffMembers(filteredStaff)
    }

    // const upcomingRequestsDueList: Request[] = [...upcomingRequestsDue];
    const openRequestsList: Request[] = [...openRequests]; //, ...basicRequests] // this is commented in case we want to revert back to the old way of showing requests
    const acceptedRequestsList: Request[] = [...acceptedRequests]; // Any changes made here on in this component should also be checked in staff view 
    const closedRequestsList: Request[] = [...closedRequests];
    const allRequests: Request[] = [...openRequestsList, ...acceptedRequestsList, ...closedRequestsList];
    allRequests.forEach((request) => {
        getOrderIdColor(orderIdColorDict, setAlreadyGeneratedColors, setOrderIdColorDict, alreadyGeneratedColors, request.OrderId);
    }
    );

    const handleCardMoveToAccepted = async (card, source) => { // Any change made here should also be checked in staff view
        try {
            if (source.fromColumnId === "accepted") {
                return;
            }
            setIsLoading(true);
            setMovedCardDepartmentId(card.metadata.DepartmentId || card.metadata.Department);       
            setCurrentMoveDetails({
                card: card,
                source: source
            });
            if (source.fromColumnId === "open") {
                sendToast('message', 'Please assign a staff to move the request to accepted');
                setShowAcceptedStaffModal(true);
            } else if (source.fromColumnId === "closed") {
                if (card.metadata.AcceptedByName) {
                    await reopenServiceRequest(card && card.metadata && card.metadata._id && String(card.metadata._id)); // this reopens both standalone and service requests.
                    setAssignableRequestFilters(prevFilters => {
                        return {
                            ...prevFilters,
                            reload: prevFilters.reload + 1
                        }
                    });
                } else {
                    sendToast('message', 'Please assign a staff to move the request to accepted');
                    setShowAcceptedStaffModal(true);
                }
            }
        } catch (error) {
            sendToast("error", error instanceof Error ? error.message : "Error assigning staff");
        } finally {
            setIsLoading(false);
        }
    }

    const fetchAllAssociatedOrders = async (request) => {
        try {
            const orderFilter: FetchOrderFromOrderIdParams = {
                statusArr: ['Open', 'Accepted'],
                residentId: String(request && request.ResidentId),
                OrderId: (request && request.OrderId) || '',
            };
            const response = await fetchOrdersFromOrderId(orderFilter);
            if (!response) {
                throw new Error('Failed to fetch associated orders');
            }
            setAssociatedOrders(response);
            return response;
        } catch (error) {
            sendToast('error', error instanceof Error ? error.message : 'Failed to fetch associated orders');
        }
    };

    const handleCardMoveToClosed = async (card, source) => {
        try {
            if (source.fromColumnId === "closed") {
                return;
            }
            setIsLoading(true);
            if (card.metadata.OrderId) {
                const allAssociatedOrders = await fetchAllAssociatedOrders(card.metadata);
                if (allAssociatedOrders && allAssociatedOrders.length > 1) {
                    setOpenCloseAssociatedOrdersModal(true);
                    setCurrentMoveDetails({
                        card: card,
                        source: source
                    });
                    return;
                }
            }
            const requestId = String(card.metadata._id);
            await closeRequest(requestId)
            setAssignableRequestFilters(prevFilters => {
                return {
                    ...prevFilters,
                    reload: prevFilters.reload + 1
                }
            })
        } catch (error) {
            sendToast("error", error instanceof Error ? error.message : "Error assigning staff");
        } finally {
            setIsLoading(false);
        }
    };

    const handleCardMoveToOpen = async (card, source) => { // Any change made here should also be checked in staff view
        try {
            if(source.fromColumnId === "open") {
                return;
            }
            setIsLoading(true);
            setCurrentMoveDetails({
                card: card,
                source: source
            });
            if(!card.metadata._id) {
                throw new Error("Request id not found");
            }
            const requestId = String(card.metadata._id);
            // if the card is moved from accepted to open then we have to unaccept the request
            if (source.fromColumnId === "accepted") {
                await unacceptServiceRequest(requestId);
            } else {
                if(card.metadata.AcceptedByName) {
                    setShowClosedToOpenModal(true);
                    return;
                } else {
                    await reopenServiceRequest(requestId); // this reopens both standalone and service requests.
                    // if the request is moved from closed to accepted then we have to return from here and not reload the requests, then assign the staff and then reload the requests
                }
            }
            setAssignableRequestFilters(prevFilters => {
                return {
                    ...prevFilters,
                    reload: prevFilters.reload + 1
                }
            });
        } catch (error) {
            sendToast("error", error instanceof Error ? error.message : "Error assigning staff");
        } finally {
            setIsLoading(false);
        }
    };

    const handleStaffAssign = async () => {
        try {
            const requestId = currentMoveDetails.card && currentMoveDetails.card.metadata && currentMoveDetails.card.metadata._id && String(currentMoveDetails.card.metadata._id);
            if (!selectedStaffId || !requestId) {
                throw new Error("Staff or request id not found");
            }
            setIsLoading(true);
            setShowAcceptedStaffModal(false);
            // if the card is moved from closed to accepted, then it should be opened first and then assigned to staff
            if (currentMoveDetails && currentMoveDetails.source && currentMoveDetails.source.fromColumnId === "closed") {
                await reopenServiceRequest(requestId); // this reopens both standalone and service requests.
            }
            await assignRequest(requestId, selectedStaffId);
        } catch (error) {
            sendToast("error", error instanceof Error ? error.message : "Error assigning staff");
        } finally {
            setIsLoading(false);
            setAssignableRequestFilters(prevFilters => {
                return {
                    ...prevFilters,
                    reload: prevFilters.reload + 1
                }
            });
            setSelectedStaffId('');
        }
    };

    const cancelAllOpenAndAcceptedOrders = async () => {
            if (associatedOrders && associatedOrders.length > 0) {
                try {
                    setIsLoading(true);
                    const mealDeliveryOrderIds: string[] = [];
                    const nonDeliveryOrderIds: string[] = [];
                    associatedOrders.forEach(order => {
                        if (order.RequestType === 'Meal Delivery') {
                            mealDeliveryOrderIds.push(order._id);
                        } else if (order.RequestType !== 'Meal Delivery') {
                            nonDeliveryOrderIds.push(order._id);
                        }
                    });
                    await closeAllRequests(nonDeliveryOrderIds);
                    await closeAllRequests(mealDeliveryOrderIds);
                    sendToast('success', 'Orders cancelled successfully');
                    setOpenCloseAssociatedOrdersModal(false);
                    setAssignableRequestFilters(prevFilters => {
                        return {
                            ...prevFilters,
                            reload: prevFilters.reload + 1
                        }
                    });
                } catch (error) {
                    sendToast('error', error instanceof Error ? error.message : 'Failed to cancel orders');
                } finally {
                    setIsLoading(false);
                }
            } else {
                sendToast('warn', 'No request to cancel');
            }
    }
        
    const cancelRequest = async (requestId: string) => {
        try {
            setIsLoading(true);
            await closeRequest(requestId);
            sendToast('success', 'Request cancelled successfully');
            setOpenCloseAssociatedOrdersModal(false);
            setAssignableRequestFilters(prevFilters => {
                return {
                    ...prevFilters,
                    reload: prevFilters.reload + 1
                }
            });
        } catch (error) {
            sendToast('error', error instanceof Error ? error.message : 'Failed to cancel request');
        } finally {
            setIsLoading(false);
        }
    }

    const handleShowClosedToOpenAction = async (unaccept?: boolean) => {
        try {
            setIsLoading(true);
            setShowClosedToOpenModal(false);
            if (!currentMoveDetails.card || !currentMoveDetails.card.metadata || !currentMoveDetails.card.metadata._id) {
                sendToast('error', 'Request id not found');
                return;
            }
            const requestId = currentMoveDetails.card && currentMoveDetails.card.metadata && currentMoveDetails.card.metadata._id && String(currentMoveDetails.card.metadata._id);
            await reopenServiceRequest(requestId); // this reopens both standalone and service requests.
            if (unaccept) {
                await unacceptServiceRequest(requestId);
            }
            setAssignableRequestFilters(prevFilters => {
                return {
                    ...prevFilters,
                    reload: prevFilters.reload + 1
                };
            });
        } catch (error) {
            sendToast("error", error instanceof Error ? error.message : "Error assigning staff");
        } finally {
            setIsLoading(false);
        }
    }

    const loadMoreProps = {
        "open": {
            isLoading: openIsFetching || openIsPaginating,
            hasMore: openHasMorePages,
            setPageNo: setOpenPageNo
        },
        "accepted": {
            isLoading: acceptedIsFetching || acceptedIsPaginating,
            hasMore: acceptedHasMorePages,
            setPageNo: setAcceptedPageNo
        },
        "closed": {
            isLoading: closedIsFetching || closedIsPaginating,
            hasMore: closedHasMorePages,
            setPageNo: setClosedPageNo
        }
    }

    const expandIconClickHandler = () => {
        setExpandView((prev) => !prev);
        expandsingleRequestId && setExpandSingleRequestId(null);
    };

    return (
        <div>
            <HeaderIcons
                expandView={expandView}
                expandIconClickHandler={expandIconClickHandler}
                currentView={currentView}
                setCurrentView={setCurrentView}
            />
            <div className="requests">
                <ReportSummaryAll
                    filters={[assignableRequestFilters, basicRequestFilters]}
                    handleFilterChangeAssignable={(updatedFilters) => {
                        setAssignableRequestFilters(updatedFilters);
                        setOpenPageNo(1);
                        // setUpcomingRequestsDuePageNo(1);
                        setAcceptedPageNo(1);
                        setClosedPageNo(1);
                    }}
                    handleFilterChangeBasic={(updatedFilters) => {
                        setBasicRequestFilters(updatedFilters);
                        setOpenPageNo(1);
                        // setUpcomingRequestsDuePageNo(1);
                        setAcceptedPageNo(1);
                        setClosedPageNo(1);
                    }}
                    shouldCreateRequestBeShown={true}
                    history={props.history}
                    profile={props.profile}
                    isFetching={openIsFetching || acceptedIsFetching || closedIsFetching}
                    importedBy='Requests'
                    requests={[...acceptedRequestsList, ...closedRequestsList, ...openRequestsList]}
                    blockCloseInView={false}
                />

                {openRequestModal && (
                    <Modal open={openRequestModal} onClose={() => {
                        props.history.push("/admin/requests");
                    }} size='fullscreen' dimmer="blurring">
                        <Modal.Content>
                            <RequestsKanban
                                openRequests={openRequestsList}
                                acceptedRequests={acceptedRequestsList}
                                closedRequests={closedRequestsList}
                                openRequestsLength={openTotalDataLength}
                                acceptedRequestsLength={acceptedTotalDataLength}
                                closedRequestsLength={closedTotalDataLength}
                                handleCardMoveToAccepted={handleCardMoveToAccepted}
                                handleCardMoveToClose={handleCardMoveToClosed}
                                handleCardMoveToOpen={handleCardMoveToOpen}
                                loading={isLoading}
                                orderIdColorDict={orderIdColorDict}
                                powerAndFacilityUsers={true}
                                assignableRequestFilters={assignableRequestFilters}
                                loadMoreProps={loadMoreProps}
                                selectedOpenSortingValue={selectedOpenSortingValue}
                                setSelectedOpenSortingValue={setSelectedOpenSortingValue}
                                selectedAcceptedSortingValue={selectedAcceptedSortingValue}
                                setSelectedAcceptedSortingValue={setSelectedAcceptedSortingValue}
                                selectedClosedSortingValue={selectedClosedSortingValue}
                                setSelectedClosedSortingValue={setSelectedClosedSortingValue}
                                refresh={
                                    () => setAssignableRequestFilters({
                                        ...assignableRequestFilters,
                                        reload: assignableRequestFilters.reload + 1
                                    })
                                }
                                setOpenPageNo={setOpenPageNo}
                                setAcceptedPageNo={setAcceptedPageNo}
                                setClosedPageNo={setClosedPageNo}
                                isFullScreenModalView={true}
                                fullScreenStatus={'open'} // Don't change this as this key is used in multiple other components
                                setAssignableRequestFilters={setAssignableRequestFilters}
                                expandView={expandView}
                                expandsingleRequestId={expandsingleRequestId}
                                setExpandSingleRequestId={setExpandSingleRequestId}
                                currentView={currentView}
                            />
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => {
                                props.history.push("/admin/requests");
                            }}>Close</Button>
                        </Modal.Actions>
                    </Modal>
                )}

                {showAcceptedReqModal && (
                    <Modal open={showAcceptedReqModal} onClose={() => {
                        props.history.push("/admin/requests");
                    }} size='fullscreen' dimmer="blurring">
                        <Modal.Content>
                            <RequestsKanban
                                openRequests={openRequestsList}
                                acceptedRequests={acceptedRequestsList}
                                closedRequests={closedRequestsList}
                                openRequestsLength={openTotalDataLength}
                                acceptedRequestsLength={acceptedTotalDataLength}
                                closedRequestsLength={closedTotalDataLength}
                                handleCardMoveToAccepted={handleCardMoveToAccepted}
                                handleCardMoveToClose={handleCardMoveToClosed}
                                handleCardMoveToOpen={handleCardMoveToOpen}
                                loading={isLoading}
                                orderIdColorDict={orderIdColorDict}
                                powerAndFacilityUsers={true}
                                assignableRequestFilters={assignableRequestFilters}
                                loadMoreProps={loadMoreProps}
                                selectedOpenSortingValue={selectedOpenSortingValue}
                                setSelectedOpenSortingValue={setSelectedOpenSortingValue}
                                selectedAcceptedSortingValue={selectedAcceptedSortingValue}
                                setSelectedAcceptedSortingValue={setSelectedAcceptedSortingValue}
                                selectedClosedSortingValue={selectedClosedSortingValue}
                                setSelectedClosedSortingValue={setSelectedClosedSortingValue}
                                refresh={
                                    () => setAssignableRequestFilters({
                                        ...assignableRequestFilters,
                                        reload: assignableRequestFilters.reload + 1
                                    })
                                }
                                setOpenPageNo={setOpenPageNo}
                                setAcceptedPageNo={setAcceptedPageNo}
                                setClosedPageNo={setClosedPageNo}
                                isFullScreenModalView={true}
                                fullScreenStatus={'accepted'} // Don't change this as this key is used in multiple other components
                                setAssignableRequestFilters={setAssignableRequestFilters}
                                expandView={expandView}
                                expandsingleRequestId={expandsingleRequestId}
                                setExpandSingleRequestId={setExpandSingleRequestId}
                                currentView={currentView}
                            />
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => {
                                props.history.push("/admin/requests");
                            }}>Close</Button>
                        </Modal.Actions>
                    </Modal>
                )}

                {showClosedReqModal && (
                    <Modal open={showClosedReqModal} onClose={() => {
                        props.history.push("/admin/requests");
                    }
                    } size='fullscreen' dimmer="blurring">
                        <Modal.Content>
                        <RequestsKanban
                                openRequests={openRequestsList}
                                acceptedRequests={acceptedRequestsList}
                                closedRequests={closedRequestsList}
                                openRequestsLength={openTotalDataLength}
                                acceptedRequestsLength={acceptedTotalDataLength}
                                closedRequestsLength={closedTotalDataLength}
                                handleCardMoveToAccepted={handleCardMoveToAccepted}
                                handleCardMoveToClose={handleCardMoveToClosed}
                                handleCardMoveToOpen={handleCardMoveToOpen}
                                loading={isLoading}
                                orderIdColorDict={orderIdColorDict}
                                powerAndFacilityUsers={true}
                                assignableRequestFilters={assignableRequestFilters}
                                loadMoreProps={loadMoreProps}
                                selectedOpenSortingValue={selectedOpenSortingValue}
                                setSelectedOpenSortingValue={setSelectedOpenSortingValue}
                                selectedAcceptedSortingValue={selectedAcceptedSortingValue}
                                setSelectedAcceptedSortingValue={setSelectedAcceptedSortingValue}
                                selectedClosedSortingValue={selectedClosedSortingValue}
                                setSelectedClosedSortingValue={setSelectedClosedSortingValue}
                                refresh={
                                    () => setAssignableRequestFilters({
                                        ...assignableRequestFilters,
                                        reload: assignableRequestFilters.reload + 1
                                    })
                                }
                                setOpenPageNo={setOpenPageNo}
                                setAcceptedPageNo={setAcceptedPageNo}
                                setClosedPageNo={setClosedPageNo}
                                isFullScreenModalView={true}
                                fullScreenStatus={'closed'} // Don't change this as this key is used in multiple other components
                                setAssignableRequestFilters={setAssignableRequestFilters}
                                expandView={expandView}
                                expandsingleRequestId={expandsingleRequestId}
                                setExpandSingleRequestId={setExpandSingleRequestId}
                                currentView={currentView}
                            />
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => {
                                props.history.push("/admin/requests");
                            }}>Close</Button>
                        </Modal.Actions>
                    </Modal>
                )}
                <RequestsKanban
                    openRequests={openRequestsList}
                    acceptedRequests={acceptedRequestsList}
                    closedRequests={closedRequestsList}
                    openRequestsLength={openTotalDataLength}
                    acceptedRequestsLength={acceptedTotalDataLength}
                    closedRequestsLength={closedTotalDataLength}
                    handleCardMoveToAccepted={handleCardMoveToAccepted}
                    handleCardMoveToClose={handleCardMoveToClosed}
                    handleCardMoveToOpen={handleCardMoveToOpen}
                    loading={isLoading}
                    orderIdColorDict={orderIdColorDict}
                    powerAndFacilityUsers={true}
                    assignableRequestFilters={assignableRequestFilters}
                    loadMoreProps={loadMoreProps}
                    selectedOpenSortingValue={selectedOpenSortingValue}
                    setSelectedOpenSortingValue={setSelectedOpenSortingValue}
                    selectedAcceptedSortingValue={selectedAcceptedSortingValue}
                    setSelectedAcceptedSortingValue={setSelectedAcceptedSortingValue}
                    selectedClosedSortingValue={selectedClosedSortingValue}
                    setSelectedClosedSortingValue={setSelectedClosedSortingValue}
                    refresh={
                        () => setAssignableRequestFilters({
                            ...assignableRequestFilters,
                            reload: assignableRequestFilters.reload + 1
                        })
                    }
                    setOpenPageNo={setOpenPageNo}
                    setAcceptedPageNo={setAcceptedPageNo}
                    setClosedPageNo={setClosedPageNo}
                    setAssignableRequestFilters={setAssignableRequestFilters}
                    expandView={expandView}
                    expandsingleRequestId={expandsingleRequestId}
                    setExpandSingleRequestId={setExpandSingleRequestId}
                    currentView={currentView}
                />
                <CloseAssociatedOrdersModal
                    open={openCloseAssociatedOrdersModal}
                    onClose={() => setOpenCloseAssociatedOrdersModal(false)}
                    cancelRequest={cancelRequest}
                    cancelAllOpenAndAcceptedOrders={cancelAllOpenAndAcceptedOrders}
                    associatedOrders={associatedOrders}
                    facilityTimezone={props.profile && props.profile.FacilityTimeZone || ""}
                    loading={isLoading}
                    requestId={currentMoveDetails.card && currentMoveDetails.card.metadata && currentMoveDetails.card.metadata._id || ""}
                />
                <Modal closeOnEscape={true} closeIcon={true} closeOnDimmerClick={true} open={showAcceptedStaffModal} onClose={() => {
                    setShowAcceptedStaffModal(false);
                    setSelectedStaffId('');
                }}
                    size='mini'
                >
                    <Modal.Header>Assign staff member</Modal.Header>
                    <Modal.Content style={{ textAlign: 'center' }}>
                        <Dropdown
                            value={(selectedStaffId && String(selectedStaffId)) || undefined}
                            placeholder='Select staff'
                            scrolling
                            selection
                            search
                            options={assignStaffMembers.map(user => {
                                return {
                                    key: String(user._id),
                                    value: String(user._id),
                                    text: user.FirstName + " " + user.LastName
                                };
                            })} 
                            onChange={(e, { value }) => {
                                if (value)
                                    setSelectedStaffId(value as string);
                            }} />
                    </Modal.Content>
                    <Modal.Actions>
                        <Button disabled={!selectedStaffId} onClick={async () => {
                            await handleStaffAssign();
                        }}>Assign</Button>
                        <Button onClick={() => {
                            setShowAcceptedStaffModal(false);
                            setSelectedStaffId('');
                        }}>Cancel</Button>
                    </Modal.Actions>
                </Modal>
                <Modal closeOnEscape={true} closeIcon={true} closeOnDimmerClick={true} open={showClosedToOpenModal} onClose={() => {
                    setShowClosedToOpenModal(false);
                }}
                size='tiny'
                >
                    <Modal.Header>Confirmation</Modal.Header>
                    <Modal.Content>
                        This request was previously associated with a staff member. Would you like to remove the staff assignment and move it to 'Open' column, or keep the staff member assigned and move it to 'Accepted' column?
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={async () => {
                            await handleShowClosedToOpenAction(true);
                        }}>Move to open</Button>
                        <Button onClick={async () => {
                            await handleShowClosedToOpenAction();
                        }}>Move to accepted</Button>
                        <Button onClick={() => {
                            setShowClosedToOpenModal(false);
                        }}>Cancel</Button>
                    </Modal.Actions>
                </Modal>
            </div>
        </div>
    )
}

export default withRouter(AllRequests)
