import React, { useState } from "react";
import { Button } from "semantic-ui-react";

const AlphabetFilter = ({ onSelectLetter }: { onSelectLetter: (letter: string | null) => void; }) => {
    const alphabets = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
    const [selectedLetter, setSelectedLetter] = useState<string | null>(null);

    return (
        <Button.Group>
            <Button
                active={selectedLetter === null}
                onClick={() => {
                    setSelectedLetter(null);
                    onSelectLetter(null);
                }}
            >
                All
            </Button>
            {alphabets.map((letter) => (
                <Button
                    key={letter}
                    active={selectedLetter === letter}
                    onClick={() => {
                        setSelectedLetter(letter);
                        onSelectLetter(letter);
                    }}
                >
                    {letter}
                </Button>
            ))}
        </Button.Group>
    );
};

export default AlphabetFilter;  