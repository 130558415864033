const requestsKanbanInitData = {
    columns: [
        {
            key: 0,
            id: 'open', //  Do not change as used in multiple places
            title: 'Open',
            name_string: 'open_requests',
            cards: [],
            hasMore: false,
            isLoading: false,
        },
        {
            key: 1,
            id: 'accepted', //  Do not change as used in multiple places
            title: 'Accepted',
            name_string: 'accepted_requests',
            cards: [],
            hasMore: false,
            isLoading: false,
        },
        {
            key: 2,
            id: 'closed', //  Do not change as used in multiple places
            title: 'Closed',
            name_string: 'close_requests',
            cards: [],
            hasMore: false,
            isLoading: false,
        }
    ]
}

export default requestsKanbanInitData
