import React, { useEffect, useState } from 'react';
import { Dimmer, Dropdown, Icon, Input, Loader, Pagination, Popup, Table } from 'semantic-ui-react';
import { Restaurant } from '../../../types/Dining';
import { UserProfile } from '../../../types';
import { RouteComponentProps } from 'react-router-dom';
import { ServicesType } from '../../../types/ServicesTypes';
import { listAllRestaurant } from '../../../services/Assets';
import { fetchPaginatedCookBook, removeServiceType } from '../../../services/service';
import DiningForm from '../../../components/Dining/DiningForm';
import './style.less';
import ConfirmationModal from '../../../components/DeleteConfirmationModal';
import { sendToast } from '../../../util';
import AlphabetFilter from '../../../components/AlphabetFilter';

interface Props extends RouteComponentProps {
    profile: UserProfile | null;
}

const Cookbook = (props: Props) => {
    const [restaurants, setRestaurants] = useState<Restaurant[]>([]);
    const [restaurantsDropdown, setRestaurantDropdown] = useState<
        {
            key: string;
            text: string;
            value: string;
        }[]
    >([]);
    const [filterRestaurant, setFilterRestaurant] = useState<string[]>([]);
    const [menuServices, setMenuServices] = useState<ServicesType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [dropDownLoader, setDropDownLoader] = useState<boolean>(false);
    const [page_no, setPage_no] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [refreshRestaurants, setRefreshRestaurants] = useState<boolean>(false);
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [deleteMenuId, setDeleteMenuId] = useState<string>('');
    const [editId, setEditId] = useState<string>('');
    const [openDiningForm, setOpenDiningForm] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const [clearButton, setClearButton] = useState<boolean>(false);
    const [alphabetFilter, setAlphabetFilter] = useState<string | null>(null);
    const page_size = 10;
    const { history } = props;

    useEffect(() => {
        fetchRestaurants();
    }, [refreshRestaurants]);

    useEffect(() => {
        fetchMenuServices();
    }, [page_no, filterRestaurant, clearButton, alphabetFilter]);

    const fetchRestaurants = async () => {
        try {
            setDropDownLoader(true);
            const filters = {
                Facility: props.profile && props.profile.Facility || "",
            };
            const response: Restaurant[] = await listAllRestaurant(filters);
            setRestaurants(response);
            const assetOptions = response
                .map((restaurant) => ({
                    key: restaurant._id,
                    text: restaurant.AssetName,
                    value: restaurant._id,
                }))
                .sort((a, b) => a.text.localeCompare(b.text));
            setRestaurantDropdown(assetOptions);
        } catch (error) {
            sendToast('error', `${error instanceof Error ? error.message : 'Failed to fetch restaurants'}`);
        } finally {
            setDropDownLoader(false);
        }
    };

    const handleSelectedRestaurant = (id: string) => {
        setEditId(id);
        setOpenDiningForm(true);
    };

    const fetchMenuServices = async () => {
        try {
            setLoading(true);
            const pageSort = {
                page_no,
                page_size,
            };
            const filters = {
                Facility: props.profile && props.profile.Facility,
            };
            if (filterRestaurant && filterRestaurant.length > 0 && !filterRestaurant.includes('all')) {
                filters['Asset'] = { $in: filterRestaurant };
            }
            if (search && search.length > 0 && clearButton) {
                filters['$or'] = [
                    { name: { $regex: search, $options: 'i' } },
                    { shortDescription: { $regex: search, $options: 'i' } },
                ];
            }

            if (alphabetFilter) {
                filters['name'] = { $regex: `^${alphabetFilter}`, $options: 'i' };
            }
            const { Result: response, TotRecords } = await fetchPaginatedCookBook({ filters, pageSort });
            setMenuServices(response);
            setTotalPages(Math.ceil(Number(TotRecords) / page_size));
        } catch (error) {
            sendToast('error', `${error instanceof Error ? error.message : 'Failed to fetch cookbook'}`);
        } finally {
            setLoading(false);
        }
    };

    const handleRestaurantFilter = (value: string[]) => {
        setPage_no(1);
        if (value.includes('all')) value = ['all'];
        setFilterRestaurant(value);
    };

    const handleSearch = (search: string) => {
        setPage_no(1);
        setClearButton(true);
    };

    const handleDeleteMenuItem = (id: string) => {
        setDeleteMenuId(id);
        setDeleteModal(true);
    };

    const cancelDelete = () => {
        setDeleteMenuId('');
        setDeleteModal(false);
    };

    const confirmDelete = async () => {
        try {
            setLoading(true);
            setDeleteModal(false);
            const response = await removeServiceType(deleteMenuId);
            if (!response) throw new Error('Failed to delete menu item');
            sendToast('success', 'Menu item deleted successfully');
            await fetchMenuServices();
        } catch (error) {
            sendToast('error', `${error instanceof Error ? error.message : 'Failed to delete menu item'}`);
        } finally {
            setDeleteMenuId('');
            setLoading(false);
        }
    };

    return (
        <div className="cookbook-container">
            <div>
                <Dimmer active={loading} inverted>
                    <Loader size="small">Loading</Loader>
                </Dimmer>
                <div className="cookbook-header">
                    <h3>Cookbook</h3>
                    <div className="dropdown">
                        <Input
                            icon="search"
                            iconPosition="left"
                            placeholder="Search cookbook..."
                            style={{ width: '250px' }}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    handleSearch(search); // Trigger search only on Enter
                                }
                            }}
                            action={
                                clearButton ? {
                                    icon: "times circle",
                                    onClick: () => {
                                        setSearch(''); // Clear search when clicked
                                        setClearButton(false);
                                    }, // Clear input when clicked
                                    disabled: !clearButton, // Disable button when input is empty
                                } : undefined}
                        />
                        <Dropdown
                            style={{ width: '250px' }}
                            placeholder="Select Restaurant"
                            fluid
                            search
                            selection
                            clearable
                            multiple
                            scrolling
                            value={filterRestaurant}
                            loading={dropDownLoader}
                            options={restaurantsDropdown}
                            onChange={(e, { value }) => handleRestaurantFilter(value as string[])}
                        />
                        <Popup
                            trigger={
                                <Icon
                                    name="plus"
                                    size="large"
                                    onClick={() => {
                                        history.push('/admin/cookbook/create');
                                    }}
                                    style={{ cursor: 'pointer' }}
                                />
                            }
                            content="Add Menu Item"
                            position='top right'
                        />
                    </div>
                </div>
                <Table celled striped>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Menu Item</Table.HeaderCell>
                            <Table.HeaderCell>Description</Table.HeaderCell>
                            <Table.HeaderCell>Meal Type</Table.HeaderCell>
                            <Table.HeaderCell>Default Restaurant</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {menuServices && menuServices.length ? (
                            menuServices.map((menu, index) => (
                                <Table.Row className="table-row" key={index}>
                                    <Table.Cell>
                                        <p
                                            className="clickable"
                                            onClick={() => {
                                                history.push(`/admin/cookbook/${menu._id}`);
                                            }}
                                        >
                                            {menu.name}
                                        </p>
                                    </Table.Cell>
                                    <Table.Cell>{menu.shortDescription}</Table.Cell>
                                    <Table.Cell>
                                        {menu.mealTypes && Array.isArray(menu.mealTypes) && menu.mealTypes.length > 0 ? menu.mealTypes.join(', ') : '-'}
                                    </Table.Cell>
                                    <Table.Cell className="table-cell">
                                        {menu.Asset ? (
                                            (() => {
                                                const restaurant = restaurants.find(
                                                    (restaurant) => String(restaurant._id) === String(menu.Asset),
                                                );
                                                return restaurant ? (
                                                    <p
                                                        className="clickable"
                                                        onClick={() => {
                                                            handleSelectedRestaurant(menu.Asset);
                                                        }}
                                                    >
                                                        {restaurant.AssetName}
                                                    </p>
                                                ) : (
                                                    <p style={{ color: 'red' }}>
                                                        No restaurant selected. Please edit the menu item to choose a
                                                        restaurant.
                                                    </p>
                                                );
                                            })()
                                        ) : (
                                            <p>
                                                No restaurant selected. Please edit the menu item to choose a
                                                restaurant.
                                            </p>
                                        )}
                                        <Popup
                                            trigger={
                                                <Icon
                                                    className="trash-icon"
                                                    name="trash"
                                                    size="large"
                                                    onClick={() => {
                                                        handleDeleteMenuItem(menu._id);
                                                    }}
                                                />
                                            }
                                            content="Delete Menu Item"
                                            position="top right"
                                        />
                                    </Table.Cell>
                                </Table.Row>
                            ))
                        ) : (
                            <Table.Row>
                                <Table.Cell colSpan="4" style={{ textAlign: 'center' }}>
                                    <p>No cookbook found</p>
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan="4">
                                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', gap: '15px' }}>
                                    {totalPages > 1 && (
                                        <Pagination
                                            boundaryRange={0}
                                            defaultActivePage={1}
                                            activePage={page_no}
                                            onPageChange={(e, { activePage }) => {
                                                setPage_no(Number(activePage));
                                            }}
                                            ellipsisItem={null}
                                            firstItem={null}
                                            lastItem={null}
                                            siblingRange={1}
                                            totalPages={totalPages}
                                        />
                                    )}
                                    <AlphabetFilter onSelectLetter={(letter: string | null) => {
                                        setPage_no(1);
                                        setAlphabetFilter(letter);
                                    }} />
                                </div>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
            </div>
            <DiningForm
                openDiningForm={openDiningForm}
                closeDiningForm={() => {
                    setOpenDiningForm(false);
                }}
                editId={editId}
                facilityId={props.profile ? props.profile.Facility : ''}
                setRefreshPage={setRefreshRestaurants}
            />
            <ConfirmationModal
                open={deleteModal}
                onClose={cancelDelete}
                onConfirm={confirmDelete}
                messageToDisplay={'Are you sure you want to delete this Menu Item ?'}
            />
        </div>
    );
};

export default Cookbook;
