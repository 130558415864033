import React, { useEffect, useState } from "react";
import { Form, Input, Checkbox, Message, Loader, Dimmer, Button, Image } from "semantic-ui-react";
import { residentCommunityConfig } from "../../types/ResidentConfig";
import { fetchResidentialCommunity, uploadCommunityResidentLogo, upsertResidentialCommunity } from "../../services/Facilities";
import { sendToast } from "../../util";
import { useSelector } from "react-redux";
import { AuthState } from "../../types";
import { removeUnsafeElementsFromFileName } from "../../util/image";

const ResidentCommunitySetupForm = () => {
	const [selectedFile, setSelectedFile] = useState<File | null>(null);
	const [previewUrl, setPreviewUrl] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [btnLoading, setBtnLoading] = useState<boolean>(false);
	const [communityConfig, setCommunityConfig] = useState<Partial<residentCommunityConfig>>({
		Facility: "",
		CustomDynamicRoute: "",
		Config: {
			Calendar: false,
			Dining: false,
			CommunityMessages: false,
			Services: false,
		},
		CommunityName: "",
		Logo: "",
		ContactName: "",
		ContactPhone: "",
		Email: "",
		Address: "",
		ZIP: "",
		State: "",
		City: "",
		Notes: ""
	});
	const [refreshFlag, setRefreshFlag] = useState<boolean>(false);
	const [errors, setErrors] = useState({
		ContactName: false,
		Email: false,
		ContactPhone: false,
		Address: false,
		CommunityName: false,
		City: false,
		State: false,
		ZIP: false
	});
	const profile = useSelector(({ authReducer }: { authReducer: AuthState; }) => {
		return authReducer.profile;
	});
	useEffect(() => {
		fetchResidentialConfig();
	}, [refreshFlag]);

	const validateForm = () => {
		const newErrors = {
			ContactName: !(communityConfig.ContactName && String(communityConfig.ContactName).trim()),
			Email: !(communityConfig.Email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(communityConfig.Email)),
			ContactPhone: !(communityConfig.ContactPhone && /^\d{10}$/.test(communityConfig.ContactPhone)),
			Address: !(communityConfig.Address && String(communityConfig.Address).trim()),
			CommunityName: !(communityConfig.CommunityName && String(communityConfig.CommunityName).trim()),
			City: !(communityConfig.City && String(communityConfig.City).trim()),
			State: !(communityConfig.State && String(communityConfig.State).trim()),
			ZIP: !(communityConfig.ZIP && /^\d{5,6}$/.test(communityConfig.ZIP))
		};

		setErrors(newErrors);
		return !Object.values(newErrors).some(Boolean);
	};

	const fetchResidentialConfig = async () => {
		try {
			setIsLoading(true);
			const response = await fetchResidentialCommunity();
			if (response) {
				setCommunityConfig(response);
				response.SignedUrl && setPreviewUrl(response.SignedUrl);
			}
		} catch (e) {
			sendToast("error", e instanceof Error ? e.message : "Error fetching residential community config");
		} finally {
			setIsLoading(false);
		}
	};

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		setCommunityConfig({ ...communityConfig, [name]: value });
		setErrors({ ...errors, [name]: false });
	};

	const handleLogoChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const { type, files } = event.target;
		if (type === "file" && files && files.length > 0) {
			const file = files[0];
			const safeFileName = removeUnsafeElementsFromFileName(file.name);
			// Create a new file object with the safe file name (optional)
			const renamedFile = new File([file], safeFileName, { type: file.type });
			setSelectedFile(renamedFile);
			setPreviewUrl(URL.createObjectURL(renamedFile)); // Show preview
			setCommunityConfig({ ...communityConfig, Logo: safeFileName });
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			setBtnLoading(true);
			if (validateForm()) {
				selectedFile && profile && await uploadCommunityResidentLogo(selectedFile, profile.Facility);
				await upsertResidentialCommunity(communityConfig);
				setRefreshFlag(!refreshFlag);
				sendToast("success", "Residential community config submitted successfully");
			}
		} catch (error) {
			sendToast("error", error instanceof Error ? error.message : "Error submitting residential community config");
		} finally {
			setBtnLoading(false);
		}
	};
	return (
		<div style={{ width: "100%", border: "1px solid #A3CDDC", borderRadius: "5px", padding: "15px" }}>
			<Dimmer active={isLoading} inverted>
				<Loader active={isLoading} />
			</Dimmer>
			<Form >
				<Form.Field>
					<div className={"center-style"} >
						<h4>Define your URL. https://www.speak2tv.com/</h4>
						<Input placeholder="Facility Route" value={communityConfig.CustomDynamicRoute} onChange={(data, { value }) => { setCommunityConfig({ ...communityConfig, CustomDynamicRoute: value.toLowerCase() as string }); }} />

					</div>
					<p>This cannot be changed, please determine the exact name and spelling.</p>
				</Form.Field>
				<Form.Field>
					<Checkbox
						toggle
						label="Calendar"
						checked={communityConfig.Config && communityConfig.Config.Calendar}
						onChange={(event, { checked }) => {
							setCommunityConfig({
								...communityConfig, Config: {
									...communityConfig.Config,
									Calendar: checked as boolean,
									Dining: (communityConfig.Config && communityConfig.Config.Dining) || false,
									CommunityMessages: (communityConfig.Config && communityConfig.Config.CommunityMessages) || false,
									Services: (communityConfig.Config && communityConfig.Config.Services) || false
								}
							});
						}}
					/>
				</Form.Field>
				<Form.Field>
					<Checkbox toggle label="Dining" disabled checked={communityConfig.Config && communityConfig.Config.Dining} />
				</Form.Field>
				<Form.Field>
					<Checkbox toggle label="Community Messages" disabled checked={communityConfig.Config && communityConfig.Config.CommunityMessages} />
				</Form.Field>
				<Form.Field>
					<Checkbox toggle label="Services" disabled checked={communityConfig.Config && communityConfig.Config.Services} />
				</Form.Field>
				<Form.Field>
					{previewUrl && (
						<div className={"center-style"}>
							<Image src={previewUrl} size="small" style={{ marginTop: "10px", borderRadius: "5px" }} />
						</div>
					)}
					<Form.Input label="Logo" placeholder="Upload Logo" type="file" onChange={handleLogoChange}
						accept="image/*" />
				</Form.Field>
				<Form.Input
					label="Community Name"
					placeholder="Enter Community Name"
					name="CommunityName"
					type="text"
					onChange={handleInputChange}
					value={communityConfig.CommunityName}
					error={errors.CommunityName ? { content: "Community Name is required", pointing: "below" } : null}
				/>
				<Form.Input
					label="Contact Name"
					placeholder="Enter Contact Name"
					name="ContactName"
					type="text"
					onChange={handleInputChange}
					value={communityConfig.ContactName}
					error={errors.ContactName ? { content: "Contact Name is required", pointing: "below" } : null}
				/>
				<Form.Input
					label="Contact Email"
					placeholder="Enter Contact Email"
					name="Email"
					type="email"
					onChange={handleInputChange}
					value={communityConfig.Email}
					error={errors.Email ? { content: "Enter a valid email address", pointing: "below" } : null}
				/>
				<Form.Input
					label="Contact Phone"
					placeholder="Enter Contact Phone"
					name="ContactPhone"
					type="tel"
					onChange={handleInputChange}
					value={communityConfig.ContactPhone}
					error={errors.ContactPhone ? { content: "Enter a valid 10-digit phone number", pointing: "below" } : null}
				/>
				<Form.Input
					label="Address"
					placeholder="Enter Address"
					name="Address"
					type="text"
					onChange={handleInputChange}
					value={communityConfig.Address}
					error={errors.Address ? { content: "Address is required", pointing: "below" } : null}
				/>
				<Form.Input
					label="City"
					placeholder="Enter City"
					name="City"
					type="text"
					onChange={handleInputChange}
					value={communityConfig.City}
					error={errors.City ? { content: "City is required", pointing: "below" } : null}
				/>
				<Form.Group widths="equal"  >
					<Form.Input
						label="State"
						placeholder="Enter State"
						name="State"
						type="text"
						onChange={handleInputChange}
						value={communityConfig.State}
						error={errors.State ? { content: "State is required", pointing: "below" } : null}
					/>
					<Form.Input
						label="ZIP Code"
						placeholder="Enter ZIP Code"
						name="ZIP"
						type="text"
						onChange={handleInputChange}
						value={communityConfig.ZIP}
						error={errors.ZIP ? { content: "Enter a valid ZIP Code", pointing: "below" } : null}
					/>
				</Form.Group>
				<Form.Input
					label="Notes"
					placeholder="Notes"
					name="Notes"
					type="text"
					onChange={handleInputChange}
					value={communityConfig.Notes}
				/>
				<Message error header="Form Validation Error" content="Please fix the errors above." />
				<div className={"center-style"} >
					<Button loading={btnLoading || isLoading} className="ui primary button" onClick={handleSubmit}>Save Config</Button>
				</div>
			</Form>
		</div>
	);
};

export default ResidentCommunitySetupForm;
