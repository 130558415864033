import React, { useState } from 'react'
import './style.less'
import { Button, Dropdown, Grid, Header, Segment, Checkbox, Input} from 'semantic-ui-react'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

interface Props {
    invite: string
    error: string
    success: string
    isSubmitting: boolean
    manager: null | {
        firstName: string
        lastName: string
        contact: string
    }
    invitedManager?: any
    enableAnnouncementAlerts?: boolean
    email?: string
    onInputChange: (stateProp, value) => any
    submitInvite: (e) => any
    deleteInvite: (e) => any
    removeRegistrantManagerHandler?: () => Promise<any>
    onAnnouncementAlertChange: (value) => any
    onEmailChange: (value) => any
    residentRPCheckboxState: {
        rp: boolean
        resident: boolean
    }
    handleFamilyInviteCheckboxChange: (name: string) => void
}

const FamilyInvite: React.FC<Props> = (props) => {
    const [invitationMode, setInvitationMode] = useState<any>('sms')
    return (
        <div className="family-invite">
            <p>Speak2 Relationship Manager</p>
            {props.manager ? (
                <>
                    <Grid columns={2}>
                        <Grid.Row>
                            <Grid.Column width={4}>
                                <div className="relative-image-container">
                                    <div className="relative-avatar">
                                        {props.manager.firstName.charAt(0)} {props.manager.lastName.charAt(0)}
                                    </div>
                                </div>
                            </Grid.Column>
                            <Grid.Column width={12} style={{ textOverflow: "ellipsis" }}>
                                <Header as='h4' size='small'>{`${props.manager.firstName} ${props.manager.lastName}`}</Header>
                                <span style={{ whiteSpace: "break-spaces", textOverflow: "ellipsis" }}>{props.manager.contact}</span>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Column width={16} textAlign='right'>
                            <Button loading={props.isSubmitting} className="ui red basic button delete-button" size='tiny' onClick={async () => {
                                if (props.removeRegistrantManagerHandler) await props.removeRegistrantManagerHandler()
                            }}>Remove</Button>
                        </Grid.Column>
                    </Grid>
                </>
            ) : (
                props.invitedManager
                    ?
                    <>
                        <p>{`Sent invite to ${props.invitedManager.Contact}. Acceptance pending.`}</p>
                        <Grid stackable columns={2}>
                            <Grid.Column width={6}>
                                <Button primary loading={props.isSubmitting} onClick={props.submitInvite} className="invite-community-manager primary">
                                    Resend
                                </Button>
                            </Grid.Column>
                            <Grid.Column width={6}>
                                <Button loading={props.isSubmitting} onClick={props.deleteInvite} className="invite-community-manager negative">
                                    Delete
                                </Button>
                            </Grid.Column>
                        </Grid>
                    </>
                    :
                    <>
                        {/* { key: 'email', value: 'email', text: 'Email' }, */}

                        <div>Enter the cell number and email to invite a contact</div>
                            <div style={{ display: "flex", gap: "10px", marginTop:"12px" }}>
                                <Checkbox
                                    label="Family"
                                    checked={props.residentRPCheckboxState.rp}
                                    onChange={() => props.handleFamilyInviteCheckboxChange('rp')}
                                />
                                <Checkbox
                                    label="Resident"
                                    checked={props.residentRPCheckboxState.resident}
                                    onChange={() => props.handleFamilyInviteCheckboxChange('resident')}
                                />
                            </div>
                        <div className="invite-container">
                                {/* <Dropdown
                                style={{ width: '60px' }}
                                defaultValue={'sms'}
                                options={[{ key: 'sms', value: 'sms', text: 'SMS' }]}
                                onChange={(event, { value = 'sms' }) => {
                                    setInvitationMode(value)
                                    console.log(value)
                                }}
                            /> */}
                                <div className="flex-1 ml-8 w-100">
                                    <PhoneInput
                                        style={{padding:'5px'}}
                                        defaultCountry="US"
                                        value={props.invite}
                                        placeholder="Enter phone number" 
                                        onChange={(phone) => {
                                            console.log(phone);
                                            props.onInputChange('invite', phone);
                                        }}
                                    />
                                </div>
                                <div className='w-100'>
                                    <input
                                        className='w-100'
                                        type="email"
                                        placeholder="Email"
                                        value={props.email}
                                        onChange={(e) => {
                                            props.onEmailChange(e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                               
                       
                        <Checkbox
                            label="Enable announcement alerts" 
                            value={'announcement'}
                            checked={props.enableAnnouncementAlerts}
                            onClick={() => props.onAnnouncementAlertChange(!(props.enableAnnouncementAlerts))}
                        />
                        <p style={{ color :"black"}}></p>
                        <Button loading={props.isSubmitting} onClick={props.submitInvite} disabled={!(props.invite && props.email)}>
                            Invite
                        </Button>
                        {props.success ? <div className="invite-message">{props.success}</div> : null}
                        {props.error ? <div className="invite-warning">{props.error}</div> : null}
                    </>
            )}
        </div>
    )
}

export default FamilyInvite
