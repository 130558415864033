import FullCalendar from '@fullcalendar/react';
import React, { useState, useRef, useEffect } from 'react';
import { Dimmer, Grid, Loader, Modal, Segment } from 'semantic-ui-react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import Calendar from '@fullcalendar/react';
import "./style.less";
import moment from 'moment';
import { AuthState, ReportSummaryFilters } from '../../types';
import { useAdminPanelRequests as useAssignableRequests } from '../../hooks/adminPanel';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { formatRequestsForCalendarEvents } from '../../util/requests';
import { useSelector } from 'react-redux';
import { formatDateWithTZ } from '../../util/timezone';
import { RefObject } from '@fullcalendar/core/preact';
import RequestInfoModal from '../Requests/RequestInfoModal';

interface RequestCalendarViewProps extends RouteComponentProps {
    calendarView: boolean,
    assignableRequestFilters: ReportSummaryFilters;
    powerAndFacilityUsers?: boolean;
    setAssignableRequestFilters: (filters: ReportSummaryFilters) => void;
}
interface EventData {
    id: string;
    title: string;
    start: string;
    end: string;
}
const RequestCalendarView = ({ calendarView, assignableRequestFilters, history, powerAndFacilityUsers, setAssignableRequestFilters }: RequestCalendarViewProps) => {
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const [dailyRequestFilters, setDailyRequestFilters] = useState<any>({ ...assignableRequestFilters, fromDate: selectedDate, toDate: selectedDate });
    const [requestStatus, setRequestStatus] = useState<string>("openRequests");
    const [requestDetailedModal, setRequestDetailedModal] = useState<boolean>(false);
    const [request, setRequest] = useState<any>();
    const calendarEl: RefObject<Calendar> = useRef<Calendar | null>(null);
    const profile = useSelector(({ authReducer }: { authReducer: AuthState; }) => {
        return authReducer.profile;
    });

    const facilityTZ = profile && profile.FacilityTimeZone || "";

    useEffect(() => {
        setDailyRequestFilters({ ...assignableRequestFilters, fromDate: dailyRequestFilters.fromDate, toDate: dailyRequestFilters.toDate });
    }, [assignableRequestFilters]);

    const {
        isFetching,
        requestsCategoryDepCountBasedOnDateDict
    } = useAssignableRequests(assignableRequestFilters, [{ RequestedTime: "asc" }], true, false, 1, 20, history, true, requestStatus, false, powerAndFacilityUsers ? '' : (profile && profile._id) || '');

    const {
        requests: dailyRequests,
        isFetching: dailyIsFetching,
        isPaginating: dailyIsPaginating,
    } = useAssignableRequests(dailyRequestFilters, [{ RequestedTime: "asc" }], true, false, 1, 50, history, false, requestStatus, true, powerAndFacilityUsers ? '' : (profile && profile._id) || '');
    
    const formattedReqArr = formatRequestsForCalendarEvents(requestsCategoryDepCountBasedOnDateDict, facilityTZ);

    const formatDate = (date: Date | null): string => {
        if (!date) return "";
        return moment(date).format("dddd, MMMM D, YYYY"); // e.g., "Monday, January 15, 2025"
    };
    const getStartAndEndDateTime = (formattedRequest, requestedType) => {
        if (requestedType === "monthly") {
            return {
                startDate: formattedRequest.request ?
                    formattedRequest.request.ScheduledTime && formattedRequest.request.ScheduledTime.startDate :
                    formattedRequest.ScheduledTime && formattedRequest.ScheduledTime.startDate,
                endDate: formattedRequest.request ?
                    formattedRequest.request.ScheduledTime && formattedRequest.request.ScheduledTime.endDate :
                    formattedRequest.ScheduledTime && formattedRequest.ScheduledTime.endDate
            }
        } else {
            return {
                startDate: formattedRequest && formattedRequest.ScheduledTime && formattedRequest.ScheduledTime.startDate,
                endDate: formattedRequest && formattedRequest.ScheduledTime && formattedRequest.ScheduledTime.endDate
            }
        }
    }
    
    const eventsFormatter = (formattedRequests, requestedType?: string): EventData[] => {
        return formattedRequests.map((formattedRequest, i) => {
            const startDate = getStartAndEndDateTime(formattedRequest, requestedType).startDate;
            const endDate = getStartAndEndDateTime(formattedRequest, requestedType).endDate;
            const formattedData = {
                id: formattedRequest._id || "",
                title: formattedRequest.Name || "",
                start: startDate || formattedRequest.RequestedTime && formatDateWithTZ(formattedRequest.RequestedTime, 'YYYY-MM-DDTHH:mm:ss', facilityTZ) || "",
                end: endDate || formattedRequest.RequestedTime && formatDateWithTZ(formattedRequest.RequestedTime, 'YYYY-MM-DDTHH:mm:ss', facilityTZ) || "",
                metaData: formattedRequest,
            };
            return formattedData;
        });
    };

    const CalendarRequestEventContent = ({ info }: any) => {
        const title = info.event._def.title;
        return (
            <div>
                <div className="requestEventTitle" style={{ fontSize: title.length > 20 ? "11px" : "12px" }}>
                    {title}
                </div>
            </div>
        );
    };

    const refresh = () => {
        setAssignableRequestFilters({
            ...assignableRequestFilters,
            reload: assignableRequestFilters.reload + 1
        });
        setDailyRequestFilters({
            ...dailyRequestFilters,
            reload: dailyRequestFilters.reload + 1
        });
        setRequestDetailedModal(false);
    }

    return (
        <Grid>
            <Dimmer active={isFetching || dailyIsFetching || dailyIsPaginating} inverted>
                <Loader active={isFetching || dailyIsFetching || dailyIsPaginating} />
            </Dimmer>
            <Grid.Row>
                <Grid.Column width={calendarView ? 11 : 16}>
                    <div style={{ display: "flex", gap: "10px", fontSize: "20px", marginBottom: "20px" }}>
                        <div style={{ cursor: "pointer", fontWeight: requestStatus === "openRequests" ? "bolder" : "lighter", }} onClick={() => setRequestStatus("openRequests")}>Open</div>
                        <div>|</div>
                        <div style={{ cursor: "pointer", fontWeight: requestStatus === "open" ? "bolder" : "lighter", }} onClick={() => setRequestStatus("open")}>Accepted</div>
                    </div>
                    <div>
                        <Segment style={{ marginTop: "20px" }}>
                            <FullCalendar
                                plugins={[dayGridPlugin, interactionPlugin]}
                                initialView="dayGridMonth"
                                editable={false}
                                ref={calendarEl}
                                events={eventsFormatter(formattedReqArr, "monthly").sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime()) || []}
                                dateClick={(info) => {
                                    calendarEl.current && calendarEl.current.getApi().gotoDate(info.date); // This sets the date of the calendar to the date selected by the user.
                                    setSelectedDate(info.date);
                                    setDailyRequestFilters({ ...dailyRequestFilters, fromDate: info.date, toDate: info.date });
                                }}
                                eventClick={(info) => {
                                    if (info.event._def.extendedProps.metaData && info.event._def.extendedProps.metaData.redirectToDetailedView) {
                                        setRequest(info.event._def.extendedProps.metaData && info.event._def.extendedProps.metaData);
                                        setRequestDetailedModal(true);
                                    } else {
                                        const date = new Date(info.event._def.extendedProps.metaData.ScheduledTime.startDate);
                                        calendarEl.current && calendarEl.current.getApi().gotoDate(date); // This sets the date of the calendar to the date selected by the user.
                                        setSelectedDate(date);
                                        setDailyRequestFilters({ ...dailyRequestFilters, fromDate: date, toDate: date });
                                    }
                                }}
                                customButtons={{}}
                                headerToolbar={{
                                    right: 'prev,next',
                                }}
                                displayEventTime={false}
                                showNonCurrentDates={false}
                                eventContent={(info) => <CalendarRequestEventContent info={info} />}
                            />
                        </Segment>
                    </div>
                </Grid.Column>
                <Grid.Column width={5}>
                    {calendarView && <>
                        <div style={{ fontSize: "20px", fontWeight: "bold" }}>Daily Schedule</div>
                        {/* show the date and the day of the selectedDate*/}
                        <div style={{ fontSize: "14px", fontWeight: "bold", marginTop: "10px" }}>{formatDate(selectedDate)} </div>
                        <Calendar
                            events={eventsFormatter(dailyRequests) || []}
                            allDaySlot={false}
                            plugins={[interactionPlugin, timeGridPlugin]}
                            initialView="timeGridDay"
                            eventDurationEditable={false} // Disable resizing of event duration
                            droppable={true}
                            headerToolbar={{ //left and right are specified to align title to center
                                left: '',
                                center: '',
                                right: '',  //timeGridDay,timeGridWeek,dayGridMonth
                            }}
                            ref={calendarEl}
                            eventClick={(info) => {
                                setRequest(info.event._def.extendedProps.metaData && info.event._def.extendedProps.metaData);
                                setRequestDetailedModal(true);
                            }}
                            selectable={true}
                            scrollTime="08:00:00"
                        />
                    </>}
                </Grid.Column>
            </Grid.Row>
            {
                request ?
                    <RequestInfoModal
                        requestDetailedModal={requestDetailedModal}
                        setRequestDetailedModal={setRequestDetailedModal}
                        request={request}
                        refresh={refresh}
                        powerAndFacilityUsers={powerAndFacilityUsers}
                    /> : <></>
            }
        </Grid>
    );
};

export default withRouter(RequestCalendarView);