import React from 'react';
import '../style.less';
type Props = {
    disabled?: boolean;
};

const UpdateBtn = ({ disabled }: Props) => {
    return (
        <div>
            <button disabled={disabled} className="edit-request-btn" type="submit">
                <div>
                    <i className="fa-duotone fa-solid fa-marker updateIcon"></i>
                </div>
                <div className='btnReqTitle'>Update</div>
            </button>
        </div>
    );
};

export default UpdateBtn;
