import React from 'react';
import '../style.less';

type Props = {
    onClickHandler: (id?: string) => void;
    disabled?: boolean;
};

const AcceptBtn = ({ onClickHandler, disabled }: Props) => {
    return (
        <div>
            <button type="button" disabled={disabled || false} onClick={() => onClickHandler()} className="edit-request-btn">
                <div>
                    <i className="fa-solid fa-circle-check updateIcon" style={{ color: '#9FD754' }}></i>
                </div>
                <div className='btnReqTitle'>Accept</div>
            </button>
        </div>
    );
};

export default AcceptBtn;
