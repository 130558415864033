import { cookbookFilter } from '../types/Service';
import { fetchAllPaginatedData } from '../util';
import API from './API';

interface PageSort {
    page_size: number;
    page_no: number;
    sort?: { DateAdded: string; }[];
}

export const upsertService = async (data: object): Promise<any> => {
    try {
        const res = await API.lambdaPost('/services-types/upsert', data);
        return res;
    } catch (error) {
        console.error('upsertService error', error);
        throw new Error(error instanceof Error ? error.message : 'Failed to upsert service');
    }
};

export const uploadServiceImage = async (file: File, serviceId: string | undefined, Facility: string, universal = false): Promise<any> => {
    try {
        const preSignedUrl = await API.lambdaPost('/services-types/images/upload', { imageName: file.name, serviceId, Facility });
        await API.putFile(preSignedUrl, file);
        if (universal && serviceId) { // if the image is for a universal service type, upload the template as well 
            const serviceData = await fetchOneServiceType(serviceId, Facility);
            if (serviceData.Service.templateId) await uploadUniversalServiceImage(file, serviceData.Service.templateId as string);
        }
        const SignedUrl = getBaseUrlFromPresignedUrl(preSignedUrl);
        return SignedUrl;
    } catch (error) {
        console.error('uploadServiceImage error', error);
        throw new Error(error instanceof Error ? error.message : 'Failed to upload service image');
    }
};

export const fetchServiceTypesImage = async (serviceId: string, fileName: string, Facility: string): Promise<any> => {
    try {
        const res = await API.lambdaGet('/services-types/images/get', { serviceId, fileName, Facility });
        return res; 
    } catch (error) { 
        console.log('Failed to fetch image for service type', error);
        throw new Error(error instanceof Error ? error.message : 'Failed to fetch image');
    }
};

export const fetchAllServiceTypes = async (): Promise<any> => {
    try {
        const res = await API.lambdaGet('/services-types/list');
        return res;
    } catch (error) {
        console.error('listALL error', error);
        throw new Error(error instanceof Error ? error.message : 'Failed to fetchallservice');
    }
};

export const removeServiceType = async (serviceId: string): Promise<any> => {
    try {
        const res = await API.lambdaDeleteById('/services-types/delete', { _id: serviceId });
        return res;
    } catch (error) {
        console.log('error in deleting in serviceTypes', error);
        throw new Error(error instanceof Error ? error.message : 'Failed to delete Service');
    }
};

export const fetchOneServiceType = async (serviceId: string, Facility: string): Promise<any> => {
    try {
        const res = await API.lambdaGet('/services-types/get', { _id: serviceId, Facility });
        return res;
    } catch (error) {
        console.log('error in fetchOne  serviceTypes', error);
        throw new Error(error instanceof Error ? error.message : 'Failed to fetch one service');
    }
};

export async function fetchPaginatedServicesTypes(page_no = 1, page_size = 50, Facility: string, category?: string, active?: boolean): Promise<any> {
    try {
        let Filter: any = { Facility };
        if(category) Filter = { ...Filter, category };
        if (active) Filter = { ...Filter, active };
        const res = await API.lambdaPost('/services-types/listAll', {
            QueryOptions: {
                page_no,
                page_size,
                sort: [{ name: "desc" }]
            },
            Filter
        });
        return res;
    } catch (error) {
        console.log('Error in listAll Paginated ServiceTypes', error);
        throw new Error(error instanceof Error ? error.message : 'Failed to fetch All paginated ServiceTypes');
    }
}

export async function fetchAllServicesTypes(Facility: string, category?: string, active?: boolean): Promise<any> {
    try {
        let Filter: any = { Facility };
        if (category) Filter = { ...Filter, category };
        if (active) Filter = { ...Filter, active };
        const res = await API.lambdaPost('/services-types/listAll', {
            Filter
        });
        return res;
    } catch (error) {
        console.log('Error in listAll ServiceTypes', error);
        throw new Error(error instanceof Error ? error.message : 'Failed to fetch ServiceTypes');
    }
}

export async function queryServicesTypes(Facility: string, name?: string,  active?: boolean, category?: string,): Promise<any> {
    try {
        let Filter: any = { Facility };
        if (name) Filter = { ...Filter, name };
        if (active) Filter = { ...Filter, active };
        if (category) Filter = { ...Filter, category };
        const res = await API.lambdaPost('/services-types/listAll', {
            Filter
        });
        return res;
    } catch (error) {
        console.log('Error in query ServiceTypes', error);
        throw new Error(error instanceof Error ? error.message : 'Failed to query ServiceTypes');
    }
}

export async function fetchAllActiveServicesTypes(filters = {}): Promise<any> {
    try {
        const serviceTypeslist = async (filters: any) => { return await API.lambdaPost('/services-types/list', filters); };
        const response = await fetchAllPaginatedData(serviceTypeslist, filters);
        return response;
    } catch (error) {
        console.log('Error in listAll ServiceTypes', error);
        throw new Error('Failed to fetch ServiceTypes');
    }
}

export function getBaseUrlFromPresignedUrl(presignedUrl: string) {
    const parsedUrl = new URL(presignedUrl);
    const baseUrl = `${parsedUrl.protocol}//${parsedUrl.host}${parsedUrl.pathname}`;
    return baseUrl;
}

export async function getInitialServiceTypesState() {
    try {
        const res = await API.lambdaGet("/services-types/initialState/list");
        return res;
    } catch (error) {
        console.error("getInitialServiceTypesState error", error);
        throw new Error(error instanceof Error ? error.message : "Failed to get initial service library state");
    }
}

export async function getServiceCategories({ Filter={}, sortAlphabetically = false }: { Filter?: any, sortAlphabetically?: boolean; }) {
    try {
        const res = await API.lambdaPost("/services-types/getCategories",Filter);
        if (sortAlphabetically)
            res.sort();
        return res;
    } catch (error) {
        console.error("getServiceCategories error", error);
        throw new Error(error instanceof Error ? error.message : "Failed to get service categories");
    }
}

export async function fetchPaginatedCookBook({ filters = {} as cookbookFilter, pageSort = { page_size: 100, page_no: 1 } as PageSort }): Promise<any> {
    try {
        const Filter = {
            active: true,
            category: "Menu",
            ...filters
        };
        const pagingSorting = {
            page_no: pageSort.page_no,
            page_size: pageSort.page_size,
            sort: [{ name: "desc" }] //Sort by name in alphabetical descending order
        };
        const res = await API.lambdaPost('/services-types/list', {
            pagingSorting,    //QueryOptions for listAll
            Filter
        });
        return res;
    } catch (error) {
        console.log('Error in listAll Paginated cookbook', error);
        throw new Error(error instanceof Error ? error.message : 'Failed to fetch All paginated cookbook');
    }
}

export async function fetchAllCookBooks( filters = {} as cookbookFilter ): Promise<any> {
    try {
        const Filter = {
            active: true,
            category: "Menu",
            ...filters
        };
        const cookbookList = async (filters: any) => { return await API.lambdaPost('/services-types/list', filters); };
        const res = await fetchAllPaginatedData(cookbookList, Filter);
        return res;
    } catch (error) {
        console.log('Error in listAll cookbook', error);
        throw new Error(error instanceof Error ? error.message : 'Failed to fetch cookbook');
    }
}

export async function fetchServicesByFilter(Facility: string, Filter: any) {
    try {
        const res = await API.lambdaPost('/services-types/list', {
            Filter: { ...Filter, Facility }
        });
        return res;
    } catch (error) {
        console.log('Error in fetchServicesByFilter', error);
        throw new Error(error instanceof Error ? error.message : 'Failed to fetch ServiceTypes');
    }
}

//----------------------------------Universal Service Types----------------------------------------------

export const fetchUniversalServiceTypesImage = async (serviceId: string, fileName: string): Promise<any> => {
    try {
        const res = await API.lambdaGet('/services-types/images/get', { serviceId, fileName, universal: true });    
        return res;
    } catch (error) {
        console.log('Failed to fetch image for service type', error);
        throw new Error(error instanceof Error ? error.message : 'Failed to fetch image');
    }
}

export const uploadUniversalServiceImage = async (file: File, serviceId: string | undefined): Promise<any> => {
    try {
        const preSignedUrl = await API.lambdaPost('/services-types/images/upload', { imageName: file.name, serviceId, universal: true });
        await API.putFile(preSignedUrl, file);
        const SignedUrl = getBaseUrlFromPresignedUrl(preSignedUrl);
        return SignedUrl;
    } catch (error) {
        throw new Error(error instanceof Error ? error.message : 'Failed to upload service image');
    }
};

export async function fetchPaginatedUniversalTemplates(filter = {}, page_no = 1, page_size = 50): Promise<any> {
    try {
        let Filter: any = { ...filter };
        const res = await API.lambdaPost('/services-types/universal-template/list', {
            QueryOptions: {
                page_no,
                page_size,
                sort: [{ name: "desc" }]
            },
            Filter
        });
        return res;
    } catch (error) {
        throw new Error(error instanceof Error ? error.message : 'Failed to fetch universal service templates');
    }
}

export const fetchOneUniversalService = async (serviceId: string, ): Promise<any> => {
    try {
        const res = await API.lambdaGet('/services-types/universal-template/get', { _id: serviceId });
        return res;
    } catch (error) {
        throw new Error(error instanceof Error ? error.message : 'Failed to fetch one service');
    }
};

export const updateUniversalService = async (data: object): Promise<any> => {
    try {
        const res = await API.lambdaPost('/services-types/universal-template/update', data);
        return res;
    } catch (error) {
        throw new Error(error instanceof Error ? error.message : 'Failed to upsert service');
    }
};

export const deleteUniversalService = async (serviceId: string): Promise<any> => {
    try {
        const res = await API.lambdaDeleteById('/services-types/universal-template/delete', { _id: serviceId });
        return res;
    } catch (error) {
        throw new Error(error instanceof Error ? error.message : 'Failed to delete Service');
    }
};


export async function fetchPaginatedCorporateTemplates(filter = {}, page_no = 1, page_size = 50): Promise<any> {
    try {
        let Filter: any = { ...filter };
        const res = await API.lambdaPost('/services-types/corporate-template/list', {
            QueryOptions: {
                page_no,
                page_size, 
                sort: [{ name: "desc" }]
            },
            Filter
        });
        return res;
    } catch (error) {
        throw new Error(error instanceof Error ? error.message : 'Failed to fetch universal service templates');
    }
}


export const fetchOneCorporateService = async (serviceId: string, ): Promise<any> => {
    try {
        const res = await API.lambdaGet('/services-types/corporate-template/get', { _id: serviceId });
        return res;
    } catch (error) {
        throw new Error(error instanceof Error ? error.message : 'Failed to fetch corporate template');
    }
};

export const updateCorporateService = async (data: object): Promise<any> => {
    try {
        const res = await API.lambdaPost('/services-types/corporate-template/update', data);
        return res;
    } catch (error) {
        throw new Error(error instanceof Error ? error.message : 'Failed to update corporate template');
    }
};

export const deleteCorporateService = async (serviceId: string): Promise<any> => {
    try {
        const res = await API.lambdaDeleteById('/services-types/corporate-template/delete', { _id: serviceId });
        return res;
    } catch (error) {
        throw new Error(error instanceof Error ? error.message : 'Failed to delete corporate template');
    }
};


export const getServiceWellness = async (wellnessId: string): Promise<any> => {
    try {
        const res = await API.lambdaGet('/services-types/wellness/get', { _id: wellnessId });
        return res;
    } catch (error) {
        throw new Error(error instanceof Error ? error.message : 'Failed to fetch wellness');
    }
}