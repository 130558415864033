import React from 'react'
import { Form, TextArea, Button, Message, Dropdown, Checkbox, Dimmer, Select, Input } from 'semantic-ui-react'
import { DailyActivity } from '../../types'
import { withRouter, RouteComponentProps } from 'react-router'
import AudioRecorder from '../../components/AudioRecorder'
import './style.less'
import DatePicker from 'react-datepicker'
import SignageContentImgCaptureAndDisplay from '../SignageContentImgCaptureAndDisplay'
import { AppState } from '../../reducers';
import { connect } from 'react-redux';
import { UserProfile } from '../../types';
import { format } from 'date-fns';
import moment from "moment-timezone";
import { capitalizeFirstLetter } from '../MenuFormEditor/utils'
import { listRoomTypes } from '../../services/RoomTypes';
import { toast } from 'react-toastify';
import { sortByKey } from '../../util/sortData';
import ExcludeDatesField from '../ExcludeDatesField';
interface Props extends RouteComponentProps {
    attendanceToggle?: boolean
    isFetching: boolean
    isSaving: boolean
    editableActivity?: Partial<DailyActivity>
    handleSubmit: (activity) => any
    handleUpdate: (activity) => any
    cancelEditActivity: (id?) => any
    edit?: boolean
    _id?: string
    copyActivity?: DailyActivity | null
    passAudioBlob?
    audioError?: string | null
    dateTimeString?: string
    activityDetailView?: boolean
    selectedActivity?: DailyActivity
    copyClickHandler?: (selectedActivity, copyImageUrl: string | undefined) => void
    profile: UserProfile | null
    timezone?: string
    modifyGroup?: boolean
    disableRecurrence?: boolean
    selectedCalendarType?: string[]
    handleLocationUpdate?: (location: string[]) => void
    handleRedirect?: (id?: string) => void
    handleDelete?: (e) => void;
}

interface State {
    activity: Partial<DailyActivity>
    imageFile: File | null
    copyImageUrl: string | undefined
    signagePreviewImage: string | undefined
    repeatType: string | undefined
    locationOptions: {
        key: string;
        text: string;
        value: string;
    }[]
    selectedLocation: string[]
    isLoading: boolean
    interval: number
    excludeDates: string[]
    datePickerMinDate: Date | null
    datePickerMaxDate: Date | null
}

class ActivityForm extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            activity: {
                text: '',
                dateTimeString:
                    props.selectedActivity &&
                    props.selectedActivity.recurrence &&
                    props.dateTimeString &&
                    props.modifyGroup
                        ? moment(
                              props.selectedActivity.recurrence
                                  .split('\n')[0]
                                  .split('DTSTART=')[1]
                                  .replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3'),
                          )
                              .set({
                                  hour: moment(props.dateTimeString).format('HH'),
                                  minute: moment(props.dateTimeString).format('mm'),
                                  second: moment(props.dateTimeString).format('ss'),
                              })
                              .format('YYYY-MM-DDTHH:mm:ss')
                        : props.dateTimeString
                        ? props.dateTimeString
                        : undefined,
                recurrence: props.selectedActivity && props.selectedActivity.recurrence
                    ? props.selectedActivity.recurrence 
                    : undefined,
                endDate:
                    props.selectedActivity && props.selectedActivity.recurrence && props.modifyGroup
                        ? moment(
                                  props.selectedActivity.recurrence
                                      .split('UNTIL=')[1]
                                      .split('\n')[0]
                                      .replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3'),
                          )
                              .set({
                                  hour: moment(props.dateTimeString).format('HH'),
                                  minute: moment(props.dateTimeString).format('mm'),
                                  second: moment(props.dateTimeString).format('ss'),
                              })
                              .format('YYYY-MM-DDTHH:mm:ss')
                        : undefined,
            },
            imageFile: null,
            copyImageUrl: undefined,
            signagePreviewImage: undefined,
            repeatType:
                this.props.selectedActivity && this.props.modifyGroup
                    ? this.props.selectedActivity.recurrence
                        ? this.props.selectedActivity.recurrence.split('FREQ=')[1].split(';')[0]
                        : 'OFF'
                    : 'OFF',
            locationOptions: [],
            selectedLocation: (
                this.props.selectedActivity && this.props.selectedActivity.calendarType ?
                    this.props.selectedActivity.calendarType :
                    this.props.selectedCalendarType
            ) || [],
            isLoading: false,
            interval:
                (this.props.selectedActivity &&
                    this.props.modifyGroup &&
                    this.props.selectedActivity.recurrence &&
                    this.props.selectedActivity.recurrence.split('FREQ=')[1] &&
                    !isNaN(
                        Number(
                            this.props.selectedActivity.recurrence
                                .split('FREQ=')[1]
                                .split(';')[0]
                        )
                    ) &&
                    Number(
                        this.props.selectedActivity.recurrence
                            .split('FREQ=')[1]
                            .split(';')[0]
                    )) ||
                1,
            excludeDates: this.getExcludeDatesFromRecurrence(this.props),
            datePickerMinDate: this.getDatePickerMinDate(this.props),
            datePickerMaxDate: this.getDatePickerMaxDate(this.props),
        };
    }

    getExcludeDatesFromRecurrence(props) {
        if (props.selectedActivity && props.modifyGroup && props.selectedActivity.recurrence) {
            const recurrence = props.selectedActivity.recurrence;
            const exDatesStringFromRecurrence = recurrence.split('EXDATE:')[1];
            const exDatesArrayFromRecurrence = exDatesStringFromRecurrence ? exDatesStringFromRecurrence.split(',').filter(x => x) : [];
            const formattedDatesArray = exDatesArrayFromRecurrence.map((dateString) => {
                return `${dateString.slice(0, 4)}-${dateString.slice(4, 6)}-${dateString.slice(6, 8)}`;
            });
            return formattedDatesArray;
        }
        return [];
    }

    getDatePickerMinDate(props) {
        const dateTimeString = props.selectedActivity && props.selectedActivity.dateTimeString;
        const endDate = (props.selectedActivity && props.selectedActivity.recurrence && props.modifyGroup)
            ? moment(
                props.selectedActivity.recurrence
                    .split('UNTIL=')[1]
                    .split('\n')[0]
                    .replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3'),
            )
                .set({
                    hour: moment(props.dateTimeString).format('HH'),
                    minute: moment(props.dateTimeString).format('mm'),
                    second: moment(props.dateTimeString).format('ss'),
                })
                .format('YYYY-MM-DDTHH:mm:ss')
            : undefined;
        if (dateTimeString && endDate) {
            return moment(dateTimeString).add(1, 'day').toDate();
        }
        return null;
    }

    getDatePickerMaxDate(props) {
        const dateTimeString = props.selectedActivity && props.selectedActivity.dateTimeString;
        const endDate = (props.selectedActivity && props.selectedActivity.recurrence && props.modifyGroup)
            ? moment(
                props.selectedActivity.recurrence
                    .split('UNTIL=')[1]
                    .split('\n')[0]
                    .replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3'),
            )
                .set({
                    hour: moment(props.dateTimeString).format('HH'),
                    minute: moment(props.dateTimeString).format('mm'),
                    second: moment(props.dateTimeString).format('ss'),
                })
                .format('YYYY-MM-DDTHH:mm:ss')
            : undefined;
        if (dateTimeString && endDate) {
            return moment(endDate).subtract(1, 'day').toDate();
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.state.activity.dateTimeString !== prevState.activity.dateTimeString ||
            this.state.activity.endDate !== prevState.activity.endDate
        ) {
            this.updateDatePickerRange();
        }
    }

    updateDatePickerRange() {
        const { dateTimeString, endDate } = this.state.activity;

        if (dateTimeString && endDate) {
            const datePickerMinDate = moment(dateTimeString).add(1, 'day').toDate();
            const datePickerMaxDate = moment(endDate).subtract(1, 'day').toDate();

            this.setState({ datePickerMinDate, datePickerMaxDate }, () => {
                this.updateExcludeDates(datePickerMinDate, datePickerMaxDate);
            });
        }
    }

    updateExcludeDates(datePickerMinDate: Date, datePickerMaxDate: Date) {
        const updatedExDates = this.state.excludeDates.filter((date) => {
            return moment(date).isBetween(datePickerMinDate, datePickerMaxDate, 'day', '[]');
        });

        this.setState({ excludeDates: updatedExDates });
    }

    setActivityText(text: string) {
        this.setState({
            activity: {
                ...this.state.activity,
                text,
            },
        });
    }

    async setActivityTime(time) {
        if (this.state.activity.endDate) {
            if (moment(time).isAfter(this.state.activity.endDate)) {
                this.setState(
                    {
                        activity: {
                            ...this.state.activity,
                            recurrence: undefined,
                            endDate: undefined,
                        },
                    },
                    () => {
                        this.generateRecurrence();
                    },
                );
            }
        }

        this.setState(
            {
                activity: {
                    ...this.state.activity,
                    timestamp: moment(time).unix() * 1000, // left to handle existing activity to be updated
                    dateTimeString: format(time, 'YYYY-MM-DDTHH:mm:ss'),
                },
            },
            () => {
                this.generateRecurrence();
            },
        );
    }

    validForm() {
        if (this.state.repeatType && this.state.repeatType !== 'OFF') {
            return this.state.activity.text && this.state.activity.dateTimeString && this.state.activity.endDate;
        } else {
            return this.state.activity.text && this.state.activity.dateTimeString;
        }
    }

    async handleSubmit() {
        if (this.props.activityDetailView) {
            // const activity = this.state.activity
            // activity._id = this.props._id // bad practice to reassign a const
            this.props.handleUpdate({
                ...this.state.activity,
                _id: this.props._id,
                imageFile: this.state.imageFile,
                copyImageUrl: this.state.copyImageUrl,
                calendarType: this.state.selectedLocation
            });
        } else {
            if (this.props.edit) {
                // const activity = this.state.activity
                // activity._id = this.props._id
                this.props.handleUpdate({
                    ...this.state.activity,
                    _id: this.props._id,
                    imageFile: this.state.imageFile,
                    copyImageUrl: this.state.copyImageUrl,
                    calendarType: this.state.selectedLocation
                });
            } else {
                this.props.handleSubmit({
                    ...this.state.activity,
                    audioId: this.props.copyActivity ? this.props.copyActivity.audioId : undefined,
                    playableUrl: this.props.copyActivity ? this.props.copyActivity.playableUrl : undefined,
                    imageFile: this.state.imageFile,
                    copyImageUrl: this.state.copyImageUrl, 
                    calendarType: this.state.selectedLocation
                })
            }
            this.setState({
                activity: {
                    text: '',
                    timestamp: undefined,
                    dateTimeString: undefined,
                    category: '',
                    recurrence: undefined,
                    endDate: undefined,
                },
                repeatType: 'OFF',
            });
        }
    }

    generateRecurrence() {
        const formatDate = (dateString: string) => {
            const [year, month, day] = dateString.split('-');
            return `${year}${month}${day}`;
        };
        const dtstart =
            this.state.activity.dateTimeString && this.state.activity.dateTimeString.split('T')[0].split('-').join('');
        const until = this.state.activity.endDate && this.state.activity.endDate.split('T')[0].split('-').join('');
        const frequency = this.state.repeatType;
        const interval = this.state.interval;
        const excludeDatesString = this.state.excludeDates.length && this.state.excludeDates.map(formatDate).join(',');
        const recurrence =
            dtstart && until && frequency
                ? `DTSTART=${dtstart}\nRRULE:FREQ=${frequency};INTERVAL=${interval};UNTIL=${until}${excludeDatesString ? `\nEXDATE:${excludeDatesString}` : ''}`
                : undefined;
        if (this.props.editableActivity) {
            this.setState((prevState => ({
                activity: {
                    ...prevState.activity,
                    recurrence: recurrence,
                    dateTimeString: prevState.activity.dateTimeString,
                }}))
            , () => {
                this.setState(prevState => ({
                    activity: {
                        ...prevState.activity,
                        endDate:
                            prevState.activity && prevState.activity.recurrence
                                ? moment(
                                          prevState.activity.recurrence
                                              .split('UNTIL=')[1]
                                              .split('\n')[0]
                                              .replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3'),
                                  )
                                      .set({
                                          hour: moment(prevState.activity.dateTimeString).format('HH'),
                                          minute: moment(prevState.activity.dateTimeString).format('mm'),
                                          second: moment(prevState.activity.dateTimeString).format('ss'),
                                      })
                                      .format('YYYY-MM-DDTHH:mm:ss')
                                : undefined,
                    },
                }));
            });
        } else {
            this.setState({
                activity: {
                    ...this.state.activity,
                    recurrence: recurrence,
                },
            });
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (
            nextProps.copyActivity &&
            nextProps.copyActivity !== prevState.copyActivity &&
            nextProps.copyActivity.text &&
            nextProps.copyActivity.dateTimeString
        ) {
            return {
                activity: {
                    text: nextProps.copyActivity.text,
                    ...(nextProps.copyActivity.dateTimeString && {
                        dateTimeString: nextProps.copyActivity.dateTimeString,
                    }), // conditionally add the keys
                    category: nextProps.copyActivity.category || undefined,
                },
                copyActivity: nextProps.copyActivity,
            };
        }
        return prevState;
    }

    componentDidMount() {
        if (this.props.editableActivity) {
            const extractedInterval =
                this.props.selectedActivity &&
                this.props.modifyGroup &&
                this.props.selectedActivity.recurrence &&
                this.props.selectedActivity.recurrence.split('INTERVAL=')[1] &&
                this.props.selectedActivity.recurrence.split('INTERVAL=')[1].split(';')[0];
            const interval = (extractedInterval && !isNaN(Number(extractedInterval))) ? Number(extractedInterval) : 1;
            this.setState({
                activity: {
                    ...this.props.editableActivity,
                    dateTimeString:
                        this.props.selectedActivity &&
                        this.props.selectedActivity.recurrence &&
                        this.props.dateTimeString &&
                        this.props.modifyGroup
                            ? moment(
                                  this.props.selectedActivity.recurrence
                                      .split('\n')[0]
                                      .split('DTSTART=')[1]
                                      .replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3'),
                              )
                                  .set({
                                      hour: moment(this.props.dateTimeString).format('HH'),
                                      minute: moment(this.props.dateTimeString).format('mm'),
                                      second: moment(this.props.dateTimeString).format('ss'),
                                  })
                                  .format('YYYY-MM-DDTHH:mm:ss')
                            : this.props.dateTimeString
                            ? this.props.dateTimeString
                            : undefined,
                    endDate:
                        this.props.selectedActivity && this.props.selectedActivity.recurrence && this.props.modifyGroup
                            ? moment(
                                      this.props.selectedActivity.recurrence
                                          .split('UNTIL=')[1]
                                          .split('\n')[0]
                                          .replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3'),
                              )
                                  .set({
                                      hour: moment(this.props.dateTimeString).format('HH'),
                                      minute: moment(this.props.dateTimeString).format('mm'),
                                      second: moment(this.props.dateTimeString).format('ss'),
                                  })
                                  .format('YYYY-MM-DDTHH:mm:ss')
                            : undefined,
                },
                interval
            }, () => {
                this.generateRecurrence();
            });
        } else {
            this.generateRecurrence();
        }
        try{
            this.setState({ isLoading: true })
            listRoomTypes().then((res) => {
                const locationOptions = res.Result && res.Result.map(el => ({ key: el._id, value: el._id, text: el.Name }));
                this.setState({
                    locationOptions,
                    isLoading: false
                });
            })
        } catch (error) {
            toast.warn(error instanceof Error ? error.message : "Failed to list room types", {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            })
            this.setState({ isLoading: false })
        }
        
    }

    handleFileChange(file: File) {
        this.setState({
            imageFile: file,
        });
    }

    handleCopyImage(url: string) {
        // supported file extensions
        const fileExtensions = ['.jpg', '.png'];
        const regex = new RegExp(`(${fileExtensions.join('|')})\\b(?!.*\\1)`);
        const match = url.match(regex);

        if (match && match.index) {
            // If a supported extension is found, truncate the URL at that point
            const truncatedUrl = url.substring(0, match.index + match[0].length);
            this.setState({
                copyImageUrl: truncatedUrl,
            });
        } else {
            // If no supported extension is found, use the original URL
            this.setState({
                copyImageUrl: url,
            });
        }
    }

    clearSignageContentState() {
        this.setState({
            copyImageUrl: undefined,
            imageFile: null,
        });
    }

    getAndStorePreviewSignageImg(img: string | undefined) {
        this.setState({
            signagePreviewImage: img,
        });
    }

    get intervalType() : string {
        const interval = this.state.interval;
        const correctGrammar = (value: string) => interval > 1 ? value + 's' : value;
        switch (this.state.repeatType) {
            case 'DAILY':
                return correctGrammar('Day')
            case 'WEEKLY':
                return correctGrammar('Week')
            case 'MONTHLY':
                return correctGrammar('Month')
            default:
                return '';
        }
    }
    

    renderSaveBtn(timestamp: number | string) {
        return <Button
            type="submit"
            primary
            loading={this.props.isSaving}
            disabled={this.props.isSaving || !this.validForm()}
        >
            Save Activity
        </Button>
    }

    render() {
        const renderLabel = (label) => {
            console.log('lll', label, 'llll');
            return {
                ...label,
            };
        };
        return (
            <Form loading={this.props.isFetching} autoComplete="false" onSubmit={this.handleSubmit.bind(this)}>
                <div className="audio-or-text">
                    <h3 className="text-center">{this.props.editableActivity ? 'Update' : 'Create'} your Activity</h3>
                    <div className="audio-or-text-header">
                        <span>Record your voice</span>
                    </div>
                    <div className="audio-recorder">
                        {this.props.passAudioBlob ? <AudioRecorder passAudioBlob={this.props.passAudioBlob} /> : <></>}

                        {this.props.editableActivity && this.props.editableActivity.playableUrl ? (
                            <div className="current-audio">
                                <p>To change your audio, press the Mic</p>
                                <p>
                                    <br />
                                    Current Audio
                                </p>
                                <audio controls src={this.props.editableActivity.playableUrl}>
                                    Your browser does not support the
                                    <code>audio</code> element.
                                </audio>
                            </div>
                        ) : (
                            <></>
                        )}

                        {this.props.copyActivity && this.props.copyActivity.playableUrl ? (
                            <div className="current-audio">
                                <p>To change your audio, press the Mic</p>
                                <p>
                                    <br />
                                    Current Audio
                                </p>
                                <audio controls src={this.props.copyActivity.playableUrl}>
                                    Your browser does not support the
                                    <code>audio</code> element.
                                </audio>
                            </div>
                        ) : (
                            <></>
                        )}

                        <Form.Field required>
                            <TextArea
                                style={{ border: '1px solid #183466', marginTop: '12px' }}
                                value={this.state.activity.text || ''}
                                type="textarea"
                                placeholder="Enter and save your text here"
                                onChange={(e) => this.setActivityText.call(this, e.currentTarget.value)}
                            />
                        </Form.Field>
                    </div>
                    <Form.Field style={{ marginTop: '15px', width: '300px' }}>
                        <Dropdown
                            placeholder="Category"
                            fluid
                            selection
                            renderLabel={renderLabel}
                            options={['intellectual', 'occupational', 'physical', 'social', 'emotional', 'spiritual', 'environmental', 'virtual'].map((item) => {
                                return {
                                    key: item,
                                    value: item,
                                    text: item,
                                    label: { className: `act-${item}`, empty: true, circular: true },
                                };
                            }).sort((a, b) => a.text.localeCompare(b.text))}
                            value={this.state.activity.category} 
                            onChange={(event, data) => {
                                this.setState({
                                    activity: {
                                        ...this.state.activity,
                                        category: `${data.value}`,
                                    },
                                });
                            }}
                            className="menu-dropdown"
                        />
                    </Form.Field>
                    <Form.Field style={{ width: '300px' }}>
                        <div className="customDatePickerWidth">
                            <DatePicker
                                style={{ border: '1px solid #183466' }}
                                placeholderText="Select date"
                                dateFormat="M.d.Y h:mm aa"
                                selectsStart
                                showTimeSelect
                                timeIntervals={15}
                                selected={
                                    this.state.activity.dateTimeString &&
                                    moment(this.state.activity.dateTimeString).toDate()
                                }
                                startDate={
                                    this.state.activity.dateTimeString &&
                                    moment(this.state.activity.dateTimeString).toDate()
                                }
                                endDate={this.state.activity.endDate && moment(this.state.activity.endDate).toDate()}
                                onChange={(date) => this.setActivityTime(date)}
                                onCalendarOpen={() => {
                                    if(!this.state.activity.dateTimeString) {
                                        this.setState(prev => {
                                           return {
                                               ...prev, 
                                               activity: {
                                                ...prev.activity, 
                                                dateTimeString: moment().hour(9).minute(0).second(0).toDate()
                                            }
                                           }
                                        })
                                    }
                                }}
                            />
                        </div>
                    </Form.Field>
                    <Form.Field style={{ width: '300px' }}>
                        <Dropdown
                            selection
                            multiple
                            control={Select}
                            placeholder="Select Calendar"
                            options={sortByKey(this.state.locationOptions)}
                            onChange={(e, { value }) => {
                                if (value) {
                                    this.setState({
                                        selectedLocation: value as string[]
                                    });
                                    this.props.handleLocationUpdate && this.props.handleLocationUpdate(value as [])
                                }
                            }}
                            value={this.state.selectedLocation}
                            loading={this.state.isLoading}
                        />
                    </Form.Field>
                        {!this.props.disableRecurrence && 
                            <Form.Field style={{ width: '300px' }}>
                                <Dropdown
                                    placeholder="Repeats"
                                    value={this.state.repeatType}
                                    fluid
                                    selection
                                    options={[
                                        {
                                            key: 'Off',
                                            text: 'Repeat off',
                                            value: 'OFF',
                                        },
                                        {
                                            key: 'Daily',
                                            text: 'Repeat daily',
                                            value: 'DAILY',
                                        },
                                        {
                                            key: 'Weekly',
                                            text: 'Repeat weekly',
                                            value: 'WEEKLY',
                                        },
                                        {
                                            key: 'Monthly',
                                            text: 'Repeat monthly',
                                            value: 'MONTHLY',
                                        },
                                    ]}
                                    onChange={(e, d) => {
                                        this.setState({
                                            repeatType: String(d.value),
                                        });
                                        if (d.value === 'OFF') {
                                            this.setState({
                                                activity: {
                                                    ...this.state.activity,
                                                    recurrence: undefined,
                                                    endDate: undefined,
                                                },
                                            });
                                        }
                                    }}
                                />
                            </Form.Field>
                        }
                        {
                            !this.props.disableRecurrence && 
                            this.state.repeatType && 
                            this.state.repeatType !== 'OFF' && 
                            (
                                <Form.Field inline>
                                    <label>Once every</label>
                                    <Input
                                        type='number'
                                        min={1}
                                        value={this.state.interval}
                                        onChange={(e, d) => {
                                            const interval = d.value;
                                            if (interval && Number(interval) > 0) {
                                                this.setState({
                                                    interval: Number(interval),
                                                }, () => this.generateRecurrence());
                                            }
                                        }}
                                    >
                                    </Input>
                                    <label style={{marginLeft: '10px'}}>{this.intervalType}</label>
                                </Form.Field>
                            )  
                        }
                    {!this.props.disableRecurrence && this.state.activity.dateTimeString && this.state.repeatType && this.state.repeatType !== 'OFF' && (
                        <Form.Field style={{ width: '300px' }}>
                            <div className="customDatePickerWidth">
                                <DatePicker
                                    style={{ border: '1px solid #183466' }}
                                    placeholderText="Select End Date"
                                    dateFormat="M.d.Y h:mm aa"
                                    selectsEnd
                                    selected={
                                        this.state.activity.endDate && moment(this.state.activity.endDate).toDate()
                                    }
                                    startDate={
                                        this.state.activity.dateTimeString &&
                                        moment(this.state.activity.dateTimeString).toDate()
                                    }
                                    endDate={
                                        this.state.activity.endDate && moment(this.state.activity.endDate).toDate()
                                    }
                                    minDate={moment(this.state.activity.dateTimeString).toDate()}
                                    onChange={(date) => {
                                        const localDate = moment(date).set({
                                                hour: moment(this.state.activity.dateTimeString).format('HH'),
                                                minute: moment(this.state.activity.dateTimeString).format('mm'),
                                                second: moment(this.state.activity.dateTimeString).format('ss'),
                                            }).format('YYYY-MM-DDTHH:mm:ss');
                                        this.setState(
                                            {
                                                activity: {
                                                    ...this.state.activity,
                                                    endDate: localDate,
                                                },
                                            },
                                            () => {
                                                this.generateRecurrence();
                                            },
                                        );
                                    }}
                                />
                            </div>
                        </Form.Field>
                    )}

                    {this.state.repeatType && this.state.repeatType !== 'OFF' && this.state.activity.dateTimeString && this.state.activity.endDate && (
                        <ExcludeDatesField
                            excludeDates={this.state.excludeDates}
                            setExcludeDates={(excludeDates) => this.setState({ excludeDates })}
                            datePickerMinDate={this.state.datePickerMinDate}
                            datePickerMaxDate={this.state.datePickerMaxDate}
                            generateRecurrence={() => {this.generateRecurrence()}}
                        />
                    )}

                    {this.props.attendanceToggle && (
                        <Form.Field style={{ width: '300px' }}>
                            <Checkbox
                                toggle
                                onChange={async (e, data) => {
                                    if (this.state.activity) {
                                        this.setState({
                                            activity: {
                                                ...this.state.activity,
                                                showAttendance: data.checked,
                                            },
                                        });
                                    }
                                }}
                                checked={this.state.activity && this.state.activity.showAttendance}
                                label={`Registration turned  ${
                                    this.state.activity && this.state.activity.showAttendance ? 'on' : 'off'
                                }`}
                            />
                        </Form.Field>
                    )}

                    <Form.Field>
                        <div style={{ position: 'relative' }}>
                        <Dimmer active={(!this.props.modifyGroup && this.props.editableActivity && this.props.editableActivity.srcId && (this.props.editableActivity._id === this.props.editableActivity.srcId)) || false} inverted></Dimmer>
                        <h3 style={{ textAlign: 'left', marginTop: '5px' }}>Signage</h3>
                        <SignageContentImgCaptureAndDisplay
                            handleFileChange={this.handleFileChange.bind(this)}
                            contentId={
                                this.props.editableActivity && this.props.editableActivity.signageImageId ? this.props.editableActivity.signageImageId : this.props._id
                            }
                            contentType={'activities'}
                            galleryImageClickHandler={this.handleCopyImage.bind(this)}
                            clearSignageState={this.clearSignageContentState.bind(this)}
                            getFetchedPreviewImage={this.getAndStorePreviewSignageImg.bind(this)}
                            title={this.state.activity.text ? capitalizeFirstLetter(this.state.activity.text) : ""}
                            subTitle={this.state.activity.timestamp ? new Date(this.state.activity.timestamp).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' }) : ""}
                            defaultImage={'https://speak2-signage.s3.amazonaws.com/activities/default-images/activity.png'}
                        />
                        </div>
                    </Form.Field>
                    {this.renderSaveBtn(this.state.activity.dateTimeString || this.state.activity.timestamp)}
                    <Button
                        onClick={(e) => {
                            e.preventDefault();
                            if (this.props.copyActivity && this.props.handleRedirect) {
                                this.props.handleRedirect()
                            }
                            if (this.props.activityDetailView) {
                                this.setState({
                                    activity: {
                                        text: this.props.editableActivity && this.props.editableActivity.text,
                                        ...(this.props.editableActivity &&
                                            this.props.editableActivity.timestamp && {
                                                timestamp: this.props.editableActivity.timestamp,
                                            }),
                                        dateTimeString:
                                            this.props.editableActivity && this.props.editableActivity.dateTimeString,
                                        category: this.props.editableActivity && this.props.editableActivity.category,
                                    },
                                });
                            } else {
                                if (this.props.edit) {
                                    this.props.cancelEditActivity(this.props._id);
                                } else {
                                    this.setState({
                                        activity: {
                                            text: '',
                                            timestamp: null,
                                            dateTimeString: undefined,
                                            category: '',
                                            recurrence: undefined,
                                            endDate: undefined,
                                        },
                                        repeatType: 'OFF',
                                    });
                                    this.props.cancelEditActivity();
                                }
                            }
                        }}
                    >
                        Cancel
                    </Button>
                    {this.props.copyClickHandler && (
                        <Button
                            className="copyBtn"
                            onClick={() => {
                                if (this.props.copyClickHandler)
                                    this.props.copyClickHandler(
                                        this.props.selectedActivity,
                                        this.state.signagePreviewImage,
                                    );
                            }}
                        >
                            Copy
                        </Button>
                    )}
                    {this.props.handleDelete && (
                        <Button
                            loading={this.state.isLoading}
                            color="red"
                            onClick={(e) => { this.props.handleDelete && this.props.handleDelete(e); }}
                        >
                            Delete
                        </Button>
                    )
                    }
                </div>

                {this.props.audioError && <Message negative>{this.props.audioError}</Message>}
            </Form>
        );
    }
}


function mapStateToProps({ authReducer }: AppState) {
    return {
        profile: authReducer.profile,
    }
}

export default withRouter(connect(mapStateToProps)(ActivityForm));