import React, { useEffect, useState } from 'react';
import ServiceInstanceFormCreate from './ServiceInstanceFormCreate';
import { withRouter } from 'react-router-dom';
import { ServicesType } from '../../types/ServicesTypes';
import "./style.less";
import { fetchServiceInstance } from '../../services/ServiceInstances';
import { toast } from 'react-toastify';
import { Button, Dimmer, Grid, Header, Icon, Loader } from 'semantic-ui-react';
import KanbanBoards from '../../pages/AdminPanel/Menu/kanban-boards';
import AcivityKanbanBoard from '../../pages/AdminPanel/DailyActivities/kanban-boards';
import MenukanbanInitData from '../../pages/AdminPanel/Menu/kanban-init-data';
import ActivityKanbanInitData from '../../pages/AdminPanel/DailyActivities/kanban-init-data';
import { useSelector } from 'react-redux';
import { AuthState } from '../../types';
import { listMenuAttendee } from '../../services/Menus';
import { kanbanDataType } from '../../types/kanbanDataTypes';
import { fetchAllActiveFacilityRegistrants } from '../../services/Registrants';
import { listActivityAttendees } from '../../services/DailyActivities';
import { LocationOption } from '../../types/ServiceInstance';
import { sendToast } from '../../util';
import moment from 'moment';

function SvcInstanceDetailedView(props) {
  const { match, location, source } = props;
  const { id: serviceInstanceId } = match.params;
  const [instanceData, setInstanceData] = useState<Partial<ServicesType>>({
    name: '',
    longDescription: '',
    shortDescription: '',
    Asset: '',
    calendarType: props.selectedCalendarType,
  });
  const [edit, setEdit] = useState(true);
  const [loadingInstance, setLoadingInstance] = useState(true);
  const [kanbanBoardInitalState, setKanbanBoardInitalState] = useState<kanbanDataType>();
  const [isKanbanLoading, setIsKanbanLoading] = useState<boolean>(true);
  const [selectedAttendees, setSelectedAttendees] = useState<string[]>([]);
  const [locationOptions, setLocationOptions] = useState<LocationOption[]>([]);
  const [isAutomateWaitingList, setIsAutoMateWaitingList] = useState<boolean>(false);
  const [isRegistrationLocked, setIsRegistrationLocked] = useState<boolean>(false);
  const [isToggleChangedByUser, setIsToggleChangedByUser] = useState<boolean>(false);
  const [selectedThisAndFollowingDate, setSelectedThisAndFollowingDate] = useState<string | undefined>(undefined)
  const profile = useSelector(({ authReducer }: { authReducer: AuthState; }) => {
    return authReducer.profile;
  });

  useEffect(() => {
    fetchAllData();
  }, []);

  useEffect(() => {
    if (source === 'Menu') {
      setKanbanBoardInitalState(MenukanbanInitData);
    } else if (source === 'DailyActivities') {
      console.log("insiatldataAct", ActivityKanbanInitData);
      setKanbanBoardInitalState(ActivityKanbanInitData);
    }
  }, [serviceInstanceId]);

  useEffect(() => {
    if (source === 'Menu') {
      if (instanceData && instanceData.calendarType) {
        setKanbanDataForMenu(instanceData.calendarType);
      }
    } else if (source === 'DailyActivities') {
      if (instanceData && instanceData.calendarType) setKanbanDataForActivity(instanceData.calendarType);
    }
  }, [instanceData.calendarType]);


  const fetchAllData = async () => {
    setLoadingInstance(true);
    try {
      const params = {
        Filter: {
          _id: serviceInstanceId
        }
      };
      const response = await fetchServiceInstance(params);
      setInstanceData(response);
      setIsRegistrationLocked(response && response.isRegistrationLocked || false);
      setIsAutoMateWaitingList(response && response.defaults && response.defaults.automateWaitingList || false);
    } catch (error) {
      console.error("error", error);
      toast.error("Error in fetching service instance", {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
    } finally {
      setLoadingInstance(false);
    }
  };

  const goBacktoSvcInstanceList = () => {
    if (source === 'Menu' || source === 'DailyActivities') {
      const previousUrl = props.history.location.state ? props.history.location.state : '/';
      const previousDate = previousUrl.selectedDate || moment(new Date()).format('YYYY-MM-DD');
      const queryParams = new URLSearchParams(previousUrl.selectedTab);
      const previousTab = queryParams.get('tab') || 'daily';
      props.history.push(`/admin/${source === 'Menu' ? "menu" : "dailyactivities"}?tab=${previousTab}&date=${previousDate}`);
    }
    else props.history.goBack();
  };

  const fetchMenuAttendees = async (menu): Promise<any> => {
    try {
      const [attendees, facilityRegistrants] = await Promise.all([
        listMenuAttendee(menu._id, true),
        fetchAllActiveFacilityRegistrants(menu.Facility, true, true, true)
      ]);
      return [attendees, facilityRegistrants];
    } catch (error) {
      console.error("error", error);
      toast.error("Error in fetching Attending list for Menu", {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
  };

  const getLocationId = (location: string, locationOptions: any[]) => {
    const foundObject = locationOptions.find((option) => option.text === location);
    return foundObject ? foundObject.value : '';
  };

  const getThisAndFollowingDateValue = (date: string | undefined) => {
    setSelectedThisAndFollowingDate(date);
  }

  const handleLockRegistrationChange = (value: boolean) => {
    setIsRegistrationLocked(value);
  }

  const fetchActivityAttendees = async (activity): Promise<any> => {
    try {
      const [attendees, facilityRegistrants] = await Promise.all([
        listActivityAttendees(activity._id, true),
        fetchAllActiveFacilityRegistrants(activity.Facility, true /* excludeAlisImage */, true, /* includeRoomInfo */ true),
      ]);
      return [attendees, facilityRegistrants];
    } catch (error) {
      console.error("error", error);
      toast.error("Error in fetching Attendes List.", {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
  };

  const setKanbanDataForMenu = async (value: null | string[]) => {
    try {
      setIsKanbanLoading(true);
      const [selectedMenuAttendees, allFacilityRegistrants] = await fetchMenuAttendees({ _id: serviceInstanceId, Facility: profile && profile.Facility });
      const facilityRegistrants = allFacilityRegistrants.filter((registrant) => {
        if (value) {
          if (value && Array.isArray(value) && value.length > 0) {
            return registrant.Unit ? (value.includes(registrant.Unit.RoomCategory) || value.includes(getLocationId('Other', locationOptions))) : true;
          }
        } else {
          if (instanceData && instanceData.calendarType && instanceData.calendarType.length > 0) {
            return registrant.Unit ? (instanceData.calendarType.includes(registrant.Unit.RoomCategory)) : true;
          } else {
            return true;
          }
        }
      });

      let kanbanDataTemp: kanbanDataType = JSON.parse(JSON.stringify(MenukanbanInitData));
      let menuAttendeesNames: string[] = [];

      const intendedRegistrantIds: String[] = [];

      selectedMenuAttendees.Result.forEach((attendeeObj) => {
        const registrant = facilityRegistrants.find(registrant => registrant._id === attendeeObj.registrantId);
        attendeeObj.key = attendeeObj._id;
        attendeeObj.id = attendeeObj._id;
        attendeeObj.title = attendeeObj.registrant.FirstName + ' ' + attendeeObj.registrant.LastName;
        attendeeObj.roomName = (registrant && registrant.Unit) ? registrant.Unit.Name : undefined;
        if (attendeeObj.status === 'attended') {
          kanbanDataTemp.columns[1].cards.push(attendeeObj);
        }
        if (attendeeObj.status === 'delivery') {
          delete attendeeObj.declineReason;
          kanbanDataTemp.columns[2].cards.push(attendeeObj);
          menuAttendeesNames.push(attendeeObj.title);
        }
        if (attendeeObj.status === 'removed') {
          delete attendeeObj.deliveryReason;
          attendeeObj.declineReason = attendeeObj.declineReason || 'Refused';
          kanbanDataTemp.columns[3].cards.push(attendeeObj);
        }
        intendedRegistrantIds.push(attendeeObj.registrantId);
      });

      facilityRegistrants.forEach((registrant) => {
        let attendeeObj = {
          key: 'temp' + registrant._id,
          id: 'temp' + registrant._id,
          registrantId: registrant._id,
          title: registrant.FirstName + ' ' + registrant.LastName,
          registrant: {
            FirstName: registrant.FirstName,
            LastName: registrant.LastName
          },
          roomName: registrant.Unit ? registrant.Unit.Name : undefined,
        };
        if (!intendedRegistrantIds.includes(registrant._id)) {
          kanbanDataTemp.columns[0].cards.push(attendeeObj);
        }
      });
      setKanbanBoardInitalState(kanbanDataTemp);
      setSelectedAttendees(selectedMenuAttendees.Result);
    } catch (error) {
      console.log("Error in setting kanban for menu", error);
      toast.error("Error in fetching activity instance", {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
    } finally {
      setIsKanbanLoading(false);
    }

  };

  const setKanbanDataForActivity = async (value: null | string[]) => {
    try {
      setIsKanbanLoading(true);
      const [[selectedActivityAttendees, allFacilityRegistrants, residentRooms], serviceInstance] = await Promise.all([fetchActivityAttendees({ _id: serviceInstanceId, Facility: profile && profile.Facility }), fetchServiceInstance({ Filter: { _id: serviceInstanceId } })])
      const isWaitingListEnabled = serviceInstance && serviceInstance.defaults && serviceInstance.defaults.waitingList;

      const facilityRegistrants = allFacilityRegistrants.filter((registrant) => {
        if (value) {
          if (value && Array.isArray(value) && value.length > 0) {
            return registrant.Unit ? (value.includes(registrant.Unit.RoomCategory) || value.includes(getLocationId('Other', locationOptions))) : true;
          }
        } else {
          if (instanceData && instanceData.calendarType && instanceData.calendarType.length > 0) {
            return registrant.Unit ? (instanceData.calendarType.includes(registrant.Unit.RoomCategory)) : true;
          } else {
            return true;
          }
        }
      });

      let kanbanDataTemp: kanbanDataType = JSON.parse(JSON.stringify(ActivityKanbanInitData));
      let activityAnnounceeNames: string[] = [];

      const intendedRegistrantIds: String[] = [];
      // Sort the waiting list attendees by the date they were added first person added first in the 
      const sortedAttendees = selectedActivityAttendees.Result.sort((a, b) => {
        if (a.status === 'waiting' && b.status !== 'waiting') return -1;
        if (a.status !== 'waiting' && b.status === 'waiting') return 1;
        return new Date(a.DateAdded).getTime() - new Date(b.DateAdded).getTime();
      });

      let queueNumber = 1; // Initialize the queue number

      sortedAttendees.forEach((attendeeObj) => {
        const registrant = facilityRegistrants.find(registrant => registrant._id === attendeeObj.registrantId);
        attendeeObj.key = attendeeObj._id;
        attendeeObj.id = attendeeObj._id;
        attendeeObj.title = attendeeObj.registrant.FirstName + ' ' + attendeeObj.registrant.LastName;
        attendeeObj.roomName = (registrant && registrant.Unit) ? registrant.Unit.Name : undefined;

        if (attendeeObj.status === 'intend_attend') {
          delete attendeeObj.participation;
          delete attendeeObj.declineReason;
          kanbanDataTemp.columns[1].cards.push(attendeeObj);
          activityAnnounceeNames.push(attendeeObj.title);
        }

        if (attendeeObj.status === 'attended') {
          attendeeObj.participation = attendeeObj.participation || 'active';
          kanbanDataTemp.columns[2].cards.push(attendeeObj);
        }

        if (attendeeObj.status === 'removed') {
          attendeeObj.declineReason = attendeeObj.declineReason || 'Not Interested';
          kanbanDataTemp.columns[3].cards.push(attendeeObj);
        }

        if (attendeeObj.status === 'waiting') {
          attendeeObj.queueNumber = queueNumber; // Assign the queue number
          queueNumber++; // Increment the queue number
          kanbanDataTemp.columns[4].cards.push(attendeeObj);
        }

        intendedRegistrantIds.push(attendeeObj.registrantId);
      });

      facilityRegistrants.forEach((registrant) => {
        let attendeeObj = {
          key: 'temp' + registrant._id,
          id: 'temp' + registrant._id,
          registrantId: registrant._id,
          title: registrant.FirstName + ' ' + registrant.LastName,
          registrant: {
            FirstName: registrant.FirstName,
            LastName: registrant.LastName,
          },
          roomName: registrant.Unit ? registrant.Unit.Name : undefined,
        };
        if (!intendedRegistrantIds.includes(registrant._id)) {
          kanbanDataTemp.columns[0].cards.push(attendeeObj);
        }
      });
      if (!isWaitingListEnabled) { // if waiting list is disabled, remove the waiting list column
        kanbanDataTemp.columns = kanbanDataTemp.columns.filter(column => column.title !== 'Waiting List');
      }

      setKanbanBoardInitalState(kanbanDataTemp);
      setSelectedAttendees(selectedActivityAttendees);
    } catch (error) {
      console.log('error seting kanban for activity', error);
      toast.error("Error in fetching activity instance", {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
    } finally {
      setIsKanbanLoading(false);
    }
  };

  const addNewAttendee = (name: string) => {
    let announcees: string[] = [];
    if (selectedAttendees) {
      announcees = [...selectedAttendees];
    } else {
      announcees = [];
    }
    if (!announcees.includes(name)) {
      announcees.push(name);
    }
    setSelectedAttendees(announcees);
  };

  const removeAttendee = (name: string) => {
    if (selectedAttendees) {
      let announcees = [...selectedAttendees];
      const indexToRemove = announcees.indexOf(name);
      if (indexToRemove >= 0) {
        announcees.splice(indexToRemove, 1);
        setSelectedAttendees(announcees);
      }
    }
  };

  const refreshData = async () => { // this fn is called for daily activities/ svc activities
    try {
      if (source !== 'Menu') {
        setKanbanDataForActivity(null);
      }
    } catch (error) {
      sendToast('error', 'Something went wrong while refreshing data');
    }
  }

  return (
    <>
      <Button
        basic
        size="medium"
        type={'button'}
        style={{ marginBottom: '10px' }}
        onClick={(e) => {
          e.preventDefault();
          goBacktoSvcInstanceList();
        }}
      >
        <Icon name="chevron left" /> Back to all {source === 'Menu' ? 'menu' : 'daily activities'}
      </Button>
      <div className='center-self'>
        <div style={{ width: "500px" }}>
          <Dimmer active={loadingInstance} inverted>
            <Loader inverted>Loading...</Loader>
          </Dimmer>
          <ServiceInstanceFormCreate
            instanceData={instanceData}
            setInstanceData={setInstanceData}
            isEdit={edit}
            setIsEdit={() => { setEdit(!edit); }}
            closeModal={goBacktoSvcInstanceList}
            reloadServices={() => { }}
            source={props.source}
            setCalendarTypesOptions={setLocationOptions}
            getThisAndFollowingDate={getThisAndFollowingDateValue}
            isRegistrationLocked={isRegistrationLocked}
            isToggleChangedByUser={isToggleChangedByUser}
          />
        </div>
      </div>
      <div>
        {kanbanBoardInitalState && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div>
              <Header as='h2' style={{ textAlign: 'center' }} content="Manage Attendance" />
              <div style={{ position: 'relative', height: '1000px' }}>
                {/** Show a loader only when the source is not menu as the menu has it's own loader */}
                {
                  source !== "Menu" ? <Dimmer active={isKanbanLoading} inverted>
                    <Loader inverted>Loading...</Loader>
                  </Dimmer> : <> </>
                }
                {source === "Menu" ? (
                  <KanbanBoards
                    board={kanbanBoardInitalState}
                    loading={isKanbanLoading}
                    setLoading={setIsKanbanLoading}
                    facilityId={profile && profile.Facility}
                    svcInstanceId={serviceInstanceId}
                    addNewAttendee={(name) => {
                      addNewAttendee && addNewAttendee(name);
                    }}
                    removeAttendee={(name) => {
                      removeAttendee && removeAttendee(name);
                    }}
                    thisAndFollowingDate={selectedThisAndFollowingDate}
                    searchable={true}
                  />
                ) : (
                  <AcivityKanbanBoard
                    board={kanbanBoardInitalState}
                    loading={isKanbanLoading}
                    facilityId={profile && profile.Facility}
                    svcInstanceId={serviceInstanceId}
                    addNewAttendee={(name) => {
                      addNewAttendee && addNewAttendee(name);
                    }}
                    removeAttendee={(name) => {
                      removeAttendee && removeAttendee(name);
                    }}
                    refreshData={refreshData}
                    thisAndFollowingDate={selectedThisAndFollowingDate}
                    isWaitingListMovementEnabled={isAutomateWaitingList}
                    searchable={true}
                    isRegistrationLocked={isRegistrationLocked}
                    handleLockRegistrationChange={handleLockRegistrationChange}
                    setIsToggleChangedByUser={setIsToggleChangedByUser}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default withRouter(SvcInstanceDetailedView);