import React from 'react';
import '../style.less';

type Props = {
    disabled?: boolean;
    title: string;
    onClickHandler: () => void;
};

const UnacceptBtn = ({ disabled, onClickHandler, title }: Props) => {
    return (
        <div>
            <button disabled={disabled} onClick={onClickHandler} className="edit-request-btn" type="button">
                <div>
                    <i className="fa-solid fa-circle-minus updateIcon" style={{ color: '#1991EB' }}></i>
                </div>
                <div className='btnReqTitle'>{title}</div>
            </button>
        </div>
    );
};

export default UnacceptBtn;
