import React, { CSSProperties } from 'react'
import './style.less'

interface Props {
    ribbonColor: string,
    heading: string,
    source?: string
}

const index = ({ ribbonColor, heading, source }: Props) => {
    const lineHeight = (source && source === 'requestsDetailView') ? '2.3em' : '1.7em';
    const marginLeft = (source && source === 'requestsDetailView') ? '0' : '10px';
    interface CustomCSSProperties extends CSSProperties {
        '--ribbonColor'?: string;
    }
    return (
        <div className="ribbon" style={{ '--ribbonColor': ribbonColor, '--lineHeight': lineHeight, '--marginLeft': marginLeft } as CustomCSSProperties} >
            <span className="ribbon-text"> {heading}</span>
        </div>
    )
}

export default index