import React, { FC, useEffect, useState } from 'react'
import { Dimmer, Header, Loader, Table } from 'semantic-ui-react'
import moment from "moment-timezone"
import { AlexaStatus } from '../../types/AlexaStatus'
import PrintExportIcons from '../PrintExportIcons'
import { listAlexaStatus, upsertAlexaStatus } from '../../services/AlexaStatus'
import { Gadget } from '../../types'
import { formatAndExportDataForPrint } from '../../util/jsonTocsvDownloader'

interface Props {
    gadget: Gadget
}

interface TableProps {
    deviceStatus?: AlexaStatus
}

const DeviceTable: FC<TableProps> = ({ deviceStatus }) => {
    return (
        <div style={{ maxHeight: '300px', overflow: 'auto', }}>
            <Table size='small'>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        <Table.HeaderCell>Timestamp</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {deviceStatus ? deviceStatus.data.map((row, i) => (
                        <Table.Row key={i}>
                            <Table.Cell>{row.status}</Table.Cell>
                            <Table.Cell>{row.date}</Table.Cell>
                        </Table.Row>
                    )) : <></>}
                </Table.Body>
            </Table>
        </div>
    );
};
const DeviceStatus: FC<Props> = ({ gadget }) => {
    const { a4hRoomId } = gadget
    const [deviceStatusEvents, setDeviceStatusEvents] = useState<AlexaStatus>()
    const [isLoading, setIsLoading] = useState(false)
    const [printLoading, setPrintLoading] = useState(false)

    async function loadDeviceData() {
        const deviceStatusEventsRes = await listAlexaStatus(a4hRoomId || "")
        setDeviceStatusEvents(deviceStatusEventsRes)
    }

    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const currentTimestamp = new Date()
            const deviceTimeZone = gadget.timeZone;
            const formattedDate = moment(currentTimestamp).tz(deviceTimeZone || "American/NewYork").format("MM/DD/YYYY");
            await upsertAlexaStatus({
                a4hRoomId,
                date: formattedDate,
                status: gadget.status
            })
            await loadDeviceData()
            setIsLoading(false)
        })()
    }, [])

    const getExportData = () => {
        const todaysFormattedDate = moment().format("MM/DD/YYYY");
        const result: any = [];
        const { distanceUnits, doNotDisturb, friendlyName, languages, macAddress, manufacturer, maximumVolumeLimit, model, serialNumber, speechConfirmation, temperatureUnit, timeZone, wakeWords, status } = gadget
        result.push({ distanceUnits, doNotDisturb, friendlyName, languages, macAddress, manufacturer, maximumVolumeLimit, model, serialNumber, speechConfirmation, temperatureUnit, timeZone, wakeWords, status, date: todaysFormattedDate });
        if (deviceStatusEvents && deviceStatusEvents.data && deviceStatusEvents.data.length > 0) {
            deviceStatusEvents.data.forEach((item, i) => {
                if (item.date !== todaysFormattedDate) {
                    result.push({ date: item.date, status: item.status });
                }
            });
        }
        return result;
    }

    const handlePrintClick = async () => {
        if (deviceStatusEvents) {
            const printArr = deviceStatusEvents.data.map(el => {
                return {
                    Subject: el.status,
                    StartTime: el.date,
                    StartDate: el.date,
                }
            })
            setPrintLoading(true)
            await formatAndExportDataForPrint(printArr, `${gadget.friendlyName ? gadget.friendlyName : gadget.serialNumber}-analyze`, gadget.timeZone, "menu"); // menu formats data in "YYYY-MM-DD"
            setPrintLoading(false)
        }
    }

    return (
        <div style={{ position: "relative", width: '400px', marginTop: '10px' }}>
            {/* add position: relative to div so that dimmer only shows there */}
            <Dimmer inverted active={isLoading}>
                <Loader active={isLoading} />
            </Dimmer>
            <div>
                <div style={{ display: "flex", gap: "10px" }}>
                    <Header as={"h3"} size='small'>Device Status Events</Header>
                    <div className="" style={{ height: "100%" }}>
                        <PrintExportIcons exportData={getExportData()} onPrintClick={handlePrintClick} exportFileName={`${gadget.friendlyName ? gadget.friendlyName : gadget.serialNumber}-deviceStatus`} printLoading={printLoading} disableExportButton={isLoading} />
                    </div>
                </div>
                <DeviceTable deviceStatus={deviceStatusEvents} />
            </div>
        </div>
    )
}

export default DeviceStatus