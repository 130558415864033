import React, { useEffect, useState } from 'react';
import { Button, Dimmer, Form, Grid, Icon, Image, Input, Loader } from 'semantic-ui-react';
import { createUserWithDepartments, fetchUserRoles, updateUser } from '../../services/Users';
import { FormValues } from '../../pages/AdminPanel/Staff/UsersForm';
import { Department, User } from '../../types';
import * as Yup from 'yup';
import { fetchAllActiveDepartments } from '../../services/Departments';
import { sendToast } from '../../util';
import PhoneInput from 'react-phone-number-input';
import { uploadFile } from '../../services/ImageUpload';
import { removeUnsafeElementsFromFileName } from '../../util/image';
import cloneDeep from 'lodash/cloneDeep';

interface DiningKitchenStaffFormProps {
  user: User | null;
  source: "Dining" | "Kitchen";
  closeForm: (reload: boolean) => void;
}

const DiningKitchenStaffForm: React.FC<DiningKitchenStaffFormProps> = (props) => {
  const [formData, setFormData] = useState<FormValues>({
    Email: (props.user && props.user.Email) || '',
    FirstName: (props.user && props.user.FirstName) || (props.source === "Kitchen" ? "Station" : "") || '',
    LastName: (props.user && props.user.LastName) || '',
    Phone: (props.user && props.user.Phone) || '',
    Cell: (props.user && props.user.Cell) || '',
    Icon: (props.user && props.user.Icon) || '',
    Username: (props.user && props.user.Username) || '',
    Facility: (props.user && props.user.Facility) || undefined,
    Roles: (props.user && props.user.Roles) || [],
    Departments: (props.user && props.user.Departments) || [],
    TemporaryPassword: '',
    ConfirmPassword: '',
    AnnouncementAlerts: (props.user && props.user.AnnouncementAlerts) || false,
    EmailAnnouncementAlert: (props.user && props.user.EmailAnnouncementAlert) || ''
  });

  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [departments, setDepartments] = useState<Department[] | null>([]);
  const [role, setRole] = useState<any | null>(null);
  const [validationErrors, setValidationErrors] = useState<{ [key: string]: string; }>({});
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [formLoader, setFormLoader] = useState<boolean>(false);
  const [existingUserData, setExistingUserData] = useState<User | null>(null);

  useEffect(() => {
    if (props.user) {
      setExistingUserData(cloneDeep(props.user));
    }
  }, [props.user]);

  useEffect(() => {
    handleDataFetching();
  }, []);

  const isEdit = () => (props.user && props.user._id ? true : false);

  const kitchenStaffView = props.source === "Kitchen";
// In the onSubmit validator we use both of the schemas. One is used for create and one for update. 
  const ValidationSchema = Yup.object().shape({
    Email: Yup.string().trim().email().required(),
    FirstName: Yup.string().required('First Name is required'),
    LastName: Yup.string().required('Last Name is required'),
    Phone: Yup.string(),
    Cell: Yup.string().required(),
    Icon: Yup.string(),
    Username: ((props.user && props.user.federatedAuthUser)
      ? Yup.string().required('Username is required')
      : Yup.string().required().test('lowercase', 'Username cannot contain uppercase letters', (value) => !/[A-Z]/.test(value))
    ),
    Facility: Yup.string(),
    Departments: Yup.array().of(Yup.object()),
    TemporaryPassword: Yup.string().min(8),
    ConfirmPassword: Yup.string().oneOf([Yup.ref('TemporaryPassword')], 'Passwords must match'),
    AnnouncementAlerts: Yup.boolean().notRequired(),
    EmailAnnouncementAlert: Yup.string().notRequired()
  });

  const updateUserValidationSchema = Yup.object().shape({
    Email: Yup.string().trim().email().required(),
    FirstName: Yup.string().required('First Name is required'),
    LastName: Yup.string().required('Last Name is required'),
    Phone: Yup.string(),
    Cell: Yup.string().required(),
    Icon: Yup.string(),
    Username: Yup.string().required('Username is required'),
    Facility: Yup.string(),
    Departments: Yup.array().of(Yup.object()),
    AnnouncementAlerts: Yup.boolean().notRequired(),
    EmailAnnouncementAlert: Yup.string().notRequired()
  });

  async function handleSave(data: FormValues) {
    try {
      const saveData = cloneDeep(data);
      if (imageFile) {
        const url = await uploadFile('user-images', imageFile);
        saveData.Icon = url;
      }

      delete saveData.VerificationCode;
      delete saveData.ConfirmPassword;
      delete saveData.PasswordReset;
      delete saveData.ConfirmPasswordReset;

      if (departments && role) {
        await createUserWithDepartments(saveData, departments, [role]);
        props.closeForm(true);
      } else {
        throw new Error("Department or Role not found");
      }
    } catch (error) {
      console.log(error);
      throw new Error(error instanceof Error ? error.message : "Error while saving the user");
    }
  }

  const handleDataFetching = async () => {
    try {
      setFormLoader(true);
      const departments = await fetchAllActiveDepartments();
      const role = await fetchUserRoles();
      let sourceDepartments: Department[] = [];
      if (props.source === "Dining") {
        const diningDepartment = departments.find((department) => department.Name === 'Dining');
        if(!diningDepartment) {
          throw new Error("Dining Department not found");
        }
        diningDepartment && sourceDepartments.push(diningDepartment);
      } else if (props.source === "Kitchen") {
        const diningDepartment = departments.find((department) => department.Name === 'Dining') || null;
        const kitchenDepartment = departments.find((department) => department.Name === 'Kitchen') || null;
        if (diningDepartment && kitchenDepartment) {
          sourceDepartments.push(diningDepartment);
          sourceDepartments.push(kitchenDepartment);
        } else {
          throw new Error("kitchenDepartment or diningDepartment not found");
        }
      } else {
        throw new Error("Source not found");
      }
      const staffRole = role.find((role) => role.Name === 'Staff') || null;
      if (sourceDepartments === null || staffRole === null) {
        throw new Error("Department or Role not found");
      }
      setDepartments(sourceDepartments);
      setRole(staffRole);
    } catch (e) {
      sendToast("error", "Error fetching data");
      props.closeForm(false);
    } finally {
      setFormLoader(false);
    }
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  const onSubmit = async (e) => {
    try {
      setFormLoader(true);
      e.preventDefault();
      await Validator(formData);
      props.user ? await handleUpdateUserData(formData) : await handleSave(formData);
      sendToast("success", `User ${props.user ? "Updated" : "Created"} Successfully`);
    } catch (e) {
      sendToast("error", e instanceof Error ? e.message : "Error on Submiting the form");
    } finally {
      setFormLoader(false);
    }
  };

  const handleUpdateUserData = async (data: FormValues) => {
    try {
      const saveData = cloneDeep(data);
      if (imageFile) {
        const response = await uploadFile('user-images', imageFile);
        saveData.Icon = response;
      }
      saveData._id = existingUserData && String(existingUserData._id);
      if (props.user && existingUserData) {
        await updateUser(saveData, existingUserData);
        props.closeForm(true);
      } else {
        throw new Error("User not found");
      }
    } catch (error) {
      console.log(error);
      throw new Error(error instanceof Error ? error.message : "Error while updating the user");
    }
  }

  const Validator = async (data: FormValues) => {
    try {
      //It should be false if abortEarly is set to true, meaning the validation process will stop after the first validation error is encountered. However, when abortEarly: false is passed, all validation errors are collected before throwing the error, rather than stopping at the first one
      props.user ? await updateUserValidationSchema.validate(formData, { abortEarly: false }) : await ValidationSchema.validate(formData, { abortEarly: false });
    } catch (validationErrors) {
      const errors: { [key: string]: string; } = {};
      if (validationErrors instanceof Yup.ValidationError) {
        validationErrors.inner.forEach((error) => {
          errors[error.path] = error.message;
        });
        setValidationErrors(errors);
      }
      throw new Error("Validation Error");
    }
  };

  return (
    <div style={{ padding: "20px" }}>
      <Dimmer active={formLoader} inverted>
        <Loader active={formLoader}>Loading</Loader>
      </Dimmer>
      <Form onSubmit={onSubmit} autoComplete="off" >
        <div style={{ textAlign: 'center', marginBottom: '20px' }}>
          <h4>{isEdit() ? "Update" : "Create"} {props.source} Staff</h4>
          <Grid>
            <Grid.Column width={16} style={{ textAlign: 'left' }}>
              <Input
                id="imageInput"
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                onChange={(e) => {
                  if (e.target.files && e.target.files[0]) {
                    const file = e.target.files[0];
                    const previewUrl = URL.createObjectURL(file);
                    setPreviewUrl(previewUrl);
                    const newFile = new File([file], removeUnsafeElementsFromFileName(file.name), { type: file.type });
                    setImageFile(newFile);
                    // Optional: Cleanup previous object URLs
                    return () => {
                      URL.revokeObjectURL(previewUrl);
                    };
                  }
                }}
              />
              <div style={{ cursor: 'pointer' }}>
                {!previewUrl && !formData.Icon && (
                  <label htmlFor="imageInput" style={{ cursor: 'pointer' }}>
                    <Icon name="images" size="huge" />
                  </label>
                )}
                {previewUrl && (
                  <label htmlFor="imageInput" style={{ cursor: 'pointer' }}>
                    <Image src={previewUrl} size="medium" />
                  </label>
                )}
                {!previewUrl && formData.Icon && (
                  <label htmlFor="imageInput" style={{ cursor: 'pointer' }}>
                    <Image src={formData.Icon} size="medium" />
                  </label>
                )}
              </div>
            </Grid.Column>
          </Grid>
        </div>
        {!kitchenStaffView && <Form.Field error={!!validationErrors.FirstName}>
          <label>First Name</label>
          <Form.Input
            name="FirstName"
            placeholder="First Name"
            value={formData.FirstName}
            onChange={handleChange}
          />
          {validationErrors.FirstName && (
            <span style={{ color: 'red' }}>{validationErrors.FirstName}</span>
          )}
        </Form.Field>}
        <Form.Field error={!!validationErrors.LastName}>
          <label>{kitchenStaffView ? "Station Name" : "Last Name"}</label>
          <Form.Input
            name="LastName"
            placeholder={kitchenStaffView ? "Station Name" : "Last Name"}
            value={formData.LastName}
            onChange={handleChange}
          />
          {validationErrors.LastName && (
            <span style={{ color: 'red' }}>{validationErrors.LastName}</span>
          )}
        </Form.Field>
        <Form.Field error={!!validationErrors.Username}>
          <label>UID</label>
          <Form.Input
            name="Username"
            placeholder="UID"
            value={formData.Username}
            onChange={handleChange}
            disabled={props.user && props.user._id ? true : false}
          />
          {validationErrors.Username && (
            <span style={{ color: 'red' }}>{validationErrors.Username}</span>
          )}
        </Form.Field>
        {!props.user && (
          <>
            <Form.Field error={!!validationErrors.TemporaryPassword}>
              <label>Temp Password</label>
              <Form.Input
                type="password"
                name="TemporaryPassword"
                placeholder="Temp Password"
                value={formData.TemporaryPassword}
                onChange={handleChange}
              />
              {validationErrors.TemporaryPassword && (
                <span style={{ color: 'red' }}>{validationErrors.TemporaryPassword}</span>
              )}
            </Form.Field>
            <Form.Field error={!!validationErrors.ConfirmPassword}>
              <label>Confirm Password</label>
              <Form.Input
                type="password"
                name="ConfirmPassword"
                placeholder="Confirm Password"
                value={formData.ConfirmPassword}
                onChange={handleChange}
              />
              {validationErrors.ConfirmPassword && (
                <span style={{ color: 'red' }}>{validationErrors.ConfirmPassword}</span>
              )}
            </Form.Field></>
        )}
        <Form.Field error={!!validationErrors.Cell}>
          <label>Cell</label>
          <PhoneInput
            style={{ padding: '5px' }}
            defaultCountry="US"
            value={formData.Cell}
            required={true}
            placeholder="Enter Cell number"
            onChange={(phone) => {
              handleChange({
                target: {
                  name: 'Cell',
                  value: phone,
                },
              });
            }}
          />
          {validationErrors.Cell && (
            <span style={{ color: 'red' }}>{validationErrors.Cell}</span>
          )}
        </Form.Field>
        <Form.Field error={!!validationErrors.Email}>
          <label>Email</label>
          <Form.Input
            name="Email"
            placeholder="Email"
            value={formData.Email}
            onChange={handleChange}
            disabled={props.user && props.user._id ? true : false}
          />
          {validationErrors.Email && (
            <span style={{ color: 'red' }}>{validationErrors.Email}</span>
          )}
        </Form.Field>
        <Form.Field>
          <div className='self-center' style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center", // Ensures vertical alignment
            columnGap: "10px", // Adds space between buttons\
            marginTop:"10px"
          }}>
            <Button type="submit"> {props.user ? "Update" : "Save"}</Button>
            <Button onClick={() => props.closeForm(false)} type="button">Cancel</Button>
          </div>
        </Form.Field>
      </Form>
    </div>
  );
};

export default DiningKitchenStaffForm;
