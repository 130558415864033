import React from 'react';

type Props = {
    disabled?: boolean;
    onClickHandler: () => void;
};

const ConvertBtn = ({ disabled, onClickHandler }: Props) => {
    return (
        <div>
            <button className="edit-request-btn" disabled={disabled} onClick={onClickHandler}>
                <div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div>
                            <i className="fa-solid fa-forward updateIcon"></i>
                        </div>
                    </div>
                    <div className='btnReqTitle'>Convert</div>
                </div>
            </button>
        </div>
    );
};

export default ConvertBtn;
