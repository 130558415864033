import React from 'react';
import '../style.less';
import { Popup } from 'semantic-ui-react';

type Props = {
    disabled?: boolean;
    onClickHandler: () => void;
    title?: string;
};

const CloseBtn = ({ disabled, onClickHandler, title }: Props) => {
    return (
        <Popup
            content={title || 'Close'}
            disabled={disabled || title === 'Close' || !title}
            trigger={
                <button disabled={disabled} onClick={onClickHandler} className="edit-request-btn" type="button">
                    <div>
                        <i className="fa-solid fa-square-xmark updateIcon"></i>
                    </div>
                    <div className='btnReqTitle'>
                        {title !== 'Close' && title && (title!.slice(0, 6).trim()) || title || 'Close'}{title !== 'Close' && title && title.length > 6 ? '...' : ''}
                    </div>
                </button>
            }
        />
    );
};

export default CloseBtn;
