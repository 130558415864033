import React, { useEffect, useState } from 'react'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom'
import { Sidebar, Menu, Icon, Button, Divider, Dropdown, Popup, Input, Grid, GridColumn, Header, Modal } from 'semantic-ui-react'

import './style.less'
import { AuthState, CurrentUser, UserProfile } from '../../types'
import {
    canManageUsers,
    canAccessFacilityForms,
    canManageRegistrants,
    canManageNotifications,
    canManageFacilities,
    canManageDailyActivities,
    canManageMenus,
    canSeeDashboard,
    canManageUniversityTrainings,
    canManageStandardUniversityTrainings,
    isFacilityAdmin,
    isPowerUser,
    isFacilityTabEnabled,
} from '../../services/Permissions'
import { fetchOneFacility } from '../../services/Facilities'
import { alertMailIsIn } from '../../services/Mail-is-in'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'

interface PageHeaderProps {
    showSidebar: boolean
    setShowSidebar: (to: boolean) => any
    pageName: string
    userName: string | null
    navigate: (to: string) => void
    logOut: (profile: UserProfile | null) => void
    path: string | null
    userProfile: UserProfile | null
    facilityLogo: string
    isMailIsIn: boolean
    updateMailIsIn: (mailIsInStatus: boolean) => void
}

const PageHeader: React.FC<PageHeaderProps> = (props) => {
    const [alerting, setAlerting] = useState<boolean>(false)
    const [showMailConfirmModal, setShowMailConfirmModal] = useState(false)
    const handleSidebarToggle = () => {
        props.setShowSidebar(!props.showSidebar)
    }

    // For resident search in the home page
    const [searchResidentText, setSearchResidentText] = useState('')
    const handleResidentTextChange = (event) => {
        setSearchResidentText(event.target.value)
    }
    const handleSearchResidentFromHomePage = () => {
        props.navigate(`/admin/registrants/list/${searchResidentText}`)
        setSearchResidentText('')
    }
    const profile = useSelector(({ authReducer }: { authReducer: AuthState }) => {
        return authReducer.profile;
    });
    const ResidentSearchOnHomePage = () => {
        return (
            <>
                <Input
                    className="searchText"
                    placeholder="Search resident"
                    type="text"
                    value={searchResidentText}
                    onChange={handleResidentTextChange}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleSearchResidentFromHomePage()
                        }
                    }}
                ></Input>
                <Button
                    className="searchBtn" icon="search"
                    onClick={() => {
                        handleSearchResidentFromHomePage()
                    }}
                />
            </>
        )
    }

    const checkIfFacilityAdmin = (userProfile: UserProfile | null) => {
        if (userProfile && userProfile.Roles) {
            return userProfile.Roles.some((role) => {
                return role.Name === 'Facility Admin'
            })
        }
        return false
    }

    const checkIfPowerUser = (userProfile: UserProfile | null) => {
        if (userProfile && userProfile.Roles) {
            return userProfile.Roles.some((role) => {
                return role.Name === 'Power User'
            })
        }
        return false
    }


    const headerIcons = [{ iconName: "fa-thin fa-envelopes-bulk", link: "/", name: "Mail is in", mailIsIn: true }, { iconName: "fa-thin fa-users-medical", link: "/admin/users/list", name: "Staff" }, { iconName: "fa-thin fa-microchip-ai", link: "", name: "AI Triggers" }, { iconName: "fa-thin fa-wrench-simple", link: "", name: "Wrench" }, { iconName: "fa-thin fa-gear", link: "/admin/forms", name: "Manage Facility" }, {iconName: "fa-thin fa-sliders-simple", link: "/admin/settings", name: "Settings"}];

    const subHeaderIcons = [
        { iconName: "fa-thin fa-people-simple", link: "/admin/groups", name: "Groups" },
        { iconName: "fa-thin fa-barcode", link: "/admin/assets", name: "Assets" },
        { iconName: "fa-thin fa-square-code", link: "/admin/services/list", name: "Services" },
        { iconName: "fa-thin fa-utensils", link: "/admin/dining", name: "Dining" },
        { iconName: "fa-thin fa-graduation-cap", link: "/admin/university/trainings/list", name: "Training" },
        { iconName: "fa-thin fa-person-to-portal", link: "/admin/kiosk", name: "Kiosk" }
    ]; 

    const filteredHeaderIcons = headerIcons.filter((el) => {
        if (!(isPowerUser(props.userProfile) && el.name === "Manage Facility")) {
            // Check additional condition for "AI Triggers"
            if (el.name === "AI Triggers") {
                return isFacilityTabEnabled(props.userProfile, el.name);
            } else {
                return true;
            }
        }
        return false; // Exclude elements that meet the condition in the first filter
    });
    const toggleMailOpenModalHandler = () => {
        setShowMailConfirmModal((prev) => !prev);
    }

    const sendToast = (type: "warn" | "success" = 'warn', message: string) => {
        if (type === 'success') {
            toast.success(message, {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            })
        } else if (type === 'warn') {
            toast.warn(message, {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            })
        }
    }

    const handleMailIsIn = async (isMailIn: boolean) => {
        try {
            setAlerting(true)
            const res = await alertMailIsIn(isMailIn)
            if (!res) throw new Error('Failed to send mail is in alert')
            props.updateMailIsIn(isMailIn)
            sendToast('success', 'Alert sent to all residents across the facility!')
        } catch (e) {
            sendToast('warn', `Something went wrong. ${e && e.message && e.message}`)
            props.updateMailIsIn(props.isMailIsIn)
        } finally {
            setAlerting(false)
        }
    }

    const isResidentPage = props.path && props.path.includes('/admin/registrant');
    const renderHeader = () => {
        return (
            <header className="page-header">
                <div className="items">
                    {/* <Button icon="bars" circular onClick={handleSidebarToggle} /> */}
                    <div style={{ display: 'flex', gap: '5px', alignItems: 'center', padding: '5px' }}>
                        <div
                            style={{
                                display: 'flex',
                                gap: '5px',
                                alignItems: 'center',
                                height: '100%',
                                flexDirection: 'column',
                            }}
                        >
                            <Link to={'/'}>
                                <img src={props.facilityLogo} style={{ height: '40px', margin: '0px 5px' }} />
                            </Link>
                        </div>
                        {(checkIfFacilityAdmin(props.userProfile) || checkIfPowerUser(props.userProfile)) &&
                            <Link
                                to={"/admin/registrants/list"}
                            >
                                <Popup
                                    content={"Residents"}
                                    trigger={
                                        <i style={{ margin: '0px 5px', fontSize: '32px' }} className="fa-thin fa-people-group" />
                                    }
                                />
                            </Link>
                        }
                        {!isResidentPage && (checkIfFacilityAdmin(props.userProfile) || checkIfPowerUser(props.userProfile)) ? ResidentSearchOnHomePage() : <></>}
                    </div>
                    <div>
                        {props.pageName !== 'Speak2 Console' ? (
                            <Header as={'h5'} size="small" textAlign="center" style={{color: "#2987CD "}}>
                                {' '}
                                {props.pageName}
                            </Header>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div style={{ display: 'flex', gap: '5px' }}>
                        <div>
                            <Grid style={{ padding: '0px 15px' }} columns={isPowerUser(props.userProfile) ? "5" : "7"}>
                                {isFacilityAdmin(profile) || isPowerUser(profile) ? (
                                    filteredHeaderIcons.map((el, i) => {
                                        return (
                                            <GridColumn key={i} style={{ paddingRight: '0px', paddingLeft: '0px' }}>
                                                {!el.mailIsIn ? (
                                                    el.name === 'Wrench' ?
                                                        <div style={{ cursor: 'pointer', paddingLeft: '17px' }}>
                                                            <Popup
                                                                size="mini"
                                                                hoverable
                                                                position='bottom center'
                                                                content={
                                                                    <div style={{ display: 'flex', gap: '20px', margin: '10px' }}>
                                                                        {subHeaderIcons.map((el) => {
                                                                            if (el.name === 'Groups' || el.name === 'Training') {
                                                                                return (
                                                                                    <Link to={el.link} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                                                                        <i className={el.iconName} style={{ fontSize: '30px', color: '#549FD7' }} />
                                                                                        <div style={{ color: '#5C5D5D' }}>{el.name}</div>
                                                                                    </Link>
                                                                                );
                                                                            }
                                                                            if (isFacilityTabEnabled(props.userProfile, el.name)) {
                                                                                if (el.link) {
                                                                                    return (
                                                                                        <Link to={el.link} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                                                                            <i className={el.iconName} style={{ fontSize: '30px', color: '#549FD7' }} />
                                                                                            <div style={{ color: '#5C5D5D' }}>{el.name}</div>
                                                                                        </Link>
                                                                                    );
                                                                                } else {
                                                                                    return (
                                                                                        <Popup
                                                                                            size="mini"
                                                                                            hoverable
                                                                                            position='bottom center'
                                                                                            content={'Coming Soon'}
                                                                                            trigger={
                                                                                                <div style={{ cursor: 'pointer', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                                                                                    <i className={el.iconName} style={{ fontSize: '30px', color: '#549FD7' }} />
                                                                                                    <div style={{ color: '#5C5D5D' }}>{el.name}</div>
                                                                                                </div>
                                                                                            }
                                                                                        />
                                                                                    );
                                                                                }
                                                                            } else {
                                                                                return null;
                                                                            }
                                                                        })}
                                                                    </div>
                                                                }
                                                                trigger={
                                                                    <i className={el.iconName} style={{ color: '#5C5D5D', fontSize: '26px' }} />
                                                                }
                                                            />
                                                        </div> :
                                                        el.name === 'AI Triggers' ?
                                                            <div style={{ paddingRight: '10px', paddingLeft: '15px', cursor: 'pointer' }}>
                                                                <Popup
                                                                    size="mini"
                                                                    content={'AI Triggers - Coming Soon'}
                                                                    trigger={
                                                                        <i className={el.iconName} style={{ color: '#5C5D5D', fontSize: '28px' }} />
                                                                    }
                                                                />
                                                            </div> :
                                                            <Link style={{ paddingRight: '10px', paddingLeft: '10px' }} to={el.link}>
                                                            <Popup
                                                                size="mini"
                                                                content={el.name.replace(
                                                                    el.name[0],
                                                                    el.name[0].toUpperCase(),
                                                                )} // Capitalise the frist letter
                                                                trigger={
                                                                    <i className={el.iconName} style={{ color: '#5C5D5D', fontSize: '26px' }} />
                                                                }
                                                            />
                                                        </Link>
                                                ) : (
                                                    <Button
                                                        style={{ background: 'none', padding: 0, margin: 0 }}
                                                        onClick={toggleMailOpenModalHandler}
                                                    >
                                                        <Popup
                                                            size="mini"
                                                            content={el.name.replace(
                                                                el.name[0],
                                                                el.name[0].toUpperCase(),
                                                            )} // Capitalise the frist letter
                                                            trigger={
                                                                <i className={props.isMailIsIn
                                                                    ? el.iconName
                                                                    : `fa-thin fa-envelopes-bulk`}
                                                                    style={{ color: props.isMailIsIn ? '#2DB84B' : '#5C5D5D', fontSize: '26px' }} />
                                                            }
                                                        />
                                                    </Button>
                                                )}
                                            </GridColumn>
                                        );
                                    })
                                ) : (
                                    <></>
                                )}
                                {/* Support Center is seprate as it uses a diffrent domain */}
                                <GridColumn style={{ paddingRight: '0px', paddingLeft: '10px' }}>
                                    <Popup
                                        size="mini"
                                        content={'Support Center'}
                                        trigger={
                                            // _blank opens a the link in a new tab
                                            <a href="https://support.speak2family.com" target="_blank">
                                                <i style={{ color: '#F0712C', fontSize: '26px' }} className="fa-light fa-question" />
                                            </a>
                                        }
                                    />
                                </GridColumn>
                            </Grid>
                        </div>
                        <div>
                            <span className="username">
                                <Dropdown text={props.userName || ''}>
                                    <Dropdown.Menu className="left">
                                        <Dropdown.Item
                                            onClick={() => props.navigate('/profile')}
                                            text="Profile Settings"
                                        />
                                        <Dropdown.Divider />
                                        <Dropdown.Item onClick={() => props.logOut(props.userProfile)} text="Log Out" />
                                    </Dropdown.Menu>
                                </Dropdown>
                            </span>
                        </div>
                    </div>
                </div>
                <Divider id="divider" style={{ marginTop: '0' }} />
                <Modal
                    open={showMailConfirmModal}
                    onClose={toggleMailOpenModalHandler}
                    closeIcon={true}
                    dimmer="blurring"
                >
                    <Modal.Header>Are you sure you want to send the "Mail is In" alert?</Modal.Header>
                    <Modal.Content>
                        Click YES to tell ALL residents the mail has arrived. Once you click the button, Alexa will send
                        a announcement telling residents the mail has arrived.
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color="orange" onClick={toggleMailOpenModalHandler} basic loading={alerting}>
                            Cancel
                        </Button>
                        <Button
                            color="green"
                            basic
                            onClick={async () => {
                                await handleMailIsIn(true);
                                toggleMailOpenModalHandler();
                            }}
                            loading={alerting}
                        >
                            Yes
                        </Button>
                        <Button
                            color="red"
                            basic
                            onClick={async () => {
                                await handleMailIsIn(false);
                                toggleMailOpenModalHandler();
                            }}
                            loading={alerting}
                        >
                            No
                        </Button>
                    </Modal.Actions>
                </Modal>
            </header>
        );
    }

    return props.pageName === 'Login' ? null : renderHeader()
}

interface Props extends RouteComponentProps {
    showSidebar: boolean
    setShowSidebar: (to: boolean) => any
    currentUser: CurrentUser | null
    profile: UserProfile | null
    logOut: (profile: UserProfile | null) => void
    isAuthenticated: boolean
}

const Navigation: React.FC<Props> = (props) => {

    const [facilityLogo, setFacilityLogo] = useState<string>(`${process.env.PUBLIC_URL}/JustThe2.svg`);
    const [isMailIsIn, setIsMailIsIn] = useState(false)

    useEffect(() => {
        const getFacilityLogo = async (facilityId) => {
            const { Icon = '', isMailIn = false } = await fetchOneFacility(facilityId);
            setIsMailIsIn(isMailIn)
            Icon ? setFacilityLogo(Icon) : setFacilityLogo(`${process.env.PUBLIC_URL}/JustThe2.svg`)
        }

        if (props.profile && props.profile.Facility) {
            getFacilityLogo(props.profile.Facility);
        } else {
            setFacilityLogo(`${process.env.PUBLIC_URL}/JustThe2.svg`);
        }
    }, [props.profile])

    const handleSidebarHide = () => {
        props.setShowSidebar(false)
    }

    const updateMailIsIn = (mailIsinStatus: boolean) => {
        setIsMailIsIn(mailIsinStatus);
    }

    const getPageNameFromRoute = () => {
        switch (props.location.pathname) {
            case '/login':
                return 'Login'
            case '/myrequests':
                return 'My Requests'
            case '/profile':
                return 'My Profile'
            case '/admin/home':
                return 'Admin Dashboard'
            case '/admin/requests/create':
                return 'Create Request'
            case '/admin/users/list':
                return 'Staff List'
            case '/admin/users/create':
                return 'Create User'
            case '/admin/registrants/list':
                return 'Residents'
            case '/admin/registrants/create':
                return 'Add Residents'
            case '/admin/facilities/list':
                return 'Facilities'
            case '/admin/facilities/create':
                return 'Add Facilities'
            case '/admin/forms/units/list':
                return 'Rooms'
            case '/admin/forms/units/create':
                return 'Add Room'
            case '/admin/forms/departments/list':
                return 'Departments'
            case '/admin/forms/departments/create':
                return 'Add Department'
            case '/admin/forms/contacts/list':
                return 'Contacts'
            case '/admin/forms/contacts/create':
                return 'Add Contact'
            case '/admin/forms/requesttypes/list':
                return 'Service Types'
            case '/admin/forms/requesttypes/create':
                return 'Add Service Type'
            case '/admin/announcements':
                return 'Announcements'
            case '/admin/dailyactivities':
                return 'Calendar'
            case '/admin/menu':
                return 'Menu'
            case '/admin/reports':
                return 'Reports'
            case '/admin/clone-data':
                return 'Clone Data For Demo'
            case '/admin/university/standard-trainings/create':
                return 'Add Standard University Trainings'
            case '/admin/university/trainings/create':
                return 'Add University Trainings'
            case '/admin/university/standard-trainings/list':
                return 'Standard University Trainings'
            case '/admin/university/trainings/list':
                return 'University'
            case '/admin/speak2-pals':
                return 'Speak2Family Broadcast'
            case '/admin/staff-of-the-month':
                return 'Staff Spotlight'
            case '/admin/resident-of-the-month':
                return 'Resident Spotlight'
            case '/admin/community-messages':
                return 'Community News'
            case '/admin/birthdays':
                return 'Birthdays'
            case '/sysadmin/skills':
                return 'Manage Properties\' Skills'
            case '/admin/signage':
                return 'Signage'
            case '/admin/notepad':
                return 'Notepad'
            case '/admin/docs':
                return 'Docs'
            case '/admin/services/list':
                return 'Service Library'
            case '/admin/services/create':
                return 'Create Service'
            default: {
                // Handle non-exact routes
                if (props.location.pathname.includes('/registrant/')) return 'Edit Resident'
                if (props.location.pathname.includes('/facility/')) return 'Edit Facility'
                if (props.location.pathname.includes('/unit/')) return 'Edit Room'
                if (props.location.pathname.includes('/department/')) return 'Edit Department'
                if (props.location.pathname.includes('/contact/')) return 'Edit Contact'
                if (props.location.pathname.includes('/requesttype/')) return 'Edit Service Type'
                if (props.location.pathname.includes('/user/')) return 'Edit Staff'
                if (props.location.pathname.includes('/standard-trainings/')) return 'Edit Standard University Training'
                if (props.location.pathname.includes('/trainings/')) return 'Edit University Training'
                return 'Speak2 Console';
            }
        }
    }

    const navigate = (to: string) => {
        handleSidebarHide()
        props.history.push(to)
    }

    return (
        <div className="navigation">
            {/* {!props.isAuthenticated && <Redirect to='/login' />} */}
            <PageHeader
                setShowSidebar={props.setShowSidebar}
                showSidebar={props.showSidebar}
                pageName={getPageNameFromRoute()}
                userName={props.currentUser ? props.currentUser.username : null}
                navigate={navigate}
                logOut={() => props.logOut(props.profile)}
                path={props.location.pathname}
                userProfile={props.profile}
                facilityLogo={facilityLogo}
                isMailIsIn={isMailIsIn}
                updateMailIsIn={updateMailIsIn}
            />
            <Sidebar
                as={Menu}
                animation="push"
                icon="labeled"
                inverted
                onHide={handleSidebarHide}
                vertical
                visible={props.showSidebar}
                width="thin"
            >
                <Menu.Item as="a" onClick={() => navigate('/')}>
                    <Icon name="home" />
                    Home
                </Menu.Item>
                <Popup
                    trigger={
                        <Menu.Item>
                            <Icon name="group" />
                            Community
                        </Menu.Item>
                    }
                    position="right center"
                    flowing
                    hoverable
                    on="click"
                    pinned
                    className="community-popup"
                >
                    <Menu icon="labeled" vertical>
                        <div>
                            <Menu.Item
                                disabled={!canManageMenus(props.profile)}
                                as="a"
                                onClick={() => (canManageMenus(props.profile) ? navigate('/admin/menu') : null)}
                            >
                                <Icon name="food" />
                                Menu
                            </Menu.Item>
                        </div>
                        <div>
                            <Menu.Item
                                disabled={!canManageDailyActivities(props.profile)}
                                as="a"
                                onClick={() =>
                                    canManageDailyActivities(props.profile) ? navigate('/admin/dailyactivities') : null
                                }
                            >
                                <Icon name="paper plane" />
                                Daily Activities
                            </Menu.Item>
                        </div>
                        <div>
                            <Menu.Item
                                disabled={!canAccessFacilityForms(props.profile)}
                                as="a"
                                onClick={() =>
                                    canAccessFacilityForms(props.profile) ? navigate('/admin/community-messages') : null
                                }
                            >
                                <Icon name="book" />
                                Community News
                            </Menu.Item>
                        </div>
                        <div>
                            <Menu.Item
                                disabled={!canAccessFacilityForms(props.profile)}
                                as="a"
                                onClick={() =>
                                    canAccessFacilityForms(props.profile)
                                        ? navigate('/admin/resident-of-the-month')
                                        : null
                                }
                            >
                                <Icon name="blind" />
                                Resident Spotlight
                            </Menu.Item>
                        </div>
                        <div>
                            <Menu.Item
                                disabled={!canAccessFacilityForms(props.profile)}
                                as="a"
                                onClick={() =>
                                    canAccessFacilityForms(props.profile) ? navigate('/admin/staff-of-the-month') : null
                                }
                            >
                                <Icon name="user md" />
                                Staff Spotlight
                            </Menu.Item>
                        </div>
                        <div>
                            <Menu.Item
                                disabled={!canAccessFacilityForms(props.profile)}
                                as="a"
                                onClick={() =>
                                    canAccessFacilityForms(props.profile) ? navigate('/admin/announcements') : null
                                }
                            >
                                <Icon name="announcement" />
                                Announcements
                            </Menu.Item>
                        </div>
                        <div>
                            <Menu.Item
                                disabled={!canAccessFacilityForms(props.profile)}
                                as="a"
                                onClick={() =>
                                    canAccessFacilityForms(props.profile) ? navigate('/admin/speak2-pals') : null
                                }
                            >
                                <Icon name="street view" />
                                Speak2 Pals
                            </Menu.Item>
                        </div>
                        <div>
                            {canManageStandardUniversityTrainings(props.profile) ? (
                                <Menu.Item
                                    disabled={!canManageStandardUniversityTrainings(props.profile)}
                                    as="a"
                                    onClick={() =>
                                        canManageStandardUniversityTrainings(props.profile)
                                            ? navigate('/admin/university/standard-trainings/list')
                                            : null
                                    }
                                >
                                    <Icon name="graduation cap" />
                                    University
                                </Menu.Item>
                            ) : (
                                <Menu.Item
                                    disabled={!canManageUniversityTrainings(props.profile)}
                                    as="a"
                                    onClick={() =>
                                        canManageUniversityTrainings(props.profile)
                                            ? navigate('/admin/university/trainings/list')
                                            : null
                                    }
                                >
                                    <Icon name="graduation cap" />
                                    University
                                </Menu.Item>
                            )}
                        </div>
                        <div>
                            <Menu.Item
                                disabled={!canAccessFacilityForms(props.profile)}
                                as="a"
                                onClick={() =>
                                    canAccessFacilityForms(props.profile) ? navigate('/admin/notifications') : null
                                }
                            >
                                <Icon name="clipboard check" />
                                Notifications
                            </Menu.Item>
                        </div>

                    </Menu>
                </Popup>
                <Menu.Item as="a" onClick={() => navigate('/myrequests')}>
                    <Icon name="user" />
                    My Requests
                </Menu.Item>
                <Menu.Item
                    disabled={!canManageUsers(props.profile)}
                    as="a"
                    onClick={() => (canManageUsers(props.profile) ? navigate('/admin/users/list') : null)}
                >
                    <Icon name="user" />
                    Staff
                </Menu.Item>
                <Menu.Item
                    disabled={!canAccessFacilityForms(props.profile)}
                    as="a"
                    onClick={() => (canAccessFacilityForms(props.profile) ? navigate('/admin/forms') : null)}
                >
                    <Icon name="setting" />
                    Manage Facility
                </Menu.Item>
                {canManageFacilities(props.profile) && <Menu.Item
                    as="a"
                    onClick={() => (canManageFacilities(props.profile) ? navigate('/sysadmin/skills') : null)}
                >
                    <Icon name="sliders" />
                    Properties' Skills
                </Menu.Item>}
                <Menu.Item
                    disabled={!canManageRegistrants(props.profile)}
                    as="a"
                    onClick={() => (canManageRegistrants(props.profile) ? navigate('/admin/registrants/list') : null)}
                >
                    <Icon name="group" />
                    Residents
                </Menu.Item>
                <Menu.Item disabled={!canSeeDashboard(props.profile)} as="a" onClick={() => navigate('/admin/reports')}>
                    <Icon name="th" />
                    Reports
                </Menu.Item>
                <Menu.Item
                    disabled={!canManageFacilities(props.profile)}
                    as="a"
                    onClick={() => (canManageFacilities(props.profile) ? navigate('/admin/facilities/list') : null)}
                >
                    <Icon name="building" />
                    Facilities
                </Menu.Item>
                <Menu.Item
                    disabled={!canManageFacilities(props.profile)}
                    as="a"
                    onClick={() => (canManageFacilities(props.profile) ? navigate('/admin/clone-data') : null)}
                >
                    <Icon name="copy" />
                    Clone Demo Data
                </Menu.Item>
            </Sidebar>
        </div>
    )
}

export default withRouter(Navigation)
