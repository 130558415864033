import React, { useEffect, useRef, useState } from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';
import './style.less';
import CreateRequestModal from '../../../../components/Requests/CreateRequestModal';
import { AuthState, OrderIdColorDict, ReportSummaryFilters, Request } from '../../../../types';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getOrderIdColor, requestListView } from '../../../../util/requests';
import { useAdminPanelRequests as useAssignableRequests } from '../../../../hooks/adminPanel';
import RequestsKanban from '../../../../components/RequestsKanban';
import { sendToast } from '../../../../util';
import { acceptRequest } from '../../../../services/Requests';
import { unacceptServiceRequest } from '../../../../services/RequestInstances';
import HeaderIcons from '../../../../components/Requests/HeaderIcons';

function StaffDashboard(props) {
    const [open, setOpen] = useState<boolean>(false);
    const [openRequestModal, setOpenRequestModal] = useState<boolean>(false);
    const [showAcceptedReqModal, setAcceptedReqModal] = useState<boolean>(false);
    const [orderIdColorDict, setOrderIdColorDict] = useState<OrderIdColorDict>({});
    const [alreadyGeneratedColors, setAlreadyGeneratedColors] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [dates, setDates] = useState({
        fromDate: (() => {
            const fromDate = new Date();
            fromDate.setDate(fromDate.getDate() - 7); // Subtract 7 days from the current date
            return fromDate;
        })(),
        toDate: (() => {
            const toDate = new Date();
            toDate.setDate(toDate.getDate() + 7); // Add 7 days to the current date
            return toDate;
        })(),
    });
    const [assignableRequestFilters, setAssignableRequestFilters] = useState<ReportSummaryFilters>({
        requestType: 'active',
        fromDate: dates.fromDate,
        toDate: dates.toDate,
        disable: false,
        staff: 'all',
        resident: 'all',
        department: 'all',
        group: "all",
        reload: 0,
        FetchCancelRequests: false
    });
    const [openPageNo, setOpenPageNo] = useState<number>(1);
    const [acceptedPageNo, setAcceptedPageNo] = useState<number>(1);
    const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
    const calendarRef = useRef<HTMLHeadingElement>(null);
    const [selectedOpenSortingValue, setSelectedOpenSortingValue] = useState<string>('asc');
    const [selectedAcceptedSortingValue, setSelectedAcceptedSortingValue] = useState<string>('asc');
    const profile = useSelector(({ authReducer }: { authReducer: AuthState }) => {
        return authReducer.profile;
    });
    const [showAllRequests, setShowAllRequests] = useState<boolean>(false);
    const [expandView, setExpandView] = useState<boolean>(false);
    const [expandsingleRequestId, setExpandSingleRequestId] = useState<string | null>(null);
    const [currentView, setCurrentView] = useState<string>(requestListView);    

    const {
        requests: openRequests,
        isFetching: openIsFetching,
        isPaginating: openIsPaginating,
        hasMorePages: openHasMorePages,
        totalDataLength: openTotalDataLength,
    } = useAssignableRequests(assignableRequestFilters, selectedOpenSortingValue === "asc" ? [{ RequestedTime: "asc" }] : [{ RequestedTime: "desc" }], true, false, openPageNo, 20, props.history, false, "openRequests", false, '', showAllRequests);

    const {
        requests: acceptedRequests,
        isFetching: acceptedIsFetching,
        isPaginating: acceptedIsPaginating,
        hasMorePages: acceptedHasMorePages,
        totalDataLength: acceptedTotalDataLength,
    } = useAssignableRequests(assignableRequestFilters, selectedAcceptedSortingValue === "asc" ? [{ RequestedTime: "asc" }] : [{ RequestedTime: "desc" }], true, false, acceptedPageNo, 20, props.history, false, "open", false, (profile && profile._id) || '', showAllRequests);  

    const openRequestsList: Request[] = [...openRequests]; // Any changes made here on in this component should also be checked in facility/admin view 
    const acceptedRequestsList: Request[] = [...acceptedRequests];
    const allRequests: Request[] = [...openRequestsList, ...acceptedRequestsList];
    allRequests.forEach((request) => {
        getOrderIdColor(orderIdColorDict, setAlreadyGeneratedColors, setOrderIdColorDict, alreadyGeneratedColors, request.OrderId);
    })

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (calendarRef.current && !calendarRef.current.contains(event.target)) {
                setShowDatePicker(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [calendarRef]);

    useEffect(() => {
        const getQueryParams = () => {
            const queryParams = new URLSearchParams(props.location.search);
            const expandOpen = queryParams.get('expandOpen');
            const expandAccepted = queryParams.get('expandAccepted');

            setOpenRequestModal(expandOpen === 'true');
            setAcceptedReqModal(expandAccepted === 'true');
        };

        getQueryParams();
    }, [props.location]);

    const formatDateRange = (dateRange: { fromDate: Date; toDate: Date; }) => {
        const startDate = dateRange.fromDate ? moment(dateRange.fromDate).format('MM/DD/YYYY') : 'none';
        const endDate = dateRange.toDate ? moment(dateRange.toDate).format('MM/DD/YYYY') : 'none';
        if (!dateRange.toDate || startDate === endDate) return startDate;
        return `${startDate} - ${endDate}`;
    };

    const isLoading = openIsFetching || acceptedIsFetching || loading;

    const handleShowAllRequests = () => {
        setShowAllRequests(prevState => !prevState); // Toggle the button state
    };

    const handleCardMoveToOpen = async (card, source) => { // Any change made here should also be checked in facility/admin view
        try {
            if (source.fromColumnId === "open") {
                return;
            }
            setLoading(true);
            if (!card.metadata._id) {
                throw new Error("Request id not found");
            }
            const requestId = String(card.metadata._id);
            // if the card is moved from accepted to open then we have to unaccept the request
            if (source.fromColumnId === "accepted") {
                await unacceptServiceRequest(requestId);
            }
            setAssignableRequestFilters(prevFilters => {
                return {
                    ...prevFilters,
                    reload: prevFilters.reload + 1
                };
            });
        } catch (error) {
            sendToast("error", error instanceof Error ? error.message : "Error assigning staff");
        } finally {
            setLoading(false);
        }
    }

    const handleCardMoveToAccepted = async (card, source) => { // Any change made here should also be checked in facility/admin view
        try {
            if (source.fromColumnId === "accepted") {
                return;
            }
            setLoading(true);
            if (source.fromColumnId === "open") {
                if(!card || !card.metadata || !card.metadata._id) {
                    throw new Error("Request id not found");
                }
                await acceptRequest(card.metadata._id);
                setAssignableRequestFilters({
                    ...assignableRequestFilters,
                    reload: assignableRequestFilters.reload + 1
                });
            }
        } catch (error) {
            sendToast("error", error instanceof Error ? error.message : "Error assigning staff");
        } finally {
            setLoading(false);
        }
    }

    const expandIconClickHandler = () => {
        setExpandView((prev) => !prev);
        expandsingleRequestId && setExpandSingleRequestId(null);
    };

    const loadMoreProps = {
        "open": {
            isLoading: openIsFetching || openIsPaginating || loading,
            hasMore: openHasMorePages,
            setPageNo: setOpenPageNo
        },
        "accepted": {
            isLoading: acceptedIsFetching || acceptedIsPaginating || loading,
            hasMore: acceptedHasMorePages,
            setPageNo: setAcceptedPageNo
        },
    }

    return (
        <div className="staff-dashboard">
            <HeaderIcons
                expandView={expandView}
                expandIconClickHandler={expandIconClickHandler}
                currentView={currentView}
                setCurrentView={setCurrentView}
            />
            <div className='staff-header'>
                <div ref={calendarRef}>
                    <Button
                        onClick={() => setShowDatePicker((prev) => !prev)}
                        className="date-range-selector"
                    >
                        <Icon name="calendar alternate outline" size="large" />
                        <span style={{ fontSize: '12px' }}>{formatDateRange(dates)}</span>
                    </Button>
                    {showDatePicker && !isLoading && (
                        <div style={{ position: 'absolute', zIndex: 2 }}>
                            <DatePicker
                                selected={dates.fromDate}
                                startDate={dates.fromDate}
                                endDate={dates.toDate}
                                onChange={(startEndDates) => {
                                    const [start, end] = startEndDates;
                                    setDates({ ...dates, fromDate: start, toDate: end });
                                    if (start && end) {
                                        setAssignableRequestFilters({ ...assignableRequestFilters, fromDate: start, toDate: end });
                                        setShowDatePicker(false);
                                    }
                                }}
                                selectsRange
                                inline
                                style={{ position: 'absolute', top: 0, left: 0 }}
                                readOnly={true}
                            />
                        </div>
                    )}
                </div>
                {/* Show All Requests Button */}
                <Button
                    color={showAllRequests ? 'green' : 'grey'} // Green when activated, grey when deactivated
                    onClick={handleShowAllRequests}
                >
                    {showAllRequests ? 'Show my group requests' : 'Show all requests'}
                </Button>
                <Button
                    style={{ backgroundColor: 'white', color: '#1991EB', border: '1px solid #1991EB' }}
                    onClick={() => {
                        setOpen(true);
                    }}
                >
                    <Icon name="add" className="button-icon" style={{ color: '#1991EB' }} />
                    Create Request
                </Button>
            </div>

            {open && (
                <Modal open={open} onClose={() => setOpen(false)} onOpen={() => setOpen(true)} style={{ width: "450px" }}>
                    <Modal.Content>
                        <CreateRequestModal
                            facilityId={props.profile.Facility}
                            setOpen={setOpen}
                            refresh={() => setAssignableRequestFilters({
                                ...assignableRequestFilters,
                                reload: assignableRequestFilters.reload + 1
                            })}
                        />
                    </Modal.Content>
                </Modal>
            )}
            {openRequestModal && (
                <Modal open={openRequestModal} onClose={() => {
                    props.history.push("/");
                }} size='fullscreen' dimmer="blurring">
                    <Modal.Content>
                        {/* Open Requests */}
                        <RequestsKanban
                            openRequests={openRequestsList}
                            acceptedRequests={acceptedRequestsList}
                            openRequestsLength={openTotalDataLength}
                            acceptedRequestsLength={acceptedTotalDataLength}
                            handleCardMoveToAccepted={handleCardMoveToAccepted}
                            handleCardMoveToOpen={handleCardMoveToOpen}
                            loading={isLoading}
                            orderIdColorDict={orderIdColorDict}
                            assignableRequestFilters={assignableRequestFilters}
                            loadMoreProps={loadMoreProps}
                            selectedOpenSortingValue={selectedOpenSortingValue}
                            setSelectedOpenSortingValue={setSelectedOpenSortingValue}
                            selectedAcceptedSortingValue={selectedAcceptedSortingValue}
                            setSelectedAcceptedSortingValue={setSelectedAcceptedSortingValue}
                            refresh={
                                () => setAssignableRequestFilters({
                                    ...assignableRequestFilters,
                                    reload: assignableRequestFilters.reload + 1
                                })
                            }
                            setOpenPageNo={setOpenPageNo}
                            setAcceptedPageNo={setAcceptedPageNo}
                            isFullScreenModalView={true}
                            fullScreenStatus={'open'} // Don't change this as this key is used in multiple other components
                            setAssignableRequestFilters={setAssignableRequestFilters}
                            expandView={expandView}
                            setExpandSingleRequestId={setExpandSingleRequestId}
                            expandsingleRequestId={expandsingleRequestId}
                            currentView={currentView}
                        />
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => {
                            props.history.push("/");
                        }}>Close</Button>
                    </Modal.Actions>
                </Modal>
            )}
            {showAcceptedReqModal && (
                <Modal open={showAcceptedReqModal} onClose={() => {
                    props.history.push("/");
                }} size='fullscreen' dimmer="blurring">
                    <Modal.Content>
                        {/* My Requests (accepted) */}
                        <RequestsKanban
                            openRequests={openRequestsList}
                            acceptedRequests={acceptedRequestsList}
                            openRequestsLength={openTotalDataLength}
                            acceptedRequestsLength={acceptedTotalDataLength}
                            handleCardMoveToAccepted={handleCardMoveToAccepted}
                            handleCardMoveToOpen={handleCardMoveToOpen}
                            loading={isLoading}
                            orderIdColorDict={orderIdColorDict}
                            assignableRequestFilters={assignableRequestFilters}
                            loadMoreProps={loadMoreProps}
                            selectedOpenSortingValue={selectedOpenSortingValue}
                            setSelectedOpenSortingValue={setSelectedOpenSortingValue}
                            selectedAcceptedSortingValue={selectedAcceptedSortingValue}
                            setSelectedAcceptedSortingValue={setSelectedAcceptedSortingValue}
                            refresh={
                                () => setAssignableRequestFilters({
                                    ...assignableRequestFilters,
                                    reload: assignableRequestFilters.reload + 1
                                })
                            }
                            setOpenPageNo={setOpenPageNo}
                            setAcceptedPageNo={setAcceptedPageNo}
                            isFullScreenModalView={true}
                            fullScreenStatus={'accepted'} // Don't change this as this key is used in multiple other components
                            setAssignableRequestFilters={setAssignableRequestFilters}
                            expandView={expandView}
                            setExpandSingleRequestId={setExpandSingleRequestId}
                            expandsingleRequestId={expandsingleRequestId}
                            currentView={currentView}
                        />
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => {
                            props.history.push("/");
                        }}>Close</Button>
                    </Modal.Actions>
                </Modal>
            )}

            <RequestsKanban
                openRequests={openRequestsList}
                acceptedRequests={acceptedRequestsList}
                openRequestsLength={openTotalDataLength}
                acceptedRequestsLength={acceptedTotalDataLength}
                handleCardMoveToAccepted={handleCardMoveToAccepted}
                handleCardMoveToOpen={handleCardMoveToOpen}
                loading={isLoading}
                orderIdColorDict={orderIdColorDict}
                assignableRequestFilters={assignableRequestFilters}
                loadMoreProps={loadMoreProps}
                selectedOpenSortingValue={selectedOpenSortingValue}
                setSelectedOpenSortingValue={setSelectedOpenSortingValue}
                selectedAcceptedSortingValue={selectedAcceptedSortingValue}
                setSelectedAcceptedSortingValue={setSelectedAcceptedSortingValue}
                refresh={
                    () => setAssignableRequestFilters({
                        ...assignableRequestFilters,
                        reload: assignableRequestFilters.reload + 1
                    })
                }
                setOpenPageNo={setOpenPageNo}
                setAcceptedPageNo={setAcceptedPageNo}
                setAssignableRequestFilters={setAssignableRequestFilters}
                expandView={expandView}
                setExpandSingleRequestId={setExpandSingleRequestId}
                expandsingleRequestId={expandsingleRequestId}
                currentView={currentView}
            />
        </div>
    );
}

export default withRouter(StaffDashboard);
