import React from 'react'
import './style.less'
import { Dimmer, Loader, Button, Input, Grid, Modal, Checkbox } from 'semantic-ui-react'
import { Facility, Registrant, UserLike, UserProfile } from '../../../../types'
import { withRouter, RouteComponentProps } from 'react-router'
import UsersList from '../../../../components/UsersList'
import LoadMore from '../../../../components/LoadMore'
import {
    fetchPaginatedInactiveRegistrants,
    fetchPaginatedRegistrants,
} from '../../../../services/Registrants'
import { Promise } from 'bluebird'
import { getSmartGadget } from '../../../../../src/services/SmartGadgets'
import ExportRegistrantButton from './ExportButton'
import EditRegistrants from '../Edit'
import NotificationsFormContainer from '../../../../components/NotificationFormContainer'
import DeviceNotificationsFormContainer from '../../../../components/DeviceNotificationsFormContainer'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import { AppState } from '../../../../reducers'
import CreateRequestModal from '../../../../components/Requests/CreateRequestModal';
import ResidentLandingPage from '../../../../components/ResidentLandingPage'
import { fetchOneFacility } from '../../../../services/Facilities';

interface State {
    registrants: Registrant[]
    inactiveRegistrants: Registrant[]
    hasError: boolean
    error: string | null
    isFetching: boolean
    isPaginating: boolean
    search: string | null
    page: number
    count: number
    limit: number
    hasMorePages: boolean
    smartGadgets: []
    selectedId: string
    detailViewActiveIndex: number
    showModal: boolean
    modalType: "notifications" | "requests" | "announcements" | ""
    selectedResidentId: string
    selectedResidentA4hRoomId: string
    feedRefreshKey: number
    showInactiveResidents: boolean | undefined,
    residentialOnly: boolean
    facilityObj: Facility | undefined
}

interface Props extends RouteComponentProps {
    profile: UserProfile | null
}

class RegistrantsList extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            registrants: [],
            inactiveRegistrants: [],
            hasError: false,
            search: props.match.params['residentToSearch'] || '',
            error: null,
            isFetching: false,
            isPaginating: false,
            page: 1,
            count: 0,
            limit: 50,
            hasMorePages: true,
            smartGadgets: [],
            selectedId: props.match.params['id'] || "",
            detailViewActiveIndex: 0,
            showModal: false,
            modalType: "",
            selectedResidentId: "",
            selectedResidentA4hRoomId: "",
            feedRefreshKey: 0,
            showInactiveResidents: false,
            residentialOnly: false,
            facilityObj: undefined,
        }
    }

    async loadMore() {
        try {
            if (this.state.page === 1) {
                this.setState({
                    isFetching: true,
                    hasError: false,
                    error: null,
                })
            } else {
                this.setState({
                    isPaginating: true,
                    hasError: false,
                    error: null,
                })
            }

            const registrants = await fetchPaginatedRegistrants(
                this.state.page,
                this.state.limit,
                this.state.search,
                undefined,
                this.state.residentialOnly ? { residentialCommunityResident: this.state.residentialOnly } : undefined
            );

            const inactiveRegistrants = this.state.showInactiveResidents
                ? await fetchPaginatedInactiveRegistrants(
                    this.state.page,
                    this.state.limit,
                    this.state.search,
                    this.state.residentialOnly ? { residentialCommunityResident: this.state.residentialOnly } : undefined
                )
                : [];

            const hasMorePages = !(registrants.length < this.state.limit)
            const hasMorePagesInactive = !(inactiveRegistrants.length < this.state.limit)

            this.setState({
                isFetching: false,
                isPaginating: false,
                registrants: [...this.state.registrants, ...registrants],
                inactiveRegistrants: [...this.state.inactiveRegistrants, ...inactiveRegistrants],
                hasError: false,
                error: null,
                hasMorePages: hasMorePages || hasMorePagesInactive,
                page: this.state.page + 1,
            })
        } catch (e) {
            const msg = e.message || 'Could not fetch Rooms.'
            this.setState({
                isFetching: false,
                isPaginating: false,
                hasError: true,
                error: msg,
            })
        }
    }

    async fetchFacility() {
        if (this.props.profile && this.props.profile.Facility) {
            const facility = await fetchOneFacility(this.props.profile.Facility);
            this.setState({
                facilityObj: facility
            });
        }
    }

    async componentDidMount() {
        await this.loadMore();
        await this.fetchFacility();
    }

    componentDidUpdate(prevProps: Props) {
        const prevPropsId = prevProps.match.params['id'] || ''
        const currentPropsId = this.props.match.params['id'] || ''
        if (prevPropsId !== currentPropsId) {
            this.setState({
                selectedId: currentPropsId
            });
        }
    }

    navigateToCreate() {
        return this.props.history.push('/admin/registrants/create')
    }

    get userLikeArray(): UserLike[] {
        const allRegistrants = [...this.state.registrants, ...this.state.inactiveRegistrants]
        const sortedAllRegistrants = allRegistrants.sort((a, b) => {
            const lastNameA = a.LastName || '';
            const lastNameB = b.LastName || '';
            if (lastNameA < lastNameB) {
                return -1;
            }
            if (lastNameA > lastNameB) {
                return 1;
            }
            return 0;
        });
        return sortedAllRegistrants.map((r) => ({
            _id: r._id,
            FirstName: r.FirstName || '',
            LastName: r.LastName || '',
            Details: r.SpecialHandling || r.Notes || '',
            Image: r.Image || r.alisImage || r.ExternalResidentImage,
            roomName: (r.Unit && r.Unit.Name) || '',
            a4hRoomId: (r.Unit && r.Unit.a4hRoomId) || '',
            onPremise: r.onPremise,
            disabled: r.IsActive ? false : true,
            isExternalResident: (r.ExternalIntegrationSrc) ? true : false,
            isAlisResident: (r.alisResidentId) ? true : false,
            externalIntegrationSource: r.ExternalIntegrationSrc || '',
            primaryResidentId: r.primaryResidentId,
            Private: r.Private,
        }))
    }

    updateSearch(value) {
        this.setState({
            search: value,
        })
    }

    search() {
        if(this.state.isFetching) {
            return;
        }

        this.setState(
            {
                registrants: [],
                inactiveRegistrants: [],
                hasError: false,
                error: null,
                isFetching: false,
                isPaginating: false,
                page: 1,
                count: 0,
                limit: 50,
                hasMorePages: false,
                selectedId: "" // refresh the edit registrant component by changning the selected id
            },
            () => {
                return this.loadMore()
            },
        )
    }

    openEdit(id: string) {
        this.props.history.push(`/admin/registrant/list/${id}`);
    }

    getAllSmartGadgets = async () => {
        const residentsWithGadgetInfo = await Promise.map(this.state.registrants, async (e) => {
            const gadgetNames = await getSmartGadget(e.Unit && e.Unit.a4hRoomId)

            e.gadgetNames = gadgetNames

            return e
        }, {
            concurrency: 5
        })

        return residentsWithGadgetInfo
    }

    // getAllData = async () => { //What is this code doing?
    //     const contacts = await Promise.map(this.state.registrants, async (e) => { //WHY IS THERE NO CONCURRENCY HERE?
    //         const data = await fetchPaginatedRegistrantRelatives(e._id)
    //     },
    //     { concurrency: 5 }
    //     )
    // }

    onShowAlexaClickHandler(id: string) {
        const alexaTabIndex = 8
        this.setState({
            detailViewActiveIndex: alexaTabIndex,
            selectedId: id
        })
    }

    annoucementIconClickHandler(id: string | undefined) {
        if (id) {
            this.toggleModalHandler()
            this.setState({
                modalType: "announcements",
                selectedResidentA4hRoomId: id
            })
        } else {
            toast.warn("No unit is assiocaited with the resident", {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            })
        }
    }


    notificationsIconClickHandler(id: string | undefined) {
        if (id) {
            this.toggleModalHandler()
            this.setState({
                modalType: "notifications",
                selectedResidentA4hRoomId: id
            })
        } else {
            toast.warn("No unit is assiocaited with the resident", {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            })
        }
    }

    requestsIconClickHandler(id: string) {
        this.setState({
            selectedResidentId: id
        })
        this.toggleModalHandler()
        this.setState({
            modalType: "requests"
        })
    }

    toggleModalHandler() {
        this.setState({
            showModal: !this.state.showModal
        })
    }

    refreshReqListHandler() {
        this.toggleModalHandler()
        this.setState({
            feedRefreshKey: this.state.feedRefreshKey + 1
        })
    }


    render() {
        // this.getAllData()
        return (
            <div>
                <Dimmer active={this.state.isFetching} inverted>
                    <Loader active={this.state.isFetching} />
                </Dimmer>
                <Grid rows={2}>
                    <Grid.Row>
                        <div style={{padding: '0 0 0 14px'}}>
                            {/* this div to is offset the button in the top of registrant list to align with registrant list */}
                        </div>
                        <Button
                            content={"Create New"}
                            icon="add"
                            labelPosition="left"
                            primary
                            onClick={this.navigateToCreate.bind(this)}
                            size="mini"
                            style={{ padding: "10px 0px" }}
                        />
                        <Input
                            style={{margin: '0 0.5vw 0 0.5vw'}}
                            className="searchText"
                            placeholder="Search resident / room"
                            type="text"
                            value={this.state.search}
                            size="small"
                            action={
                                <Button
                                    className="searchBtn"
                                    icon="search"
                                    onClick={() => this.search()}
                                    size="small"
                                />
                            }
                            onChange={(_, { value }) => this.updateSearch(value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    this.props.history.push('/admin/registrants/list')
                                    this.search()
                                }
                            }}
                        />

                        {
                            this.state.facilityObj && this.state.facilityObj.residentialCommunity &&
                            <Button.Group size="small">
                                <Button
                                    active={!this.state.residentialOnly}
                                    onClick={() => {
                                        this.setState({
                                            residentialOnly: false,
                                            page: 1,
                                            registrants: [],
                                            inactiveRegistrants: [],
                                            isFetching: true,
                                        }, () => { this.loadMore(); });
                                    }}
                                >
                                    All
                                </Button>
                                <Button
                                    active={this.state.residentialOnly}
                                    onClick={() => {
                                        this.setState({
                                            residentialOnly: true,
                                            page: 1,
                                            registrants: [],
                                            inactiveRegistrants: [],
                                            isFetching: true,
                                        }, () => { this.loadMore(); });
                                    }}
                                >
                                    Residential
                                </Button>
                            </Button.Group>
                        }

                        <Checkbox
                            style={{display: 'flex', alignItems: 'center', paddingRight:'1vw', marginLeft: '10px'}}
                            onChange={(e, data) => { 
                                if (data.checked) {
                                    this.setState({ limit: 25 });
                                } else {
                                    this.setState({ limit: 50 });
                                }
                                this.setState({ showInactiveResidents: data.checked, page: 1, registrants: [], inactiveRegistrants: [], isFetching: true, search: '' }, () => {this.loadMore()});
                            }}
                            checked={this.state.showInactiveResidents}
                            label={`Include inactive`}
                        />
                        <ExportRegistrantButton
                            getAllSmartGadgets={this.getAllSmartGadgets}
                        />
                    </Grid.Row>
                    <Grid.Row style={{paddingTop: 0}}>
                        <Grid.Column width={4} style={{width: '325px', maxWidth: '325px'}}>
                            <div className="RegistrantsList">
                                <UsersList
                                    users={this.userLikeArray}
                                    onClick={this.openEdit.bind(this)}
                                    source="registrants"
                                    onShowAlexaClick={this.onShowAlexaClickHandler.bind(this)}
                                    selectedId={this.state.selectedId}
                                    annoucementIconClickHandler={this.annoucementIconClickHandler.bind(this)}
                                    notificationIconClickHandler={this.notificationsIconClickHandler.bind(this)}
                                    requestIconClickHandler={this.requestsIconClickHandler.bind(this)}
                                />
                                <LoadMore
                                    next={() => {
                                        this.loadMore()
                                    }}
                                    isLoading={this.state.isPaginating}
                                    hasMore={this.state.hasMorePages}
                                />
                            </div>
                        </Grid.Column>
                        <Grid.Column width={12} style={{paddingTop: '0px' }}>
                            {/* DO NOT REMOVE KEY : passing the key prop is a hack to rerender the component when the selectedId changes  */}
                            {this.state.selectedId ? <EditRegistrants id={this.state.selectedId} key={this.state.selectedId + this.state.detailViewActiveIndex} activeIndex={this.state.detailViewActiveIndex} feedRefreshKey={this.state.feedRefreshKey} /> : <ResidentLandingPage facilityObj={this.state.facilityObj}/>}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Modal open={this.state.showModal} onClose={this.toggleModalHandler.bind(this)} closeIcon closeOnEscape style={{ padding: '10px', width: this.state.modalType === "requests" ? "450px" : '' }}>
                    {
                        this.state.modalType === "announcements"
                            ?
                            <NotificationsFormContainer selectedA4hRoomId={this.state.selectedResidentA4hRoomId} closeModal={() => {
                                this.setState({
                                    showModal: false
                                })
                            }} />
                            :
                            this.state.modalType === "notifications"
                                ?
                                <DeviceNotificationsFormContainer selectedResidentA4hRoomId={this.state.selectedResidentA4hRoomId} closeModal={() => {
                                    this.setState({
                                        showModal: false
                                    })
                                }} />
                                :
                                this.state.modalType === "requests"
                                    ?
                                    <CreateRequestModal refresh={this.refreshReqListHandler.bind(this)} cancelClickHandler={this.toggleModalHandler.bind(this)} resident={this.state.selectedResidentId} facilityId={this.props.profile && this.props.profile.Facility || ''} />
                                    :
                                    <></>
                    }
                </Modal>
            </div>

        )
    }
}

function mapStateToProps({ authReducer }: AppState) {
    return {
        profile: authReducer.profile,
    }
}
export default connect(mapStateToProps)(withRouter(RegistrantsList))
